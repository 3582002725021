import {Button, Container, Table} from "react-bootstrap";
import React, {useState} from "react";
import DemoBody from "../../../components/DemoComponents/DemoBody";
import MmeCard from "../../../components/MiddleMileComponents/MmeCard";
import VisualisationOfNetwork from "../../../components/DemoComponents/VisualisationOfNetwork";
import NetworkInfo from "../../../components/DemoComponents/NetworkInfo";
import IsolatedFacilities from "../../../components/DemoComponents/IsolatedFacilities";
import PackageInfo from "../../../components/DemoComponents/PackageInfo";
import graphSpire100RawData
    from "../../../contexts/DemoContents/LatestFiles/graphs/graph_with_meta_spire_100_shipment_profiles_pre_catastrophe.json";
import trainedModel100Spire
    from "../../../contexts/DemoContents/LatestFiles/agents/trained_model_with_co2_COST.json";
import multiplePackagesJson from "../../../contexts/DemoContents/LatestFiles/packages/200_packages_from_graph_with_meta_spire_100_shipment_profiles.json";
// import multiplePackagesJsonNotConsolidated from "../../../contexts/DemoContents/LatestFiles/packages/unconsolidated_packages.json";
// import multiplePackagesJsonPreConsolidated from "../../../contexts/DemoContents/LatestFiles/packages/consolidated_package.json";
// import consolidatedPrediction from "../../../contexts/DemoContents/LatestFiles/predictions/consolidated_package_prediction.json";

// import ConsolidationSavingsComparison from "../../../components/DemoComponents/ConsolidationSavingsComparison";
import RoutingStatistics from "../../../components/DemoComponents/RoutingStatistics";
import AStarRoutingStatisticsComparison from "../../../components/DemoComponents/AStarRoutingStatisticsComparison";
import AlternativeRoutingStatisticsComparison from "../../../components/DemoComponents/AlternativeRoutingStatisticsComparison";
import PackagesBreakdown from "../../../components/DemoComponents/PackagesBreakdown";
import CatastropheRoutingComparison from "../../../components/DemoComponents/CatastropheRoutingComparison";
import DifferentOptimisationsRoutingStatistics from "../../../components/DemoComponents/DifferentOptimisationsRoutingStatistics";

/*
this demo tool was built to showcase the use cases and performance of MME
It has the ability to live MME calculations, but for the purposes of an MVP, cached/local json files are used
Mapping API calls are live however and not cached
 */
export default function DemoTool() {
    const [showDemoTool, setShowDemoTool] = React.useState(false);
    const [mmePredictionResult, setMmePredictionResult] = useState<any>();
    const [mmePredictionTime, setMmePredictionTime] = useState<number>(0);

    // this was to ensure that all components use the same MME prediction result
    const handleMmePredictionChange = (newMmePredictionResult: any, newMmePredictionTime: number) => {
        setMmePredictionResult(newMmePredictionResult);
        setMmePredictionTime(newMmePredictionTime);
    };

    let singlePredictionMmeForAlt;
    let singlePackageForAlt;
    for (const packageId in mmePredictionResult as any) {
        // this was to ensure that the choice of package for alternative routing was valid
        const packagePath = mmePredictionResult[packageId].path;
        if (packagePath.length > 0) {
            singlePredictionMmeForAlt = {[packageId]: mmePredictionResult[packageId]};
            singlePackageForAlt = [multiplePackagesJson.find((packageJson) => packageJson.id === Number(packageId))];
            break;
        }
    }

    return (
        <DemoBody demoSideBar>
            <center>
            {showDemoTool
                ? <Container>
                    <VisualisationOfNetwork graphJson={graphSpire100RawData}/>
                    <div style={{float: 'left', width: '50%'}}>
                        <NetworkInfo graphJson={graphSpire100RawData}/>
                    </div>
                    <div style={{float: 'right', width: '50%'}}>
                        <IsolatedFacilities graphJson={graphSpire100RawData}/>
                    </div>
                    <div style={{float: 'left', width: '100%'}}>
                        <PackageInfo packageJson={multiplePackagesJson} graphJson={graphSpire100RawData}/>
                    </div>
                    <PackagesBreakdown packageJson={multiplePackagesJson}/>
                    <RoutingStatistics graphJson={graphSpire100RawData} trainedModelJson={trainedModel100Spire} packagesJson={multiplePackagesJson} onMmePredictionGenerated={handleMmePredictionChange} useCached={true} />
                    {mmePredictionResult &&
                        <>
                            <AStarRoutingStatisticsComparison graphJson={graphSpire100RawData} packagesJson={multiplePackagesJson} mmePredictionResults={mmePredictionResult} mmePredictionTime={mmePredictionTime} useCachedFiles={true} />
                            <AlternativeRoutingStatisticsComparison packagesJson={singlePackageForAlt} mmePredictionResultSinglePackage={singlePredictionMmeForAlt}/>
                            <DifferentOptimisationsRoutingStatistics graphJson={graphSpire100RawData} packagesJson={multiplePackagesJson} mmePredictionCostOpti={mmePredictionResult} useCached={true}/>
                            {/*due to inconsistent results, the ConsolidationSavingsComparison component is currently not in use*/}
                            {/*<ConsolidationSavingsComparison graphJson={graphSpire100RawData} mmePredictionResultsConsolidated={consolidatedPrediction} packagesJsonConsolidated={multiplePackagesJsonPreConsolidated} packagesJsonNotConsolidated={multiplePackagesJsonNotConsolidated} trainedModelJson={trainedModel100Spire}/>*/}
                            <CatastropheRoutingComparison graphJson={graphSpire100RawData} trainedModelJson={trainedModel100Spire} packagesJson={multiplePackagesJson} mmePredictionResults={mmePredictionResult}/>
                        </>
                    }

                </Container>
                : <Container>
                    <MmeCard
                        title={'Welcome to the Multi-Modal Engine(MME) Demo Tool'}
                        description={'You have the ability to interact with MME in a variety of scenarios to explore its performance and capabilities. These scenarios are chosen to help either demonstrate its capabilities in a real-world use cases or highlight features that MME has. We will be using a pre-set network of facilities and connections based on the current state of between the busiest 100 airports. We will be doing a live prediction so you can get a true feel of the prediction power of MME. In addition to just showing you what it does, we will also allow you to explore a variety of statistics to further reinforce your belief in its performance and give you full transparency into its ability. The scenarios are listed and explained below:'}
                        body={
                            <Table striped bordered hover>
                                <thead>
                                <tr>
                                    <th>Highlight 1: Single Shipment Route and Suggestions</th>
                                    <th>Highlight 2: Catastrophe Reactivity</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>In this scenario we highlight the ability to successfully route a single shipment from one facility to another while educatedly choosing the most ideal route. We give you the ability to explore suggestions made by MME as alternative routes, calculated to anticipate issues with any segment of the optimal route.</td>
                                    <td>In this scenario we will highlight the flexibility and reactivity of MME. We base this scenario on a simulation of an emergency situation where 2 large facilities has lead to full shutdowns. This means that all incoming and outgoing connections are now unavailable, we will successfully reroute all shipments that have possible paths to their destinations. Here you will explore the statistics of how this catastrophe has impacted impact the original state of the shipments.</td>
                                </tr>
                                </tbody>
                            </Table>}
                    />
                    <Button onClick={() => setShowDemoTool(!showDemoTool)}>Begin</Button>
                </Container>}
            </center>
        </DemoBody>
    )
}
