import Container from 'react-bootstrap/Container';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import HomePage from './pages/HomePage';
import MmePrediction from './pages/MiddleMilePages/MmePrediction';
import MmeDashboard from './pages/MiddleMilePages/MmeDashboard';
import UploadDataset from "./pages/LastMilePages/UploadDataset";
import PreloadedDatasets from "./pages/LastMilePages/PreloadedDatasets";
import Output from "./pages/LastMilePages/Output";
import CompareDataset from "./pages/LastMilePages/CompareDataset";
import SecondDataset from "./pages/LastMilePages/SecondDataset";
import UseCase from "./pages/LastMilePages/UseCase";
import ColdChainSuccessMetrics from "./pages/LastMilePages/ColdChainSuccessMetrics";
import ColdChainMetrics from "./pages/LastMilePages/ColdChainMetrics";
import SubUseCases from "./pages/LastMilePages/SubUseCases";
import DemoTool from "./pages/DemoPages/DemoToolPages/DemoTool";
import HazardousGoodsSuccessMetrics from "./pages/LastMilePages/HazardousGoodsSuccessMetrics";
import MixedFleetSuccessMetrics from "./pages/LastMilePages/MixedFleetSuccessMetrics";
import PriorityOrdersSuccessMetrics from "./pages/LastMilePages/PriorityOrdersSuccessMetrics";
import LargeFleetSuccessMetrics from "./pages/LastMilePages/LargeFleetSuccessMetrics";
import UseCaseGeneratorHomepage from "./pages/UseCaseGeneratorPages/UseCaseGeneratorHomepage";
import LSHomepage from "./pages/LastMilePages/LSHomepage";
import LsDashboard from "./pages/LastMilePages/LsDashboard";
import SelfServiceHomepage from "./pages/UseCaseGeneratorPages/SelfServiceHomepage";
import SelfServiceSettingsPage from "./pages/UseCaseGeneratorPages/SelfServiceSettingsPage";

import React from 'react';

import { PageLayout } from './components/AuthenticationComponents/PageLayout';

import { AuthenticatedTemplate, UnauthenticatedTemplate} from '@azure/msal-react';

const MainContent = () => {
    return (
        // the background color is set to match the gif background color
        <div className="App" style={{backgroundColor: '#fcfcff'}}>
            {/*AuthenticatedTemplate refers to the template that is displayed after successfully logging in*/}
            <AuthenticatedTemplate>
                <Container fluid className="App">
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" element={<HomePage />} />
                            <Route path="/demo-tool" element={<DemoTool />} />
                            <Route path="/mme-prediction" element={<MmePrediction />} />
                            <Route path="/mme-dashboard" element={<MmeDashboard />} />
                            <Route path="/preloaded-datasets" element={<PreloadedDatasets />} />
                            <Route path="/upload-dataset" element={<UploadDataset />} />
                            <Route path="/output" element={<Output />} />
                            <Route path="/compare-dataset" element={<CompareDataset />} />
                            <Route path="/second-dataset" element={<SecondDataset />} />
                            <Route path="/use-case" element={<UseCase/>} />
                            <Route path="/cold-chain-success-metrics" element={<ColdChainSuccessMetrics/>} />
                            <Route path="/cold-chain-metrics" element={<ColdChainMetrics/>}/>
                            <Route path="/sub-use-cases" element={<SubUseCases/>} />
                            <Route path={"/hazardous-goods-success-metrics"} element={<HazardousGoodsSuccessMetrics/>} />
                            <Route path={"/mixed-fleet-success-metrics"} element={<MixedFleetSuccessMetrics/>} />
                            <Route path={"/priority-orders-success-metrics"} element={<PriorityOrdersSuccessMetrics/>} />
                            <Route path={"/large-fleet-success-metrics"} element={<LargeFleetSuccessMetrics/>} />
                            <Route path="/use-case-generator-homepage" element={<UseCaseGeneratorHomepage/>} />
                            <Route path="/ls-homepage" element={<LSHomepage/>} />
                            <Route path="/ls-dashboard" element={<LsDashboard/>} />
                            <Route path="/self-service-homepage" element={<SelfServiceHomepage/>} />
                            <Route path="/self-service-settings-page" element={<SelfServiceSettingsPage/>}/>
                            {/*any wrong routes lead back to the HomePage*/}
                            <Route path="*" element={<Navigate to="/" />} />
                        </Routes>
                    </BrowserRouter>
                </Container>
            </AuthenticatedTemplate>

            {/*UnauthenticatedTemplate refers to the template that is displayed when not logged in*/}
            <UnauthenticatedTemplate>
                <h5>
                    <center>
                        Please sign-in to access QAI Console.
                    </center>
                </h5>
            </UnauthenticatedTemplate>
        </div>
    );
};

export default function App() {

    return (
        <PageLayout>
            <MainContent />
        </PageLayout>
    );
}