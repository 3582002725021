import React, {useEffect, useState} from 'react';
import { Bar } from 'react-chartjs-2';
import { CategoryScale, Chart, LinearScale, BarElement } from "chart.js";

const VehicleDistanceChart = (props: { vehicleId: any; distanceArrForEachVehicle: any; }) => {
    Chart.register(CategoryScale, LinearScale, BarElement);

    const [data, setData] = useState({
        labels: [],
        datasets: [
            {
                backgroundColor: '#483D8B',
                borderColor: '#483D8B',
                data: [],
            },
        ],
    });

    const percData = props.distanceArrForEachVehicle;
    const vehicleLabels = props.vehicleId;
    useEffect(() => {
        // Update the data state with the fetched values
        setData({
            ...data,
            labels: vehicleLabels,
            datasets: [
                {
                    ...data.datasets[0],
                    data: percData,
                },
            ],
        });
    }, [percData, vehicleLabels]);

    return (
        <div className="text">
            {/*<CDBContainer>*/}
                <h6 className="subheading">Distance Travelled Per Vehicle</h6>
                <Bar data={data} options={{ responsive: true }} />
            {/*</CDBContainer>*/}
        </div>
    );
};

export default VehicleDistanceChart;