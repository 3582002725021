import React from "react";
import Body from '../../components/Body';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Col, Row, Container, Card, Table, Button} from 'react-bootstrap';
import packageicon from '../../utils/img/last-mile/box.png'
import vehicleicon from '../../utils/img/last-mile/van.png'
import drivericon from '../../utils/img/last-mile/driver.png'
import timeicon from '../../utils/img/last-mile/clock.png'
import {Chart, ArcElement} from 'chart.js'
import {useNavigate} from "react-router-dom";
Chart.register(ArcElement);

const ColdChainSuccessMetrics: React.FC  = () => {
    // const {response} = React.useContext(ResponseContext) as ResponseContextType;
    // const {data} = React.useContext(DataContext) as DataContextType;
    let navigate = useNavigate();
    const handleMoreInfo = () => {
        navigate("/cold-chain-metrics");
    };

    const handleBack = () => {
        navigate("/sub-use-cases");
    }

    return (
        <Body sidebar>
            <Container className="pt-lg-3">
                <div className="justify-content-center mx-auto">
                    <Row >
                        <Col>
                            <Button className="d-grid gap-2 d-md-block" onClick={handleBack}>Back</Button>
                        </Col>
                    </Row>
                    <Row >
                        <Col>
                            <h3 className="text-center">Difficulty maintaining cold chain compliance</h3>
                            <br/>
                        </Col>
                        <Col xs={2}>
                        <Button className="float-right bg-success" onClick={handleMoreInfo} >
                            Dashboard
                        </Button>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col xs={6}>
                            <Table striped bordered hover>
                                <thead>
                                <tr>
                                    <th>Vehicles</th>
                                    <th>Capacity Per Vehicle</th>
                                    <th>Skills</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>
                                        <img src={vehicleicon} style={{width:50, height:60}} alt="nil"/> x 4</td>
                                    <td>
                                        <img src={packageicon} style={{width:50, height:50}} alt="nil"/> x 50</td>
                                    <td>Normal shipments</td>
                                </tr>
                                <tr>
                                    <td>
                                        <img src={vehicleicon} style={{width:50, height:60}} alt="nil"/> x 4</td>
                                    <td>
                                        <img src={packageicon} style={{width:50, height:50}} alt="nil"/> x 50</td>
                                    <td>Refrigerated shipments</td>
                                </tr>
                                </tbody>
                            </Table>
                        </Col>
                        <Col xs={2}>
                            <Card border="grey p-2">
                                <p className="text-center fw-bold"> Drivers</p>
                                <Card.Text>
                                    <img src={drivericon} style={{width:70, height:70}} className='p-2' alt="nil"/> x 10
                                    <br/>
                                    <p className='text-center'>No breaks for drivers</p>
                                </Card.Text>
                            </Card>
                        </Col>
                        <Col xs={4}>
                            <Table striped bordered hover>
                                <thead>
                                <tr>
                                    <th>Time Windows</th>
                                    <th>Number of Shipments</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>
                                        <img src={timeicon} style={{width:50, height:50}} alt="nil"/> 7 Hours</td>
                                    <td>
                                        <img src={packageicon} style={{width:50, height:50}} alt="nil"/> x 150</td>
                                </tr>
                                <tr>
                                    <td>
                                        <img src={timeicon} style={{width:50, height:50}} alt="nil"/> 2 to 4 Hours</td>
                                    <td>
                                        <img src={packageicon} style={{width:50, height:50}} alt="nil"/> x 150</td>
                                </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={3}>
                        <Card border="grey p-2">
                            <p className="text-center fw-bold"> Organization Start Time</p>

                            <Card.Text className='text-center'>
                                <img src={timeicon} style={{width:70, height:70}} className='p-2' alt="nil"/> 9am
                                <br/>
                            </Card.Text>
                        </Card>
                        </Col>
                        <Col xs={3}>
                        <Card border="grey p-2">
                            <p className="text-center fw-bold"> Standard Shipment Handling Time</p>
                            <Card.Text className='text-center'>
                                <img src={timeicon} style={{width:70, height:70}} className='p-2' alt="nil"/> 5 mins
                                <br/>
                            </Card.Text>
                        </Card>
                        </Col>
                    </Row>
                </div>
            </Container>
        </Body>
    )
}

export default ColdChainSuccessMetrics