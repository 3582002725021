import * as React from 'react';
import { ResponseContextType} from "./ResponseContextType";

export const ResponseContext = React.createContext<ResponseContextType | null>(null);

interface Props {
    children: React.ReactNode;
}

const ResponseProvider: React.FC<Props> = ({ children }) => {
    const [response, setResponse] = React.useState<Object>({});
    const [lsResponse, setLsResponse] = React.useState<Object>({});

    const updateResponse = (newResponse: Object) => {
        setResponse(newResponse);
        console.log('response');
        console.log(newResponse)
    };

    const updateLsResponse = (newResponse: Object) => {
        setLsResponse(newResponse);
        console.log('response');
        console.log(newResponse)
    }

    return <ResponseContext.Provider value={{ response, updateResponse, lsResponse, updateLsResponse}}>
            {children}
        </ResponseContext.Provider>
}

export default ResponseProvider;