import * as React from 'react';
import { DataContextType} from "./DataContextType";
import {useState} from "react";

export const DataContext = React.createContext<DataContextType | null>(null);

interface Props {
    children: React.ReactNode;
}

const DataProvider: React.FC<Props> = ({ children }) => {
    const [data, setData] = React.useState<Object>([]);
    const [LSdata, setLSData] = React.useState<Object>([]);
    const [location, setLocation] = React.useState('');

    //time picker
    const [startHour, setStartHour] = useState('7');
    const [startMinute, setStartMinute] = useState('00');
    const [startAmPm, setStartAmPm] = useState('AM');
    const [endHour, setEndHour] = useState('7');
    const [endMinute, setEndMinute] = useState('00');
    const [endAmPm, setEndAmPm] = useState('PM');
    const [endTime, setEndTime] = useState(61200);
    const [startTime, setStartTime] = useState(25200);
    const [simplePickups, setSimplePickups] = React.useState(0);
    const [simpleDeliveries, setSimpleDeliveries] = React.useState(20);
    const [onTheGo, setOnTheGo] = React.useState(0);

    // const [startHourChange, setStartHourChange] = React.useState('7');
    // const [startMinChange, setStartMinChange] = React.useState('00');
    // const [startAmPmChange, setStartAmPmChange] = React.useState('AM');
    // const [endHourChange, setEndHourChange] = React.useState('7');
    // const [endMinChange, setEndMinChange] = React.useState('00');
    // const [endAmPmChange, setEndAmPmChange] = React.useState('PM');


    const updateData = (newData: Object) => {
        setData(newData);
        console.log('data');
        console.log(newData)
    };

    const updateLSdata = (newData: Object) => {
        setLSData(newData);
        console.log('data');
        console.log(newData)
    }

    const updateLocation = (newLocation: string) => {
        setLocation(newLocation);
    }

    const updateSimplePickups = (newSimplePickups: number) => {
        setSimplePickups(newSimplePickups);
    }

    const updateSimpleDeliveries = (newSimpleDeliveries : number) => {
        setSimpleDeliveries(newSimpleDeliveries);
    }

    const updateOnTheGo = (newOnTheGo: number) => {
        setOnTheGo(newOnTheGo);
    }

    const updateStartHour = (newStartHour: string) => {
        setStartHour(newStartHour)
    }

    const updateStartMinute= (newStartMin: string) => {
        setStartMinute(newStartMin);
    }

    const updateStartAmPm = (newStartAmPm: string ) => {
        setStartAmPm(newStartAmPm);
    }

    const updateEndHour = (newEndHour: string) => {
        setEndHour(newEndHour);
    }

    const updateEndMinute = (newEndMinute: string) => {
        setEndMinute(newEndMinute);
    }

    const updateEndAmPm = (newEndAmPm: string) => {
        setEndAmPm(newEndAmPm);
    }

    const updateEndTime = (newEndTime: number) => {
        setEndTime(newEndTime);
    }

    const updateStartTime = (newStartTime: number) => {
        setStartTime(newStartTime);
    }

    return <DataContext.Provider value={{ data, updateData, LSdata, updateLSdata, location, updateLocation, startHour, updateStartHour, startMinute, updateStartMinute,
    startAmPm, updateStartAmPm, endAmPm, endHour, endMinute,updateEndMinute, updateEndHour, updateEndAmPm, simplePickups, updateSimplePickups, simpleDeliveries, updateSimpleDeliveries, onTheGo, updateOnTheGo,
        updateEndTime, endTime, updateStartTime, startTime}}>
        {children}
    </DataContext.Provider>
}

export default DataProvider;