import {Button, Spinner, Table} from "react-bootstrap";
import React, {useEffect} from "react";
import MmeCard from "../MiddleMileComponents/MmeCard";
import VisualisationOfNetwork from "./VisualisationOfNetwork";
import catastropheGraph
    from "../../contexts/DemoContents/LatestFiles/graphs/graph_with_meta_spire_100_shipment_profiles_catastrophe.json";
import catastrophePrediction from "../../contexts/DemoContents/LatestFiles/predictions/catastrophe_prediction.json";
import NetworkInfo from "./NetworkInfo";
import IsolatedFacilities from "./IsolatedFacilities";
import {Doughnut, Scatter} from "react-chartjs-2";
import CallApi from "../../utils/CallApi";
import {constants} from "../../utils/constants";
import {ArcElement, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Tooltip} from "chart.js";
import ParsePrediction from "../../utils/DemoSharedFunctions/ParsePredictionJson";
import GenerateStatisticsTable from "../../utils/DemoSharedFunctions/GenerateStatisticsTable";
import sleep from "../../utils/sleep";

interface CatastropheRoutingComparisonProps {
    graphJson: any;
    trainedModelJson: any;
    packagesJson: any;
    mmePredictionResults: any;
}

export default function CatastropheRoutingComparison({graphJson, trainedModelJson, packagesJson, mmePredictionResults}: CatastropheRoutingComparisonProps) {
    const [causeCatastrophe, setCauseCatastrophe] = React.useState(false);
    const [runningPredictions, setRunningPredictions] = React.useState<boolean>(false);

    const [loadingPredictionMap, setLoadingPredictionMap] = React.useState<boolean>(false);
    const [predictionMap, setPredictionMap] = React.useState<any>(null);
    const [predictionMapCatastrophe, setPredictionMapCatastrophe] = React.useState<any>(null);

    const [mmeCatastropheResults, setMmeCatastropheResults] = React.useState<any>(null);

    useEffect(() => {
        const callMappingAndPredictionApis = async () => {
            await sleep(20000);
            setRunningPredictions(true);
            setLoadingPredictionMap(true);
            setMmeCatastropheResults(catastrophePrediction);


            // let data = JSON.stringify({
            //     graph: catastropheGraph,
            //     agent: trainedModelJson,
            //     packages: packagesJson,});
            // const predictCatastropheResponse = await CallApi(constants.MME_PREDICTION_API_DOMAIN,
            //     constants.MME_PREDICTION_API_PREDICT, data, false);
            // if (predictCatastropheResponse.hasOwnProperty('error')) {
            //     console.log('Error in generateCatastrophePredictions: ', predictCatastropheResponse, predictCatastropheResponse.error);
            //     alert('Error in generateCatastrophePredictions');
            //     return;
            // }
            // else if (predictCatastropheResponse.hasOwnProperty('all_routing_info')) {
            //     setMmeCatastropheResults(predictCatastropheResponse.all_routing_info);
            // } else {
            //     console.log(predictCatastropheResponse)
            //     const errorMessage = predictCatastropheResponse.error +
            //         ', error with prediction catastrophe API';
            //     console.log(errorMessage);
            //     alert(errorMessage);
            // }
            setRunningPredictions(false);

            // call mapping api
            const mappingResponse = await CallApi(constants.CONSOLE_MAPPING_API_DOMAIN, constants.CONSOLE_MAPPING_API_GENERATE_MME_PACKAGE_ROUTE, JSON.stringify({
                list_of_package_datas: [mmePredictionResults],
                list_of_colors: ["Black"],
                display_suggestions: false,
            }), true);
            if (mappingResponse.hasOwnProperty('error')) {
                console.log('Error in generateMmeMap: ', mappingResponse);
                alert('Error in generateMmeMap');
                return;
            }
            setPredictionMap(mappingResponse);

            const mappingCatastropheResponse = await CallApi(constants.CONSOLE_MAPPING_API_DOMAIN, constants.CONSOLE_MAPPING_API_GENERATE_MME_PACKAGE_ROUTE, JSON.stringify({
                list_of_package_datas: [catastrophePrediction],
                list_of_colors: ["Black"],
                display_suggestions: false,
            }), true);
            if (mappingCatastropheResponse.hasOwnProperty('error')) {
                console.log('Error in generateMmeMap: ', mappingCatastropheResponse);
                alert('Error in generateMmeMap');
                return;
            }
            setPredictionMapCatastrophe(mappingCatastropheResponse);
            setLoadingPredictionMap(false);
        };
        callMappingAndPredictionApis().then(() => console.log('callMappingAndPredictionApis() done'));
    }, [mmePredictionResults]);

    function MultiplePackagesRoutingStatsTable() {
        ChartJS.register(ArcElement, Tooltip, Legend);
        
        let {
            numberOfNodesOnTime: numberOfNodesOnTimeMme,
            numberOfNodesLate: numberOfNodesLateMme,
            numberOfNodesStuck: numberOfNodesStuckMme,
            originalLeadTimes: originalLeadTimesMme,
            packagesOnTime: packagesOnTimeMme,
            packagesLate: packagesLateMme,
            packagesStuck: packagesStuckMme,
        } = ParsePrediction(mmePredictionResults, packagesJson);

        let {
            numberOfNodesOnTime: numberOfNodesOnTimeCatastrophe,
            numberOfNodesLate: numberOfNodesLateCatastrophe,
            numberOfNodesStuck: numberOfNodesStuckCatastrophe,
            originalLeadTimes: originalLeadTimesCatastrophe,
            packagesOnTime: packagesOnTimeCatastrophe,
            packagesLate: packagesLateCatastrophe,
            packagesStuck: packagesStuckCatastrophe,
        } = ParsePrediction(catastrophePrediction, packagesJson);

        const stuckReasons: StuckReasons = WhyStuck(packagesJson, catastrophePrediction, ["ONT", "DFW"]);

        let {
            // there are more variables for future use
            diff_path,
            now_late,
            same,
            actually_same,
        } = CheckRoutingDifferences(mmePredictionResults, mmeCatastropheResults, 'cost');

        ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend);
        const optionsMme = {
            scales: {
                x: {
                    title: {
                        display: true,
                        text: 'Shipment ID (MME)',
                    },
                },
                y: {
                    title: {
                        display: true,
                        text: 'Lead Time (MME)',
                    },
                },
            },
        };
        const dataMme = {
            datasets: [
                {
                    label: 'Lead Time',
                    data: originalLeadTimesMme,
                    pointBackgroundColor: 'black',
                },
                {
                    label: 'On Time',
                    data: packagesOnTimeMme,
                    pointBackgroundColor: 'green',
                },
                {
                    label: 'Late',
                    data: packagesLateMme,
                    pointBackgroundColor: 'orange',
                },
                {
                    label: 'Stuck',
                    data: packagesStuckMme,
                    pointBackgroundColor: 'red',
                }
            ],
        };

        const optionsCatastrophe = {
            scales: {
                x: {
                    title: {
                        display: true,
                        text: 'Shipment ID (Catastrophe)',
                    },
                },
                y: {
                    title: {
                        display: true,
                        text: 'Lead Time (Catastrophe)',
                    },
                },
            },
        };
        const dataCatastrophe = {
            datasets: [
                {
                    label: 'Lead Time',
                    data: originalLeadTimesCatastrophe,
                    pointBackgroundColor: 'black',
                },
                {
                    label: 'On Time',
                    data: packagesOnTimeCatastrophe,
                    pointBackgroundColor: 'green',
                },
                {
                    label: 'Late',
                    data: packagesLateCatastrophe,
                    pointBackgroundColor: 'orange',
                },
                {
                    label: 'Stuck',
                    data: packagesStuckCatastrophe,
                    pointBackgroundColor: 'red',
                }
            ],
        };

        interface PredictionResults {
            [key: string]: any;
        }

        function CalculateCostIncrease() {
            let totalCostInfo1 = 0;
            let totalCostInfo2 = 0;
            Object.entries(mmePredictionResults as PredictionResults).forEach(([packageId, mmePrediction]) => {
                if (mmePrediction.is_at_destination && mmeCatastropheResults[packageId].is_at_destination) {
                    totalCostInfo1 += mmePrediction.total_cost;
                    totalCostInfo2 += mmeCatastropheResults[packageId].total_cost;
                }
            });
            return ((totalCostInfo2 - totalCostInfo1) / totalCostInfo1) * 100;
        }

        return (
            <>
                <div style={{ display: 'flex', width: '60%' }}>
                    {/*this Doughnut is for mme*/}
                    <div style={{ width: '30%', height: '30%', flex: 1 }}>
                        <Doughnut
                            data={{
                                labels: ["On Time", "Late", "Stuck"],
                                datasets: [
                                    {
                                        label: "Shipment Status for Pre-Consolidated",
                                        data: [numberOfNodesOnTimeMme, numberOfNodesLateMme, numberOfNodesStuckMme],
                                        backgroundColor: ["green", "orange", "red"],
                                        borderColor: ["green", "orange", "red"],
                                        borderWidth: 1,
                                    },
                                ]
                            }}
                            options={{
                              plugins: {
                                  title: {
                                      display: true,
                                      text: 'MMe (before Catastrophe)',
                                      font: {
                                          size: 18,
                                          weight: 'bold',
                                      },
                                  },
                              }}}/></div>
                    {/*this Doughnut is for After Catastrophe*/}
                    <div style={{ width: '30%', height: '30%', flex: 1}}>
                        <Doughnut data={{
                            labels: ["On Time", "Late", "Stuck"],
                            datasets: [
                                {
                                    label: "Shipment Status for Pre-Consolidation",
                                    data: [numberOfNodesOnTimeCatastrophe,numberOfNodesLateCatastrophe, numberOfNodesStuckCatastrophe],
                                    backgroundColor: ["green", "orange", "red"],
                                    borderColor: ["green", "orange", "red"],
                                    borderWidth: 1,
                                },
                            ],
                        }}
                                  options={{
                                      plugins: {
                                          title: {
                                              display: true,
                                              text: 'MMe (after Catastrophe)',
                                              font: {
                                                  size: 18,
                                                  weight: 'bold',
                                              },
                                          },
                                      }}}/></div></div>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th style={{textAlign: 'center'}} colSpan={2}>
                            Impact on Shipments
                        </th>
                    </tr>
                    </thead>
                    <thead>
                    <tr>
                        <th>Number of Shipments not Impacted</th>
                        <th>Number of Shipments Impacted</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>{actually_same.length + same.length}</td>
                        <td>{diff_path.length}</td>
                    </tr>
                    </tbody>
                </Table>

                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th style={{textAlign: 'center'}} colSpan={4}>
                            Analysis of Impacted Shipments
                        </th>
                    </tr>
                    </thead>
                    <thead>
                    <tr>
                        <th>Number stuck due to closure of origin and destination</th>
                        <th>Number stuck NOT due to closure of origin and destination</th>
                        <th>Number of shipments which are now late</th>
                        <th>Average cost increase</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>{stuckReasons.reduce((accumulator: number, currentReason: string) => {
                            if (currentReason === 'origin and destination hub down' ||
                                currentReason === 'origin hub down' ||
                                currentReason === 'destination hub down') {
                                return accumulator + 1;
                            }
                            return accumulator;
                        }, 0)}</td>
                        <td>{stuckReasons.reduce((accumulator: number, currentReason: string) => {
                            if (currentReason === 'code couldn\'t solve it') {
                                return accumulator + 1;
                            }
                            return accumulator;
                        }, 0)}</td>
                        <td>{now_late.length}</td>
                        {/*
                        total_cost_info1 = 0
                        total_cost_info2 = 0
                        foreach pkg
                            if info1[is_at_destination] and info2[is_at_destination]:
                            total_cost_info1 += info1[total_cost]
                                total_cost_info2 += info2[total_cost]
                        total2 - total1 / total1
                        */}
                        <td>{(CalculateCostIncrease()).toFixed(2)}%</td>
                    </tr>
                    </tbody>
                </Table>
                {GenerateStatisticsTable([mmePredictionResults, catastrophePrediction], packagesJson)}
                <div style={{display: 'flex'}}>
                    <div style={{flex: 1}}>
                        <Scatter options={optionsMme} data={dataMme} />
                    </div>
                    <div style={{flex: 1}}>
                        <Scatter options={optionsCatastrophe} data={dataCatastrophe} />
                    </div>
                </div>
                {loadingPredictionMap
                    ? <Spinner/>
                    : (predictionMap && <div style={{ display: 'flex', width: '80%' }}>
                        <div style={{flex:1}} className="content" dangerouslySetInnerHTML={{__html: predictionMap}}></div>
                        <div style={{flex:1}} className="content" dangerouslySetInnerHTML={{__html: predictionMapCatastrophe}}></div>
                    </div>)
                }
            </>
        )
    }

    interface PackageInfo {
        id: number;
        origin_hub: string;
        destination_hub: string;
    }

    interface PredInfo {
        [key: string]: {
            is_stuck: boolean;
        };
    }

    interface StuckReasons {
        [key: string]: any;
    }

    function WhyStuck(
        packageInfo: PackageInfo[],
        predInfo: PredInfo,
        killed: string[] = []
    ) {
        const findIndexFormat = packageInfo.map((item) => item.id);
        const stuckReasons: StuckReasons = {};

        for (const pkg in predInfo) {
            if (predInfo.hasOwnProperty(pkg) && predInfo[pkg].is_stuck) {
                const ind = findIndexFormat.indexOf(parseInt(pkg));
                const packageItem = packageInfo[ind];

                if (
                    killed.includes(packageItem.origin_hub) &&
                    killed.includes(packageItem.destination_hub)
                ) {
                    stuckReasons[pkg] = 'origin and destination hub down';
                } else if (killed.includes(packageItem.origin_hub)) {
                    stuckReasons[pkg] = 'origin hub down';
                } else if (killed.includes(packageItem.destination_hub)) {
                    stuckReasons[pkg] = 'destination hub down';
                } else {
                    stuckReasons[pkg] = "code couldn't solve it";
                }
            }
        }

        return Object.values(stuckReasons);
    }

    interface PackageInfo {
        is_stuck: boolean;
        arrived_before_pdd: boolean;
        path: {
            departure_hub: string;
            arrival_hub: string;
        }[];
        [key: string]: any; // Include other properties if present in the actual data structure
    }

    type PackageSet = { [key: string]: PackageInfo };

    function CheckRoutingDifferences(
        set1: PackageSet,
        set2: PackageSet,
        optimization: string
    ) {
        if (optimization === 'cost') {
            optimization = 'total_cost';
        }

        const diff_path: string[] = [];
        const now_stuck: string[] = [];
        const now_unstuck: string[] = [];
        const now_late: string[] = [];
        const now_ontime: string[] = [];
        const same_path_more_expensive: string[] = [];
        const same_path_less_expensive: string[] = [];
        const same_cost_diff_conn: string[] = [];
        const same: string[] = [];
        const actually_same: string[] = [];
        const now_cost_more: string[] = [];
        const now_cost_less: string[] = [];

        for (const pkg in set1) {
            if (set1.hasOwnProperty(pkg)) {
                const info = set1[pkg];
                const info2 = set2[pkg];

                if (info.is_stuck && info2.is_stuck) {
                    same.push(pkg);
                } else if (!info.is_stuck && info2.is_stuck) {
                    now_stuck.push(pkg);
                    diff_path.push(pkg);
                } else if (info.is_stuck && !info2.is_stuck) {
                    now_unstuck.push(pkg);
                    diff_path.push(pkg);
                } else if (info.arrived_before_pdd && !info2.arrived_before_pdd) {
                    now_late.push(pkg);
                    diff_path.push(pkg);
                } else if (!info.arrived_before_pdd && info2.arrived_before_pdd) {
                    now_ontime.push(pkg);
                    diff_path.push(pkg);
                } else {
                    const path1 = [info.path[0].departure_hub, ...info.path.map((item) => item.arrival_hub)];
                    const path2 = [info2.path[0].departure_hub, ...info2.path.map((item) => item.arrival_hub)];

                    if (path1.join() !== path2.join()) {
                        diff_path.push(pkg);
                        if (info[optimization] > info2[optimization]) {
                            now_cost_more.push(pkg);
                        } else {
                            now_cost_less.push(pkg);
                        }
                    } else {
                        if (info.path === info2.path) {
                            actually_same.push(pkg);
                        } else if (info[optimization] === info2[optimization]) {
                            same_cost_diff_conn.push(pkg);
                            same.push(pkg);
                        } else if (info[optimization] < info2[optimization]) {
                            same_path_more_expensive.push(pkg);
                            same.push(pkg);
                        } else {
                            same_path_less_expensive.push(pkg);
                            same.push(pkg);
                        }
                    }
                }
            }
        }

        return {
            diff_path,
            now_stuck,
            now_unstuck,
            now_late,
            now_ontime,
            same_path_more_expensive,
            same_path_less_expensive,
            same_cost_diff_conn,
            same,
            actually_same,
            now_cost_more,
            now_cost_less,
    };
    }


    return (
        <MmeCard
            title={'Catastrophe Reactivity'}
            description={'In this scenario we will highlight the flexibility and reactivity of MME. ' +
                'Basing this on a simulated scenario of an emergency situation at facilities ONT and DFW ' +
                'leading to full shutdowns including the shut down of incoming and outgoing connections, we will ' +
                'reroute all shipments that have possible paths to their destinations. We will also allow you to ' +
                'explore statistics of how these new routes impact the current stand of the shipments.'}
            body={
                <div style={{ maxHeight: '400px', overflow: 'auto' }}>
                    {!causeCatastrophe && <Button onClick={()=>setCauseCatastrophe(!causeCatastrophe)}>Cause Catastrophe</Button>}
                    {causeCatastrophe &&
                        <div>{runningPredictions
                            ? <Spinner/>
                            : <div>
                                <VisualisationOfNetwork graphJson={graphJson} secondGraphJson={graphJson}/>
                                <div>
                                    <div style={{float: 'left'}}>
                                        <NetworkInfo graphJson={graphJson}/>
                                        <IsolatedFacilities graphJson={graphJson}/>
                                    </div>
                                    <div style={{float: 'right'}}>
                                        <NetworkInfo graphJson={catastropheGraph}/>
                                        <IsolatedFacilities graphJson={catastropheGraph}/>
                                    </div>
                                </div>
                                <div style={{float: 'left'}}>
                                    {mmePredictionResults && mmeCatastropheResults && <MultiplePackagesRoutingStatsTable/>}
                                </div>
                            </div>}
                        </div>}
                </div>
            }/>
    )
}