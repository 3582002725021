import {Button, Spinner, Table} from "react-bootstrap";
import React, {useEffect} from "react";
import MmeCard from "../MiddleMileComponents/MmeCard";
import CallApi from "../../utils/CallApi";
import {constants} from "../../utils/constants";
import mmePredictionJsonCached from "../../contexts/DemoContents/LatestFiles/predictions/new_mme_prediction.json";
import {Doughnut, Scatter} from "react-chartjs-2";
import {Chart as ChartJS, ArcElement, Legend, Tooltip, LineElement, LinearScale, PointElement} from "chart.js";
import sleep from "../../utils/sleep";
import ParsePrediction from "../../utils/DemoSharedFunctions/ParsePredictionJson";
import GenerateStatisticsTable from "../../utils/DemoSharedFunctions/GenerateStatisticsTable";

interface RoutingStatisticsProps {
    graphJson?: any;
    trainedModelJson?: any;
    packagesJson?: any;
    mmePredictionJson?: any;
    mmePredictionTimeGenerated?: number;
    onMmePredictionGenerated?: (mmePredictionResults: any, mmePredictionTime: number) => void;
    useCached: boolean;
}

interface PredictionResults {
    [key: string]: any;
}

export default function RoutingStatistics({graphJson, trainedModelJson, packagesJson, mmePredictionJson, mmePredictionTimeGenerated,
                                              onMmePredictionGenerated, useCached}: RoutingStatisticsProps) {
    const [mmePredictionResults, setMmePredictionResults] = React.useState<any>(null);
    const [mmePredictionTime, setMmePredictionTime] = React.useState<number>(0);
    const [loadingPredictionMap, setLoadingPredictionMap] = React.useState<boolean>(false);
    const [predictionMap, setPredictionMap] = React.useState<any>(null);

    ChartJS.register(ArcElement, Tooltip, Legend);

    const generatePredictions = async () => {
        // Can uncomment the block below to use the predictionInfo json file, and comment out the rest of the function
        setLoadingPredictionMap(true);
        if (useCached) {
            await sleep(20000);
            setMmePredictionResults(mmePredictionJsonCached);
            setLoadingPredictionMap(true);
            setMmePredictionTime(30);
            const mappingResponse = await CallApi(constants.CONSOLE_MAPPING_API_DOMAIN, constants.CONSOLE_MAPPING_API_GENERATE_MME_PACKAGE_ROUTE, JSON.stringify({
                list_of_package_datas: [mmePredictionJsonCached],
                list_of_colors: ["Black"],
                display_suggestions: false,
            }), true);
            if (mappingResponse.hasOwnProperty('error')) {
                console.log('Error in generateMmeMap: ', mappingResponse, mappingResponse.error);
                alert('Error in generateMmeMap');
                return;
            }
            setPredictionMap(mappingResponse);
        } else {
            setMmePredictionResults(mmePredictionJson);
            setLoadingPredictionMap(true);
            setMmePredictionTime(mmePredictionTimeGenerated || 30);
            const mappingResponse = await CallApi(constants.CONSOLE_MAPPING_API_DOMAIN, constants.CONSOLE_MAPPING_API_GENERATE_MME_PACKAGE_ROUTE, JSON.stringify({
                list_of_package_datas: [mmePredictionJson],
                list_of_colors: ["Black"],
                display_suggestions: false,
            }), true);
            if (mappingResponse.hasOwnProperty('error')) {
                console.log('Error in generateMmeMap: ', mappingResponse, mappingResponse.error);
                alert('Error in generateMmeMap');
                return;
            }
            setPredictionMap(mappingResponse);
        }
        setLoadingPredictionMap(false);
    }

    function MultiplePackagesRoutingStatsTable() {
        let {
            numberOfNodesOnTime,
            numberOfNodesLate,
            numberOfNodesStuck,
            originalLeadTimes,
            packagesOnTime,
            packagesLate,
            packagesStuck
        } = ParsePrediction(mmePredictionResults, packagesJson);

        // Scatter-plot needed information
        ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend);
        const optionsScatterPlot = {
            scales: {
                x: {
                    title: {
                        display: true,
                        text: 'Shipment ID',
                    },
                },
                y: {
                    title: {
                        display: true,
                        text: 'Lead Time (Hours)',
                    },
                },
            },
        };
        const dataScatterPlot = {
            datasets: [
                {
                    label: 'Lead Time',
                    data: originalLeadTimes,
                    pointBackgroundColor: 'black',
                },
                {
                    label: 'On Time',
                    data: packagesOnTime,
                    pointBackgroundColor: 'green',
                },
                {
                    label: 'Late',
                    data: packagesLate,
                    pointBackgroundColor: 'orange',
                },
                {
                    label: 'Stuck',
                    data: packagesStuck,
                    pointBackgroundColor: 'red',
                }
            ],
        };

        return (
            <>
                <div style={{ display: 'flex', width: '90%' }}>
                    <div style={{width: '100%', flex: 1}}>
                        <Doughnut
                            data={{
                                labels: ["On Time", "Late", "Stuck"],
                                datasets: [
                                    {
                                        label: "Shipment Status",
                                        data: [numberOfNodesOnTime, numberOfNodesLate, numberOfNodesStuck],
                                        backgroundColor: ["green", "orange", "red"],
                                        borderColor: ["green", "orange", "red"],
                                        borderWidth: 1,
                                    },
                                ],
                            }}
                            options={{
                                maintainAspectRatio: true, // Allow the chart to maintain aspect ratio
                                responsive: false, // Enable responsiveness
                            }}
                        />
                    </div>
                    <div style={{flex: 1, paddingBottom: '10px'}}>
                        <Scatter  options={optionsScatterPlot} data={dataScatterPlot} />
                    </div>
                </div>
                {GenerateStatisticsTable([mmePredictionResults], packagesJson)}
            </>
        )
    }

    // this is to pass the mmePredictionResults to the parent component, to be passed to AStarRoutingStatisticsComparison for example
    useEffect(() => {
        if (onMmePredictionGenerated) {
            onMmePredictionGenerated(mmePredictionResults, mmePredictionTime);
        }
    }, [onMmePredictionGenerated, mmePredictionResults, mmePredictionTime]);

    return (
        <MmeCard
            title={'Routing Statistics'}
            description={''}
            body={
                <div style={{ maxHeight: '400px', overflow: 'auto' }}>
                    {!mmePredictionResults && <Button onClick={generatePredictions}>Route Shipments</Button>}
                    {mmePredictionResults && (Object.keys(mmePredictionResults).length > 1
                        ? <MultiplePackagesRoutingStatsTable/>
                        :
                        // there is only one package, but this is easier to do than to use .at(0) on the object
                        Object.entries(mmePredictionResults as PredictionResults).map(([packageId, packageValue]) =>
                            <Table striped bordered hover>
                                <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Origin Hub</th>
                                    <th>Destination Hub</th>
                                    <th>Delivery Status</th>
                                    <th>Volume Moved(Kg)</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>{packageId}</td>
                                    <td>{packageValue.path[0].departure_hub}</td>
                                    <td>{packageValue.path.at(-1).arrival_hub}</td>
                                    {/* is this correct */}
                                    <td>{packageValue.is_at_destination
                                        ? 'At Destination'
                                        : packageValue.is_stuck
                                            ? 'Stuck'
                                            : 'Late'}</td>
                                    <td>{packageValue.is_at_destination && packageValue.arrived_before_pdd
                                        // again, there should only be one package, so using [0] should be correct
                                        ? packagesJson[0].volume
                                        : 'Not on time'}</td>
                                </tr>
                                </tbody>
                                <thead>
                                <tr>
                                    <th>Cost($)</th>
                                    <th>Duration</th>
                                    <th>Number Of Connections Taken</th>
                                    <th>Total Carbon Emissions</th>
                                    <th>Distance</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    {/* is it total_cost or last_cost */}
                                    <td>{packageValue.total_cost}</td>
                                    <td>{packageValue.duration}</td>
                                    <td>{packageValue.path.length}</td>
                                    <td>{packageValue.duration/3600*6000*3.1}</td>
                                    <td>{packageValue.distance}</td>
                                </tr>
                                </tbody>
                            </Table>
                        ))}
                        {/*here include the new scatter plot, just gotta figure out how to use it*/}
                        {loadingPredictionMap
                            ? <Spinner/>
                            : (predictionMap && <div style={{width: '50%'}} className="content" dangerouslySetInnerHTML={{__html: predictionMap}}></div>)
                    }
                </div>
            }/>
    )
}