import React, {useEffect, useState} from 'react';
import { Bar } from 'react-chartjs-2';
import { CDBContainer } from 'cdbreact';
import { CategoryScale, Chart, LinearScale, BarElement } from "chart.js";

const VehicleUtilisationChart = (props: { vehicleNum: any; percentages: any; }) => {
    Chart.register(CategoryScale, LinearScale, BarElement);

    const [data, setData] = useState({
        labels: [],
        datasets: [
            {
                label: 'My First dataset',
                backgroundColor: '#483D8B',
                borderColor: '#483D8B',
                data: [],
            },
        ],
    });

    const percData = props.percentages;
    const vehicleLabels = props.vehicleNum;
    useEffect(() => {
        // Update the data state with the fetched values
            setData({
                ...data,
                labels: vehicleLabels,
                datasets: [
                    {
                        ...data.datasets[0],
                        data: percData,
                    },
                ],
            });
    }, [percData, vehicleLabels]);

    return (
        <div>
            <CDBContainer>
                <h3 className="mt-5">Vehicle Utilisation %</h3>
                <Bar data={data} options={{ responsive: true }} />
            </CDBContainer>
        </div>
    );
};

export default VehicleUtilisationChart;