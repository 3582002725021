import {Table} from "react-bootstrap";
import React, {useEffect} from "react";
import MmeCard from "../MiddleMileComponents/MmeCard";
import CallApi from "../../utils/CallApi";
import {constants} from "../../utils/constants";

interface PackageInfoProps {
    packageJson: any;
    graphJson: any;
}

export default function PackageInfo({packageJson, graphJson}: PackageInfoProps) {
    // seems to be needed occasionally
    const innerPackageJson = packageJson.hasOwnProperty('packageJson')
        ? packageJson.packageJson
        : packageJson;
    const [mapOfOriginsFacilities, setMapOfOriginsFacilities] = React.useState<any>({})
    const [mapOfDestinationFacilities, setMapOfDestinationFacilities] = React.useState<any>({})

    useEffect(() => {
        const callMappingApis = async (listOfOriginsFacilitiesCopy: any[][], listOfDestinationFacilitiesCopy: any[][]) => {
            const mappingOriginResponse = await CallApi(constants.CONSOLE_MAPPING_API_DOMAIN, constants.CONSOLE_MAPPING_API_GENERATE_HUBS_MAP, JSON.stringify({
                hubs_data: listOfOriginsFacilitiesCopy,
            }), true);
            if (mappingOriginResponse.hasOwnProperty('error')) {
                console.log('Error in generateMmeMap: ', mappingOriginResponse, mappingOriginResponse.error);
                alert('Error in generateMmeMap');
                return;
            }
            setMapOfOriginsFacilities(mappingOriginResponse);
            const mappingDestinationResponse = await CallApi(constants.CONSOLE_MAPPING_API_DOMAIN, constants.CONSOLE_MAPPING_API_GENERATE_HUBS_MAP, JSON.stringify({
                hubs_data: listOfDestinationFacilitiesCopy,
            }), true);
            if (mappingDestinationResponse.hasOwnProperty('error')) {
                console.log('Error in generateMmeMap: ', mappingDestinationResponse, mappingDestinationResponse.error);
                alert('Error in generateMmeMap');
                return;
            }
            setMapOfDestinationFacilities(mappingDestinationResponse);
        };
        // iterate through, add it to the array if not already there, if there then add the count
        const listOfOriginsFacilitiesCopy: any[][] = [];
        const listOfDestinationFacilitiesCopy: any[][] = [];
        innerPackageJson.forEach((currentPackage: any) => {
            const indexOfOriginHub = listOfOriginsFacilitiesCopy.findIndex((el: any) => el[0] === currentPackage.origin_hub);
            if (indexOfOriginHub !== -1) {
                listOfOriginsFacilitiesCopy[indexOfOriginHub][1][0]++;
            } else {
                // find the coordinates of the origin hub from the graph
                const originHub = graphJson.graph.nodes.find((node: any) => node[0] === currentPackage.origin_hub);
                listOfOriginsFacilitiesCopy.push([currentPackage.origin_hub, [1, [originHub[1].lat, originHub[1].lon]]]);
            }
            const indexOfDestinationHub = listOfDestinationFacilitiesCopy.findIndex((el: any) => el[0] === currentPackage.destination_hub);
            if (indexOfDestinationHub !== -1) {
                listOfDestinationFacilitiesCopy[indexOfDestinationHub][1][0]++;
            } else {
                // find the coordinates of the destination hub from the graph
                const destinationHub = graphJson.graph.nodes.find((node: any) => node[0] === currentPackage.destination_hub);
                listOfDestinationFacilitiesCopy.push([currentPackage.destination_hub, [1, [destinationHub[1].lat, destinationHub[1].lon]]]);
            }
        });
        // call new API
        callMappingApis(listOfOriginsFacilitiesCopy, listOfDestinationFacilitiesCopy);
    }, [innerPackageJson])

    return (
        <MmeCard
            title={'Shipment Info'}
            description={''}
            body={
                <div style={{ maxHeight: '400px', overflow: 'auto' }}>
                    {innerPackageJson.length === 1
                        ? <>
                            <Table striped bordered hover>
                                <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Origin Facility Density</th>
                                    <th>Destination Facility Density</th>
                                    <th>Priority</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <th>{innerPackageJson[0].id}</th>
                                    <th>{innerPackageJson[0].origin_hub}</th>
                                    <th>{innerPackageJson[0].destination_hub}</th>
                                    <th>{innerPackageJson[0].priority}</th>
                                </tr>
                                </tbody>
                                <thead>
                                <tr>
                                    <th>Count</th>
                                    <th>Volume</th>
                                    <th>Lead Time</th>
                                    <th>Shipment Profile</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <th>{innerPackageJson[0].count}</th>
                                    <th>{innerPackageJson[0].volume}</th>
                                    <th>{(innerPackageJson[0].pdd)/3600}</th>
                                    <th>{innerPackageJson[0].shipment_profile}</th>
                                </tr>
                                </tbody>
                            </Table>
                        </>
                        :
                        <div>
                            <Table striped bordered hover>
                                <thead>
                                <tr>
                                    <th>Number of Shipments</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>{innerPackageJson.length}</td>
                                </tr>
                                </tbody>
                                <thead>
                                <tr>
                                    <th>Total Volume</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>{innerPackageJson.reduce((acc: any, packageValue: { volume: any; }) => acc + packageValue.volume, 0).toFixed(2)}</td>
                                </tr>
                                </tbody>
                            </Table>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Origin Facility</th>
                                        <th>Destination Facility</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        {/*set the width of td to be 50% */}
                                        <td style={{width:'50%'}}>
                                            {mapOfOriginsFacilities && <div className="content" dangerouslySetInnerHTML={{__html: mapOfOriginsFacilities}}></div>}                                        </td>
                                        <td>
                                            {mapOfDestinationFacilities && <div className="content" dangerouslySetInnerHTML={{__html: mapOfDestinationFacilities}}></div>}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    }
                </div>
            }/>
    )
}