interface PredictionResults {
    [key: string]: any;
}

// this function parses the prediction results and returns the values needed for the tables and charts
export default function ParsePrediction(predictionResults: PredictionResults, packagesJson: any) {
    const uniqueEdges = new Set();
    let totalVolumeMoved = 0;
    let totalNumberOfConnectionsTaken = 0;
    let totalCost = 0;
    let totalDuration = 0;
    let totalDistance = 0;
    let totalCo2EmissionsUniqueEdges = 0; // for table1 : total co2 emissions for unique edges
    let totalCo2EmissionsAllEdges = 0; // for table2: total co2 emissions for all edges / number of packages
    let numberOfNodesOnTime = 0;
    let numberOfNodesLate = 0;
    let numberOfNodesStuck = 0;
    const originalLeadTimes: { x: any; y: number; }[] = [];
    const packagesOnTime: { x: any; y: number; }[] = [];
    const packagesLate: { x: any; y: number; }[] = [];
    const packagesStuck: { x: any; y: number; }[] = [];


    let packageIndex = 0;
    Object.entries(predictionResults as PredictionResults).forEach(([packageId, packageValue]) => {
        packageIndex++;
        const packageIdInt = Number(packageId);
        // find out the order date of the package
        const currentPackage = packagesJson.find((currentPackage: any) => currentPackage.id === packageIdInt);
        const orderDate = currentPackage.order_date;
        const pdd = currentPackage.pdd;
        // find out package status
        if (packageValue.arrived_before_pdd) {
            numberOfNodesOnTime += 1;
            originalLeadTimes.push({x: packageIndex, y: (pdd - orderDate) / 3600});
            packagesOnTime.push({x: packageIndex, y: (packageValue.path.at(-1).arrival_time - orderDate) / 3600});
            // check for unique edges to calculate CO2
            for (const edge of packageValue.path) {
                const edgeKey = JSON.stringify(edge);
                if (!uniqueEdges.has(edgeKey)) {
                    uniqueEdges.add(edgeKey);
                    totalCo2EmissionsUniqueEdges += edge.co2_emissions;
                }
            }
            totalVolumeMoved += packagesJson.find((currentPackage: any) => currentPackage.id === packageIdInt).volume;
            totalNumberOfConnectionsTaken += packageValue.path.length;
            totalCost += packageValue.total_cost;
            totalDuration += packageValue.path.reduce((accumulator: number, currentValue: any) => {
                return accumulator + currentValue.duration;
            }, 0);
            totalCo2EmissionsAllEdges += packageValue.path.reduce((accumulator: number, currentValue: any) => {
                return accumulator + currentValue.co2_emissions;
            }, 0);
            totalDistance += packageValue.path.reduce((accumulator: number, currentValue: any) => {
                return accumulator + currentValue.distance;
            }, 0);
        } else {
            if (packageValue.is_stuck) {
                numberOfNodesStuck += 1;
                packagesStuck.push({x: packageIndex, y: 0});
            } else {
                // these packages are late
                numberOfNodesLate += 1;
                originalLeadTimes.push({x: packageIndex, y: (pdd - orderDate) / 3600});
                packagesLate.push({x: packageIndex, y: (packageValue.path.at(-1).arrival_time - orderDate) / 3600});
                // check for unique edges to calculate CO2
                for (const edge of packageValue.path) {
                    const edgeKey = JSON.stringify(edge);
                    if (!uniqueEdges.has(edgeKey)) {
                        uniqueEdges.add(edgeKey);
                        totalCo2EmissionsUniqueEdges += edge.co2_emissions;
                    }
                }
            }
        }
    })
    const averageNumberOfConnectionsTaken = totalNumberOfConnectionsTaken / numberOfNodesOnTime;
    const averageCost = totalCost / numberOfNodesOnTime;
    const averageDuration = totalDuration / numberOfNodesOnTime;
    const averageDistance = totalDistance / numberOfNodesOnTime;
    const averageCo2Emissions = totalCo2EmissionsAllEdges / numberOfNodesOnTime;
    return {
        totalVolumeMoved,
        totalNumberOfConnectionsTaken,
        averageNumberOfConnectionsTaken,
        totalCost,
        averageCost,
        totalDuration,
        averageDuration,
        totalDistance,
        averageDistance,
        totalCo2EmissionsUniqueEdges,
        averageCo2Emissions,
        numberOfNodesOnTime,
        numberOfNodesLate,
        numberOfNodesStuck,
        originalLeadTimes,
        packagesOnTime,
        packagesLate,
        packagesStuck
    };
}