import React, {useState, useEffect} from "react";
import Body from '../../components/Body';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Col, Row, Container, Table, ListGroup, Button, Spinner} from 'react-bootstrap';
import {Doughnut} from "react-chartjs-2";
import {useNavigate} from "react-router-dom";
import CallApi from "../../utils/CallApi";
import {constants} from "../../utils/constants";
import {ResponseContext} from "../../contexts/LastMileContexts/ResponseContext";
import {ResponseContextType} from "../../contexts/LastMileContexts/ResponseContextType";
import {DataContext} from "../../contexts/LastMileContexts/DataContext";
import {DataContextType} from "../../contexts/LastMileContexts/DataContextType";
import GetMetrics from "../../utils/GetMetrics";
import {MetricsContext} from "../../contexts/LastMileContexts/MetricsContext";
import {MetricsContextType} from "../../contexts/LastMileContexts/MetricsContextType";
import VehicleUtilisationChart from "../../components/LastMileComponents/VehicleUtilisationChart";

// @ts-ignore
const ColdChainMetrics: React.FC  = () => {
    // Chart.register(CategoryScale, LinearScale, BarElement);
    let navigate = useNavigate();
    const {response} = React.useContext(ResponseContext) as ResponseContextType;
    const {data} = React.useContext(DataContext) as DataContextType;

    const [mapForRoutes, setMapForRoutes] = useState('');
    const [mapForPackages, setMapForPackages] = useState('');
    const [loading, setLoading] = useState(false);
    const [clusterLoading, setClusterLoading] = useState(false);
    const[visibleRouteMap, setVisibleRouteMap] = useState(false);
    const[visiblePackageMap, setVisiblePackageMap] = React.useState(false);
    const {useCase} = React.useContext(MetricsContext) as MetricsContextType;

    //getting metrics
    const[metrics, setMetrics] = useState<string[]>([]) ;

    //missed packages
    const json = JSON.stringify(response);
    const parsed = JSON.parse(json);
    let numMissed = 0;
    if(parsed.missedIds === undefined) {
    } else {
        const missedIDsArr = parsed.missedIds;
        console.log(
            'missedIDsArr'
        )
        console.log(missedIDsArr)
        // @ts-ignore
        numMissed = Object.entries(missedIDsArr)[0][1].length;
    }

    useEffect(() => {
        let ignore = false;
        if (!ignore)  {
            console.log('getmetrics response')
            console.log(response);
            GetMetrics(data, response).then(
                res => {
                    const metrics = res;
                    setMetrics(metrics);
                    console.log('m')
                    console.log(metrics);
                })
                .catch(err => console.log(err));
        }
        return () => { ignore = true; }
    }, [data, response]);


    const handleBack = () => {
        if(useCase === 'coldChainContent') {
            navigate("/cold-chain-success-metrics");
        } else if(useCase === 'hazardousGoodsContent') {
            navigate("/hazardous-goods-success-metrics");
        } else if(useCase === 'largeFleetContent') {
            navigate("/large-fleet-success-metrics")
        } else if(useCase === 'priorityOrdersContent') {
            navigate("/priority-orders-success-metrics");
        } else if(useCase === 'mixedFleetContent') {
            navigate("/mixed-fleet-success-metrics");
        } else if(useCase === 'lateArrivalsContent') {
            navigate("/late-arrivals-success-metrics");
        }
    }

    const handleRouteMapChange = () => {
        setVisibleRouteMap(!visibleRouteMap);
        // setLoading(true);
        routeMap();
    };

    const handlePackageMapChange = () => {
        setClusterLoading(true);
        setVisiblePackageMap(!visiblePackageMap);
        clusterMap();
    }

    //route map
    const routeMap = () => {
        setLoading(true);
        CallApi(constants.CONSOLE_MAPPING_API_DOMAIN, constants.CONSOLE_MAPPING_API_GENERATE_ROUTE_MAP, JSON.stringify({
            data: data,
            response: response
        }), true)
        .then((data) => {
            console.log('string');
            console.log(data);
            setMapForRoutes(data);
        })
        setLoading(false);
    }

    const clusterMap = () => {
        CallApi(constants.CONSOLE_MAPPING_API_DOMAIN, constants.CONSOLE_MAPPING_API_GENERATE_CLUSTER_MAP, JSON.stringify({
            data: data,
            response: response
        }), true)
        .then((data) => {
            console.log('string');
            console.log(data);
            setMapForPackages(data);
        })
        setClusterLoading(false);
    }

    //vehlcle utilisation metrics

    const vehicleUtilisation = () => {
        CallApi(constants.CONSOLE_MAPPING_API_DOMAIN, constants.CONSOLE_MAPPING_API_GENERATE_VEHICLE_UTILIZATION_METRICS, JSON.stringify({
            data: data,
            response: response
        }))
            .then((data) => {
                console.log('string');
                console.log(data);
                setVehicleUtilisationData(data);
                console.log(vehicleUtilisationData);
                // let obj = Object.entries(vehicleUtilisationData)[0][1];
                // // @ts-ignore
                // console.log(obj.percentage)
                // // @ts-ignore
                // percentages.push(obj.percentage);
            })
    }

    // const percentages = new Array<number>();
    const [percentages, setPercentages] = useState<number[]>([]);
    const [vehicleNum, setVehicleNum] = useState<string[]>([]);
    // const vehicleNum = new Array<string>();
    const [vehicleUtilisationData, setVehicleUtilisationData] = useState(Object);
    useEffect(() => {
        vehicleUtilisation();
    }, []); // Run the effect only once, when the component mounts

    const [numVehicles, setNumVehicles] = useState(0);
    useEffect(() => {
    console.log(vehicleUtilisationData);
    setNumVehicles(Object.keys(vehicleUtilisationData).length);
        let updatedPercentages = new Array<number>();
        let updatedVehicleNum = new Array<string>();
    for(let i=0; i<numVehicles; i++) {
        let obj = Object.entries(vehicleUtilisationData)[i][1];
        console.log('obj')
        console.log(obj)
        // @ts-ignore
        const newPercentage = obj.percentage;
        console.log('newPercentage')
        console.log(newPercentage)
        updatedPercentages =  [...updatedPercentages, newPercentage];
        const newVehicleNum = Object.entries(vehicleUtilisationData)[i][0];
        updatedVehicleNum = [...updatedVehicleNum, newVehicleNum];
        // // @ts-ignore
        // percentages.push(obj.percentage);
        // vehicleNum.push(Object.entries(vehicleUtilisationData)[i][0]);
    }
    let arr = [1,1,1]
        setPercentages(updatedPercentages as typeof arr)
        // @ts-ignore
        setVehicleNum(updatedVehicleNum);

    }, [vehicleUtilisationData, numVehicles]);

    console.log('PERCENTAGES')
    console.log(percentages)
    console.log('VEHICLE NUM')
    console.log(numVehicles)

    //getting packages details
    const numShipments = 300;
    const packagesDeliveredPercentage = ((numShipments - numMissed)/300) * 100;
    const donutdata = {
        datasets: [
            {
                data: [packagesDeliveredPercentage, 100-packagesDeliveredPercentage],
                backgroundColor: ["green", "white"],
            }
        ]
    }

    //calculating avg vehicle utilisation
    let totalPercentage = 0;
    for(let i=0; i<percentages.length; i++) {
        totalPercentage = totalPercentage + percentages[i];
    }
    let avgPercentage = totalPercentage/percentages.length;
    console.log('avgPercentage')
    console.log(avgPercentage)
    const vehicleutilisationpercentage = {
        datasets: [
            {
                data: [avgPercentage,100 - avgPercentage],
                backgroundColor: ["green", "white"],
            }
        ]
    }

    return(
        <Body sidebar>
            <Container className="pt-lg-3">
                <div className="justify-content-center mx-auto">
                    <Row>
                        <Col>
                        <Button className="d-grid gap-2 d-md-block" onClick={handleBack}>Back</Button>
                            &nbsp;
                            <h4>Metrics</h4>
                            {/*<Button variant="secondary" onClick={handleVehicleUtilisation}>Vehicle</Button>*/}
                            {/*{percentages}*/}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <ListGroup horizontal className="d-flex">
                                <ListGroup.Item className="p-3">Total Hours on Road
                                    <h2>{metrics[0]}</h2>
                                </ListGroup.Item>
                                <ListGroup.Item className="p-3">Average stops per Driver
                                    <h2>{metrics[1]}</h2>
                                </ListGroup.Item>
                                <ListGroup.Item className="p-3">Total Distance Traveled (km)
                                    <h2>{metrics[2]}</h2>
                                </ListGroup.Item>
                            </ListGroup>
                            {/*</span>*/}
                            &nbsp;
                            <ListGroup horizontal>
                                <ListGroup.Item >Average Hours on Road
                                    <h2>{metrics[3]}</h2>
                                </ListGroup.Item>
                                <ListGroup.Item>Average Stops per Driver /Hour
                                    <h2>{metrics[4]}</h2>
                                </ListGroup.Item>
                                <ListGroup.Item >Average Distance Traveled (km)
                                    <h2>{metrics[5]}</h2>
                                </ListGroup.Item>
                            </ListGroup>
                            <br/>
                        </Col>
                        <Col xs={6} className="border">
                            <p className='fw-bold'>Shipments Details</p>
                            <div className="d-flex col-sm-8">
                                <div className="col-sm-7">
                                    <Doughnut className="p-4"
                                              data={donutdata}
                                    />
                                    <h6 className='text-center fw-bold'>{packagesDeliveredPercentage.toFixed(2)}%</h6>
                                    <p className="fs-6 fw-lighter text-center">Shipments delivered %</p>
                                </div>
                                <div className="col-sm-7">
                                    <Doughnut className="p-4"
                                              data={vehicleutilisationpercentage}
                                    />
                                    <h6 className='text-center fw-bold'>{avgPercentage.toFixed(2)}%</h6>
                                    <p className="fs-7 fw-lighter text-center">Average Vehicle Utilisation %</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <p className='fw-bold'>Shipment Details</p>
                        <Col xs={6}>
                            <Table striped bordered hover>
                                <tbody >
                                <tr className="same-col-widths">
                                    <td>Number Of Shipments:</td>
                                    <td>{numShipments}</td>
                                </tr>
                                <tr>
                                    <td>
                                        Number of Missed Shipments:</td>
                                    <td>{numMissed}</td>
                                </tr>
                                <tr className="same-col-widths">
                                    <td>Total Number of Vehicles Utilised:</td>
                                    <td>{numVehicles}</td>
                                </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    {/*<Row>*/}
                    {/*    <p className='fw-bold'>Resource Utilisation</p>*/}
                    {/*    /!*<Alert variant="danger">*!/*/}
                    {/*    /!*    <p>Volume constraint not present in dataset!</p>*!/*/}
                    {/*    /!*</Alert>*!/*/}
                    {/*    <Col xs={3}>*/}
                    {/*        <p className='fw-bold'> </p>*/}
                    {/*        <CDBContainer>*/}
                    {/*            <Doughnut className="p-4"  data={vehicleData}/>*/}
                    {/*            <h6 className='text-center fw-bold'>79.47%</h6>*/}
                    {/*            <p className="fs-6 fw-lighter text-center">Vehicle Utilisation %</p>*/}
                    {/*        </CDBContainer>*/}
                    {/*    </Col>*/}
                    {/*    <Col xs={3}>*/}
                    {/*        <p className='fw-bold'> </p>*/}
                    {/*        <CDBContainer>*/}
                    {/*            <Doughnut className="p-4"  data={vehicleData}/>*/}
                    {/*            <h6 className='text-center fw-bold'>79.47%</h6>*/}
                    {/*            <p className="fs-6 fw-lighter text-center">Driver Utilisation %</p>*/}
                    {/*        </CDBContainer>*/}
                    {/*    </Col>*/}
                    {/*    <Col xs={3}>*/}
                    {/*        <p className='fw-bold'> </p>*/}
                    {/*        <CDBContainer>*/}
                    {/*            <Doughnut className="p-4"  data={donutdata}/>*/}
                    {/*            <h6 className='text-center fw-bold'>95%</h6>*/}
                    {/*            <p className="fs-6 fw-lighter text-center">Weight Utilisation %</p>*/}
                    {/*        </CDBContainer>*/}
                    {/*    </Col>*/}
                    {/*    <Col xs={3}>*/}
                    {/*        <p className='fw-bold'> </p>*/}
                    {/*        <br/>*/}
                    {/*        <Alert variant="danger">*/}
                    {/*            <p>Volume constraint not present in dataset. Unable to show volume utilisation %</p>*/}
                    {/*        </Alert>*/}
                    {/*        /!*<CDBContainer>*!/*/}
                    {/*        /!*    <Doughnut className="p-4"  data={data}/>*!/*/}
                    {/*        /!*    <h6 className='text-center fw-bold'>95%</h6>*!/*/}
                    {/*        /!*    <p className="fs-6 fw-lighter text-center">Volume Utilisation %</p>*!/*/}
                    {/*        /!*</CDBContainer>*!/*/}
                    {/*        <br/>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                    <Row>
                        <Col xs={6}>
                            <div className='square border rounded p-3 '>
                                <h6 className="text-muted">Routes</h6>
                                <Button variant="secondary" onClick={handleRouteMapChange}>Route Map</Button>
                                {
                                    visibleRouteMap ? (
                                            <div className="content" dangerouslySetInnerHTML={{__html: mapForRoutes}}></div>
                                        ) :
                                        <br/>
                                }
                                {loading ?  <Spinner style={{marginBottom:27}} animation="border"/> : null }
                            </div>
                            <br/>
                        </Col>
                        <Col xs={6}>
                            <div className='square border rounded p-3 '>
                                <h6 className="text-muted">Shipments</h6>
                                <Button variant="secondary" onClick={handlePackageMapChange}>Shipments Map</Button>
                                {
                                    visiblePackageMap ? (
                                        <div className="content" dangerouslySetInnerHTML={{__html: mapForPackages}}></div>
                                    ) : <br/>
                                }
                                {clusterLoading ?  <Spinner style={{marginBottom:27}} animation="border"/> : null }
                            </div>
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <VehicleUtilisationChart percentages={percentages} vehicleNum={vehicleNum}/>
                            <br/>
                        </Col>
                        <Col>

                        </Col>
                    </Row>
                </div>
            </Container>
        </Body>
    )
}

export default ColdChainMetrics;