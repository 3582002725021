import {Button, Spinner, Table} from "react-bootstrap";
import React from "react";
import MmeCard from "../MiddleMileComponents/MmeCard";
import CallApi from "../../utils/CallApi";
import {constants} from "../../utils/constants";

interface AlternativeRoutesComparisonProps {
    packagesJson: any;
    mmePredictionResultSinglePackage: any;
}

interface PredictionResults {
    [key: string]: any;
}

export default function AlternativeRoutingStatisticsComparison({packagesJson, mmePredictionResultSinglePackage}: AlternativeRoutesComparisonProps) {
    const [loadingPredictionMap, setLoadingPredictionMap] = React.useState<boolean>(false);
    const [predictionMap, setPredictionMap] = React.useState<any>(null);

    const [exploreAlternativeRoutes, setExploreAlternativeRoutes] = React.useState<boolean>(false);

    const colors = ['magenta', 'blue', 'pink', 'purple', 'grey', 'lightblue', 'orange'];
    const enableAlternativeRoutes = async () => {
        setExploreAlternativeRoutes(!exploreAlternativeRoutes);

        // todo fill in this portion when the map is made
        setLoadingPredictionMap(true);

        console.log('mmePredictionResultSinglePackage', mmePredictionResultSinglePackage)
        const mappingResponse = await CallApi(constants.CONSOLE_MAPPING_API_DOMAIN, constants.CONSOLE_MAPPING_API_GENERATE_MME_PACKAGE_ROUTE, JSON.stringify({
            // have to use this and not mmePredictionResultSinglePackage because setState above takes too long
            list_of_package_datas: [mmePredictionResultSinglePackage],
            // add black to the front of the list of colors
            list_of_colors: ['black'].concat(colors),

            display_suggestions: true,
        }), true);
        if (mappingResponse.hasOwnProperty('error')) {
            console.log('Error in generateMmeMap: ', mappingResponse, mappingResponse.error);
            alert('Error in generateMmeMap');
            return;
        }
        setPredictionMap(mappingResponse);
        setLoadingPredictionMap(false);
    }


    return (
        <MmeCard
            title={'Alternative Route Suggestions Exploration'}
            description={''}
            body={
                <div style={{ maxHeight: '400px', overflow: 'auto' }}>
                    <Button onClick={enableAlternativeRoutes}>Explore Alternative Routes</Button>
                    {mmePredictionResultSinglePackage && exploreAlternativeRoutes &&
                        // there is only one package, but this is easier to do than to use .at(0) on the object
                        Object.entries(mmePredictionResultSinglePackage as PredictionResults).map(([packageId, mmePredictionValue]) => {
                                const mmePredictionAlternativeResults = mmePredictionValue.suggestions;
                                return <>
                                    <Table striped bordered hover>
                                        <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Origin Hub</th>
                                            <th>Destination Hub</th>
                                            <th>Delivery Status</th>
                                            <th>Volume Moved</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>{packageId}</td>
                                            <td>{mmePredictionValue.path[0].departure_hub}</td>
                                            <td>{mmePredictionValue.path.at(-1).arrival_hub}</td>
                                            {/* is this correct */}
                                            <td>{mmePredictionValue.is_at_destination
                                                ? 'At Destination'
                                                : mmePredictionValue.is_stuck
                                                    ? 'Stuck'
                                                    : 'Late'}
                                                {mmePredictionAlternativeResults.map((mmePredictionAlternative: any, index: number) => {
                                                    const chosenColor = colors[index];
                                                    return <> | <span style={{color: chosenColor}}>{mmePredictionAlternative.is_at_destination
                                                        ? 'At Destination'
                                                        : mmePredictionAlternative.is_stuck
                                                            ? 'Stuck'
                                                            : 'Late'}</span></>
                                                })}
                                            </td>
                                            <td>{mmePredictionValue.is_at_destination && mmePredictionValue.arrived_before_pdd
                                                // again, there should only be one package, so using [0] should be correct
                                                ? packagesJson[0].volume.toFixed(2)
                                                : 'Not on time'}
                                                {mmePredictionAlternativeResults.map((mmePredictionAlternative: any, index: number) => {
                                                    const chosenColor = colors[index];
                                                    return <> | <span style={{color: chosenColor}}>{mmePredictionAlternative.is_at_destination && mmePredictionAlternative.arrived_before_pdd
                                                        // again, there should only be one package, so using [0] should be correct
                                                        ? packagesJson[0].volume.toFixed(2)
                                                        : 'Not on time'}</span></>
                                                })}
                                            </td>
                                        </tr>
                                        </tbody>
                                        <thead>
                                        <tr>
                                            <th>Cost</th>
                                            <th>Duration (Hours)</th>
                                            <th>Number Of Connections Taken</th>
                                            <th>Total Carbon Emissions (Kg of CO2)</th>
                                            <th>Distance (km)</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>{mmePredictionValue.total_cost.toFixed(2)}{mmePredictionAlternativeResults.map((mmePredictionAlternative: any, index: number) => {
                                                const chosenColor = colors[index];
                                                return <> | <span style={{color: chosenColor}}>{mmePredictionAlternative.total_cost.toFixed(2)}</span></>
                                                })}
                                            </td>
                                            <td>{(mmePredictionValue.duration/3600).toFixed(2)}{mmePredictionAlternativeResults.map((mmePredictionAlternative: any, index: number) => {
                                                const chosenColor = colors[index];
                                                return <> | <span style={{color: chosenColor}}>{(mmePredictionAlternative.duration/3600).toFixed(2)}</span></>
                                                })}
                                            </td>
                                            <td>{mmePredictionValue.path.length}{mmePredictionAlternativeResults.map((mmePredictionAlternative: any, index: number) => {
                                                const chosenColor = colors[index];
                                                return <> | <span style={{color: chosenColor}}>{mmePredictionAlternative.path.length}</span></>
                                                })}
                                            </td>
                                            <td>{(mmePredictionValue.duration / 3600 * 6000 * 3.1).toFixed(2)}{mmePredictionAlternativeResults.map((mmePredictionAlternative: any, index: number) => {
                                                const chosenColor = colors[index];
                                                return <> | <span style={{color: chosenColor}}>{(mmePredictionAlternative.duration / 3600 * 6000 * 3.1).toFixed(2)}</span></>
                                                })}
                                            </td>
                                            <td>{(mmePredictionValue.path.reduce((acc: any, subpath: { distance: any; }) => acc + subpath.distance, 0) / 3600 * 6000 * 3.1).toFixed(2)}{mmePredictionAlternativeResults.map((mmePredictionAlternative: any, index: number) => {
                                                const chosenColor = colors[index];
                                                return <> | <span style={{color: chosenColor}}>{(mmePredictionAlternative.distance / 3600 * 6000 * 3.1).toFixed(2)}</span></>
                                            })}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                    {loadingPredictionMap
                                        ? <Spinner/>
                                        : (predictionMap && <div style={{ width: '50%'}} className="content"
                                                                 dangerouslySetInnerHTML={{__html: predictionMap}}></div>)
                                    }
                                </>
                            }
                        )}
                </div>
            }/>
    )
}