import MmeCard from "../../components/MiddleMileComponents/MmeCard";
import {Button, Container, Form, FormSelect} from "react-bootstrap";
import Body from '../../components/Body';
import React, {ChangeEvent, useState} from "react";
import {useNavigate} from "react-router-dom";
import graphSpire100RawData
    from "../../contexts/DemoContents/LatestFiles/graphs/graph_with_meta_spire_100_shipment_profiles_pre_catastrophe.json";
import settingsDictionary from "../../contexts/DemoContents/LatestFiles/settings/base_settings.json";
import CallApi from "../../utils/CallApi";
import {constants} from "../../utils/constants";

export default function MmePrediction() {

    // 0 is generate packages, 1 is train model, 3 is generate packages
    const [currentForm, setCurrentForm] = useState(0);
    const [inputOptions, setInputOptions] = useState([-1, -1, -1]);

    const [networkJson, setNetworkJson] = useState('');
    const [graphJson, setGraphJson] = useState<any>('');
    const [trainedModelID, setTrainedModelID] = useState<number>(-1);
    const [trainedModelJson, setTrainedModelJson] = useState('');
    const [packagesDataJson, setPackagesDataJson] = useState('');

    // Helper function to convert the uploaded file into a JSON for sending in API body
    const fileToJson = (stateVariableToAssign: any, file: File | string) => {
        const fileReader = new FileReader();
        // @ts-ignore
        fileReader.readAsText(file);
        fileReader.onload = e => {
            const target = e.target;
            const result = target?.result;
            console.log(result);
            if (typeof result === "string") {
                stateVariableToAssign(JSON.parse(result));
            }
        }
    }

    // todo Change the system to call the API as soon as they click next
    const onNetworkJsonUpload = (event: ChangeEvent<HTMLInputElement>) => {
        if (event != null) {
            // @ts-ignore
            fileToJson(setNetworkJson, event.target.files[0]);
        } else {
            return alert('Error uploading network file')
        }
    }
    const onGraphJsonUpload = (event: ChangeEvent<HTMLInputElement>) => {
        if (event != null) {
            // @ts-ignore
            fileToJson(setGraphJson, event.target.files[0]);
        } else {
            return alert('Error uploading network file')
        }
    }
    const onTrainedModelJsonUpload = (event: ChangeEvent<HTMLInputElement>) => {
        if (event != null) {
            // @ts-ignore
            fileToJson(setTrainedModelJson, event.target.files[0]);
        } else {
            return alert('Error uploading trainedModel file')
        }
    }
    const onPackagesDataJsonUpload = (event: ChangeEvent<HTMLInputElement>) => {
        if (event != null) {
            // @ts-ignore
            fileToJson(setPackagesDataJson, event.target.files[0]);
        } else {
            return alert('Error uploading packageData file')
        }
    }

    const onSubmitBuildNetworkPage = async (event: React.ChangeEvent<HTMLFormElement>) => {
        event.preventDefault(); // can consider removing
        const formData = new FormData(event.target);
        const formDataObj = Object.fromEntries(formData.entries())
        console.log(formDataObj)
        if (inputOptions[0] === 0) {
            // todo engineering needs to replace this with the actual API call
            alert("Not implemented yet");
            console.log("Not implemented yet");
            const buildNetworkResponse = await fetch('https://console-mapping-api.dev.quincus.com/build-network', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    network: networkJson,
                })
            })
            if (buildNetworkResponse.ok) {
                const results = await buildNetworkResponse.json();
                setGraphJson(results);
            } else {
                alert(buildNetworkResponse.status.toString() + ", error with build-network")
                return;
            }
        } else if (inputOptions[0] === 1) {
            // use cached 100 node graph
            setGraphJson(graphSpire100RawData);
        }
        setCurrentForm(currentForm + 1);
    };

    const onSubmitTrainModelPage = async (event: React.ChangeEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const formDataObj = Object.fromEntries(formData.entries())
        console.log(formDataObj)
        if (inputOptions[1] === 0) {
            // no trained model so call training API
            settingsDictionary['OPTIMIZATION_PARAMETER'] = formDataObj.optimizedFor.toString();
            const trainAgentResponse = await CallApi(constants.MME_TRAINING_API_DOMAIN, constants.MME_TRAINING_API_TRAIN_MODEL, JSON.stringify({
                graph: graphJson,
                settings: settingsDictionary,
            }), false);
            if (trainAgentResponse.hasOwnProperty('unique_id')) {
                setTrainedModelID(trainAgentResponse.unique_id);
                alert("Training ID: " + trainAgentResponse.unique_id.toString() + " has been created." +
                    " Please store this ID for use later.");
            } else if (trainAgentResponse.hasOwnProperty('error')) {
                console.log('Error in /train-model: ', trainAgentResponse, trainAgentResponse.error);
                alert('Error: training error');
                return;
            }
        } else if (inputOptions[1] === 1) {
            // check if trained agent has been built
            if (trainedModelID === -1) {
                alert('Error: Please input a trained model ID first');
                return;
            }
            const trainingResponse = await CallApi(constants.MME_TRAINING_API_DOMAIN, constants.MME_TRAINING_API_GET_TRAINED_MODEL, JSON.stringify({
                unique_id: trainedModelID,
            }), false);
            if (trainingResponse.hasOwnProperty('agent')) {
                setTrainedModelJson(trainingResponse.agent);
            } else if (trainingResponse.hasOwnProperty('error')) {
                if (trainingResponse.error === 'IncompleteTrainingError') {
                    alert('Error: Model is still training');
                    return;
                } else {
                    console.log('Error in /get-trained-model: ', trainingResponse, trainingResponse.error);
                    alert('Error: training never began');
                    return;
                }
            }
        }
        setCurrentForm(currentForm + 1);
    }

    const navigate = useNavigate();

    const onSubmitGeneratePackagePage = async (event: React.ChangeEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const formDataObj = Object.fromEntries(formData.entries())
        console.log(formDataObj)
        let packagesTempForSpeed = [];
        if (inputOptions[2] === 0) {
            const generatePackageResponse = await CallApi(constants.MME_PREDICTION_API_DOMAIN, constants.MME_PREDICTION_API_GENERATE_PACKAGE, JSON.stringify({
                graph: graphJson,
                number_of_packages: formDataObj.numberOfPackages,
            }), false);
            if (generatePackageResponse.hasOwnProperty('packages')) {
                setPackagesDataJson(generatePackageResponse.packages);
                packagesTempForSpeed = generatePackageResponse.packages;
            } else if (generatePackageResponse.hasOwnProperty('error')) {
                const errorMessage = generatePackageResponse.status.toString() + ", error with prediction API to generate packages";
                console.log(errorMessage);
                alert(errorMessage);
                return;
            }
        }
        // call prediction API here to retrieve prediction ID
        let predictData = JSON.stringify({
            graph: graphJson,
            agent: trainedModelJson,
            packages: packagesTempForSpeed,
            settings: settingsDictionary,
            retrieve_all_later: true,
        });
        const predictResponse = await CallApi(constants.MME_PREDICTION_API_DOMAIN,
            constants.MME_PREDICTION_API_PREDICT, predictData, false);
        if (predictResponse.hasOwnProperty('error')) {
            console.log('Error in generatePredictions: ', predictResponse, predictResponse.error);
            alert('Error in generatePredictions');
            return;
        } else if (predictResponse.hasOwnProperty('unique_id')) {
            const predictionResponseID = predictResponse.unique_id;
            alert('Here is your predictionID for access later: ' + predictionResponseID);
            console.log('Here is your predictionID for access later: ' + predictionResponseID + '. You can now ' +
                'navigate away from this page and come back later to retrieve your predictions.');
        } else {
            console.log(predictResponse)
            const errorMessage = predictResponse.error +
                ', error with prediction API';
            console.log(errorMessage);
            alert(errorMessage);
            return;
        }
        navigate('/mme-dashboard');

    }

    return (
        <Body sidebar>
            <Container>
                {currentForm === 0
                    ?
                    <Form onSubmit={onSubmitBuildNetworkPage} key={0}>
                        <MmeCard
                            title={'Step 1: Build Your Network'}
                            description={'How would you like to create a graph?'}
                            body={
                                <>
                                    <FormSelect name={'existingGraphNetwork'} defaultValue={-1} onChange={event =>
                                        setInputOptions({ ...inputOptions, 0: parseInt(event.target.value) })
                                    }>
                                        <option value={-1} disabled>Select One</option>
                                        <option value={0}>Build from connection schedule</option>
                                        <option value={1}>Select cached 100 node graph</option>
                                        <option value={2}>Upload my own</option>
                                    </FormSelect>
                                    {inputOptions[0] === -1
                                        ? <></>
                                        : inputOptions[0] === 0
                                            ? <Form.Group controlId="networkFile" className="mb-3">
                                                <Form.Label>Instruction: network file includes xyz columns. Upload Network File as a JSON</Form.Label>
                                                <Form.Control name={'networkFile'} type="file" accept=".json" onChange={onNetworkJsonUpload} />
                                              </Form.Group>
                                            : inputOptions[0] === 2
                                                ?   <Form.Group controlId="graphenvFile" className="mb-3">
                                                         <Form.Label>Upload graph JSON file</Form.Label>
                                                         <Form.Control name={'graphenvFile'} type="file" accept=".json" onChange={onGraphJsonUpload}/>
                                                     </Form.Group>
                                                :   <Form.Group controlId="getCachedGraph" className="mb-3">
                                                        <Form.Label>Will use cached 100 Node Graph</Form.Label>
                                                    </Form.Group>
                                    }
                                </>
                            }
                        />
                        <Button variant="primary" type="submit">Submit</Button>
                    </Form>
                    : currentForm === 1
                        ? <Form onSubmit={onSubmitTrainModelPage} key={1}>
                            <MmeCard
                                title={'Step 2: Train Your Model'}
                                description={'Do you have a trained model?'}
                                body={
                                    <>
                                        <FormSelect name={'trainedModel'} defaultValue={-1} onChange={event =>
                                            setInputOptions({ ...inputOptions, 1: parseInt(event.target.value) })
                                        }>
                                            <option value={-1} disabled>Select One</option>
                                            <option value={0}>No, train new model</option>
                                            <option value={1}>Check if model has been built</option>
                                            <option value={2}>Yes, upload my own</option>
                                        </FormSelect>
                                        {inputOptions[1] === -1
                                            ? <></>
                                            : inputOptions[1] === 0
                                                ? <Form.Group controlId="modelVersion" className="mb-3">
                                                    <Form.Label>Select Model Version</Form.Label>
                                                    <FormSelect name={'modelVersion'}>
                                                        <option value='5.3'>Model 5.3</option>
                                                    </FormSelect>
                                                    <Form.Label>Optimize for</Form.Label>
                                                    <FormSelect name={'optimizedFor'}>
                                                        <option value='cost'>Cost</option>
                                                        <option value='time'>Time</option>
                                                        <option value='distance'>Distance</option>
                                                    </FormSelect>
                                                  </Form.Group>
                                                : inputOptions[1] === 2
                                                    ? <Form.Group controlId="trainedModelFile" className="mb-3">
                                                        <Form.Label>Upload trained JSON file</Form.Label>
                                                        <Form.Control name={'trainedModelFile'} type="file" accept=".json" onChange={onTrainedModelJsonUpload}/>
                                                      </Form.Group>
                                                    : <Form.Group controlId="trainedAgentID" className="mb-3">
                                                        <Form.Label>Trained Agent ID</Form.Label>
                                                        <input type="text" className="form-control"
                                                               placeholder="Trained Agent ID"
                                                               onChange={e=>setTrainedModelID(parseInt(e.target.value))}/>
                                                      </Form.Group>
                                        }
                                    </>
                                }
                            />
                            <Button variant="primary" type="submit">Submit</Button>
                        </Form>
                        : <Form onSubmit={onSubmitGeneratePackagePage} key={2}>
                            <MmeCard
                                title={'Step 3: Optimize Routes'}
                                description={'Do you have shipment data?'}
                                body={
                                    <>
                                        <FormSelect name={'packageData'} defaultValue={-1} onChange={event =>
                                            setInputOptions({ ...inputOptions, 2: parseInt(event.target.value) })
                                        }>
                                            <option value={-1} disabled>Select One</option>
                                            <option value={0}>No, simulate shipments</option>
                                            <option value={2}>Yes, upload my own</option>
                                        </FormSelect>
                                        {inputOptions[2] === -1 ?
                                            <></>
                                            :
                                            <>
                                                {inputOptions[2] === 0?
                                                    <Form.Group controlId="numberOfPackages" className="mb-3">
                                                        <Form.Label>Number of Shipments</Form.Label>
                                                        <Form.Control name={'numberOfPackages'} type="integer" placeholder="Integer between 1 and n" />
                                                    </Form.Group>
                                                    :
                                                    <Form.Group controlId="dataFile" className="mb-3">
                                                        <Form.Label>Upload data JSON file</Form.Label>
                                                        <Form.Control name={'packageDataFile'} type="file" accept=".json" onChange={onPackagesDataJsonUpload}/>
                                                    </Form.Group>}
                                                <Form.Check
                                                    name={'saveRoutes'}
                                                    type='checkbox'
                                                    id={`default-checkbox`}
                                                    label={`Save routes`}
                                                />
                                            </>
                                        }
                                    </>
                                }
                            />
                            <Button variant="primary" type="submit">Submit</Button>
                        </Form>
                }
            </Container>
        </Body>
    )
}