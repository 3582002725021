import {Table} from "react-bootstrap";
import React from "react";
import MmeCard from "../MiddleMileComponents/MmeCard";
import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement, Title,
    Tooltip
} from "chart.js";
import {Doughnut, Scatter, Bar} from "react-chartjs-2";

interface PackageInfoProps {
    packageJson: any;
}

export default function PackagesBreakdown({packageJson}: PackageInfoProps) {
    // seems to be needed occasionally
    const innerPackageJson = packageJson.hasOwnProperty('packageJson')
        ? packageJson.packageJson
        : packageJson;

    ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, Title, LineElement, Tooltip, Legend);
    const leadTimes: { x: any; y: number; }[] = [];
    // map is faster as it is O(1) lookup
    const volumes = new Map<any, number>();
    const priorityMap: { [key: string]: number } = { express: 0, normal: 0, long: 0,};
    if (innerPackageJson) {
        let packageIndex = 0;
        Object.values(innerPackageJson).forEach((packageValue: any) => {
            packageIndex++;
            leadTimes.push({x: packageIndex, y: (packageValue.pdd - packageValue.order_date)/3600});
            const currentValue = volumes.get(packageValue.volume);
            if (currentValue !== undefined) {
                volumes.set(packageValue.volume, currentValue+1);
            } else {
                volumes.set(packageValue.volume, 1);
            }
            priorityMap[packageValue.priority]++;
        });
    }
    const volumesArray = Array.from(volumes.entries()).map(([x, y]) => ({ x, y }));
    // sort this volumeArray such that the keys are in increasing order
    volumesArray.sort((a, b) => a.x - b.x);

    const optionsBarChartVolume = {
        responsive: true,
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Volume',
                },
            },
            y: {
                title: {
                    display: true,
                    text: 'Number of Shipments',
                },
            },
        },
        plugins: {
            legend: {
                position: 'top' as const,
            },
        },
    };
    const dataBarChartVolume = {
        labels: Array.from(volumes.keys()).sort((a, b) => a - b),
        datasets: [
            {
                label: 'Number of Shipments',
                data: volumesArray,
                backgroundColor: 'black',
            },
        ],
    };

    const optionsScatterPlotLeadTimes = {
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Shipment ID',
                },
            },
            y: {
                title: {
                    display: true,
                    text: 'Lead Time (Hours)',
                },
            },
        },
    };
    const dataScatterPlotLeadTimes = {
        datasets: [
            {
                label: 'Lead Time',
                data: leadTimes,
                pointBackgroundColor: 'black',
            },
        ],
    };

    return (
        <MmeCard
            title={'Shipments Breakdown'}
            description={''}
            body={
                <div>
                    <Table bordered hover>
                        <thead>
                        <tr>
                            <th>Priorities</th>
                            <th>Volumes</th>
                            <th>Lead Times(Time Allotted for Delivery)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td style={{width: '33%'}}>
                                <Doughnut
                                    data={{
                                        labels: [Object.keys(priorityMap)[0], Object.keys(priorityMap)[1], Object.keys(priorityMap)[2]],
                                        datasets: [
                                            {
                                                label: "Priority",
                                                data: [priorityMap[Object.keys(priorityMap)[0]], priorityMap[Object.keys(priorityMap)[1]], priorityMap[Object.keys(priorityMap)[2]]],
                                                backgroundColor: ["green", "blue", "yellow"],
                                                borderColor: ["green", "blue", "yellow"],
                                                borderWidth: 1,
                                            },
                                        ],
                                    }}
                                    options={{
                                        maintainAspectRatio: true, // Allow the chart to maintain aspect ratio
                                        responsive: false, // Enable responsiveness
                                    }}
                                    />
                            </td>
                            <td><Bar options={optionsBarChartVolume} data={dataBarChartVolume} /></td>
                            <td><Scatter options={optionsScatterPlotLeadTimes} data={dataScatterPlotLeadTimes} /></td>
                        </tr>
                        </tbody>
                    </Table>
                </div>
            }/>
    )
}