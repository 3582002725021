import Body from '../../components/Body';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, {useEffect, useState} from 'react';
import { Col, Row, ListGroup , Button, Container} from 'react-bootstrap';
import { ResponseContext} from "../../contexts/LastMileContexts/ResponseContext";
import { ResponseContextType} from "../../contexts/LastMileContexts/ResponseContextType";
import {DataContext} from "../../contexts/LastMileContexts/DataContext";
import {DataContextType} from "../../contexts/LastMileContexts/DataContextType";
import {MetricsContext} from "../../contexts/LastMileContexts/MetricsContext";
import {MetricsContextType} from "../../contexts/LastMileContexts/MetricsContextType";
// @ts-ignore
import { CSVLink } from "react-csv";
import {Spinner} from "react-bootstrap";
import {useNavigate} from 'react-router-dom';
import CallApi from "../../utils/CallApi";
import {constants} from "../../utils/constants";
import GetMetrics from "../../utils/GetMetrics";
import MissedPackagesTable from "../../components/LastMileComponents/MissedPackagesTable";
import {downloadFile} from "../../utils/downloadFIle";

const Output: React.FC  = () => {

    const {response} = React.useContext(ResponseContext) as ResponseContextType;
    console.log('output response');
    console.log(response);
    const {data} = React.useContext(DataContext) as DataContextType;
    const [mapForRoutes, setMapForRoutes] = useState('');
    const [mapForPackages, setMapForPackages] = useState('');
    const [loading, setLoading] = useState(false);
    const [clusterLoading, setClusterLoading] = useState(false);
    const {updateTotalHrsFirst, updateAvgStopsPerDriverFirst, updateTotalDistFirst,
        updateAvgHoursOnRoadFirst, updateAvgStopsPerDriverPerHourFirst, updateAvgDistFirst} = React.useContext(MetricsContext) as MetricsContextType;

    const[visibleRouteMap, setVisibleRouteMap] = useState(false);
    const[visiblePackageMap, setVisiblePackageMap] = React.useState(false);
    const handleRouteMapChange = () => {
        setLoading(true);
        setVisibleRouteMap(!visibleRouteMap);
         routeMap();
    };

    const handlePackageMapChange = () => {
        setClusterLoading(true);
        setVisiblePackageMap(!visiblePackageMap);
        clusterMap();
    }

    const exportToJson = (e: { preventDefault: () => void; }) => {
        e.preventDefault()
        downloadFile({
            data: JSON.stringify(response),
            fileName: 'response.json',
            fileType: 'text/json',
        })
    }

    //getting metrics
    const[metrics, setMetrics] = useState<string[]>([]) ;

    useEffect(() => {
        let ignore = false;
        if (!ignore)  {
            GetMetrics(data, response).then(
                res => {
                    // const metrics = res;
                    setMetrics(res);
                    // console.log('m')
                    // console.log(metrics);
                })
                .catch(err => console.log(err));

        }
        return () => { ignore = true; }
    }, [data, response]);

    updateTotalHrsFirst(Number(metrics[0]));
    updateAvgStopsPerDriverFirst(Number(metrics[1]));
    updateTotalDistFirst(Number(metrics[2]));
    updateAvgHoursOnRoadFirst(Number(metrics[3]));
    updateAvgStopsPerDriverPerHourFirst(Number(metrics[4]));
    updateAvgDistFirst(Number(metrics[5]));

    // manipulating LE response
    const json = JSON.stringify(response);
    const parsed = JSON.parse(json) as typeof response;
    const route = Object.entries(parsed)[1][1];
    console.log('route');
    console.log(route);
    //
    //getMetrics
    // for (let i = 0; i < route.length; i++) {
    //     driverIdsArr.push(route[i].driverId);
    // }
    // console.log('driver')
    // console.log(driverIdsArr)
    //
    // const vehicleIdsArr = new Array<string>();
    // for (let i = 0; i < route.length; i++) {
    //     vehicleIdsArr.push(route[i].vehicleId);
    // }
    // console.log('driver')
    // console.log(driverIdsArr)

    //to calc total hours, have to take into account those with the same start time >> should filter before mapping

    //missedIDS
    //missedIDs is array of missed IDs
    // let missedIDsArr;
    // let missedIDs: any;
    // let reasonMissed;
    // let getMissedIds;
    // try {
        let missedIDsArr = Object.entries(parsed)[2][1];
        let missedIDs = Object.entries(missedIDsArr)[0][1];
    //     reasonMissed = Object.entries(missedIDsArr)[0][0];
    //     getMissedIds = missedIDs.map((item: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined, i: React.Key | null | undefined) => {
    //         return (
    //             <li key={i}>{item}</li>
    //         )
    //     });
    // } catch (err: unknown) {
    //     if (err instanceof Error) {
    //         console.log(err.message);
    //     }
    // }

    //downloading metrics
    let distanceByVehicle = new Array<number>();
    let numStopsByVehicle = new Array<number>();
    let firstEtaByVehicle = new Array<string>();
    let lastEtaByVehicle = new Array<string>();
    let tripDurationByVehicle = new Array<number>();
    let totalWeightByVehicle = new Array<number>();
    let avgStopsByVehicle = new Array<number>();
    let dist = 0;
    let weight = 0;
    let duration = 0;
    for (let i = 0; i < route.length; i++) {
        for (let j = 0; j < route[i].ships.length; j++) {
            dist = dist + (+route[i].ships[j].distance);
            weight = weight + route[i].ships[j].weight;
            duration = (route[i].ships[j].eta - route[i].ships[j].start_time) / 3600;
        }
        distanceByVehicle.push(dist);
        numStopsByVehicle.push(route[i].ships.length);
        firstEtaByVehicle.push(route[i].ships[0].etaReadable);
        lastEtaByVehicle.push(route[i].ships[route[i].ships.length - 1].etaReadable);
        totalWeightByVehicle.push(weight);
        tripDurationByVehicle.push(duration);
        duration = 0;
        dist = 0;
        weight = 0;
    }

    for (let i = 0; i < route.length; i++) {
        avgStopsByVehicle.push(tripDurationByVehicle[i] / numStopsByVehicle[i]);
    }

    //metrics content
    let csvMetricsContent = " Vehicle ID, Total Distance (km), No. of Stops, Driver ID, First ETA, Last ETA, Trip Duration (hr), Total Weight Carried, Avg Stops per Hr, Max Capacity Used (%) \n";
    for (let i = 0; i < route.length; i++) {
        csvMetricsContent += JSON.stringify(route[i].vehicleId) + ',';
        csvMetricsContent += JSON.stringify(distanceByVehicle[i]) + ',';
        csvMetricsContent += JSON.stringify(numStopsByVehicle[i]) + ',';
        csvMetricsContent += JSON.stringify(route[i].driverId) + ',';
        csvMetricsContent += JSON.stringify(firstEtaByVehicle[i]) + ',';
        csvMetricsContent += JSON.stringify(lastEtaByVehicle[i]) + ',';
        csvMetricsContent += JSON.stringify(tripDurationByVehicle[i]) + ',';
        csvMetricsContent += JSON.stringify(totalWeightByVehicle[i]) + ',';
        csvMetricsContent += JSON.stringify(avgStopsByVehicle[i]) + ',';
        csvMetricsContent += "100 \n";
    }

    // let totalDistance = 0;
    // let totalHours = 0;
    // let totalStopsMade = 0;
    // for (let i = 0; i < route.length; i++) {
    //     totalDistance += distanceByVehicle[i];
    //     totalHours += tripDurationByVehicle[i];
    //     totalStopsMade += numStopsByVehicle[i];
    // }
    // let avgHrs = totalHours / driverIdsArr.length;
    // let avgStops = totalStopsMade / driverIdsArr.length;
    // let avgStopsPerHr = totalStopsMade / totalHours;
    // let avgDist = totalDistance / driverIdsArr.length;
    // updateTotalHrsFirst(totalHours);
    // updateAvgStopsPerDriverFirst(avgStops);
    // updateTotalDistFirst(totalDistance);
    // updateAvgStopsPerDriverPerHourFirst(avgStopsPerHr);
    // updateAvgDistFirst(avgDist);
    // updateAvgHoursOnRoadFirst(avgHrs);

    // downloading missed packages
    //get missedpackages ID >> getMissedIds
    // // filter response based on ID then retrieve relevant data
    // let csvContent = '';
    // const [filteredData, setFilteredData] = useState([]);
    const jsonData = JSON.stringify(data);
    const parsedData = JSON.parse(jsonData) as typeof data;
    //shipArr is an array of objects
    const shipArr = Object.entries(parsedData)[2][1];
    // console.log('shipArr');
    // console.log(shipArr);
    // console.log(typeof shipArr);
    let numShipments = shipArr.length;
    //
    // let finalResults = new Array<Object>();
    // let result: Object = [];

    // function filterMissedPackages() {
    //     for (let i = 0; i < missedIDs.length; i++) {
    //         for (let j = 0; j < shipArr.length; j++) {
    //             let txt: string = shipArr[j].id;
    //             if (txt.toString().charAt(0) === ("'")) {
    //                 txt = txt.substring(1);
    //             }
    //             //txt.toString().replace("'","");
    //             if (txt === missedIDs[i]) {
    //                 result = shipArr[i];
    //                 finalResults.push(result);
    //             }
    //             //console.log('filter');
    //         }
    //     }
    //     // @ts-ignore
    //     setFilteredData(finalResults);
    //     return filteredData;
    // }
    let missedPackages = 0;
    if(missedIDsArr !== false) {
        // @ts-ignore
        missedPackages = missedIDs.length;
    }
    let missedPackagesPercentage = (missedPackages/numShipments) * 100;
    //
    // console.log('final results')
    // console.log(filteredData);

    // for (let i = 0; i < filteredData.length; i++) {
    //     let string = filteredData[i];
    //     csvContent += JSON.stringify(string) + "\n";
    // }

    //route map
    const routeMap = () => {
        setLoading(true);
        CallApi(constants.CONSOLE_MAPPING_API_DOMAIN, constants.CONSOLE_MAPPING_API_GENERATE_ROUTE_MAP, JSON.stringify({
            data: data,
            response: response
        }), true)
            .then((data) => {
                console.log('string');
                console.log(data);
                setMapForRoutes(data);
            })
        setLoading(false);
    }

    const clusterMap = () => {
        CallApi(constants.CONSOLE_MAPPING_API_DOMAIN, constants.CONSOLE_MAPPING_API_GENERATE_CLUSTER_MAP, JSON.stringify({
            data: data,
            response: response
        }), true)
            .then((data) => {
                console.log('string');
                console.log(data);
                setMapForPackages(data);
            })
        setClusterLoading(false);
    }

    let navigate = useNavigate();
    const handleCompare = () => {
        navigate("/second-dataset");
    }

    return (
        <Body sidebar>
            <Container className="pt-lg-3">
        <div className="justify-content-center mx-auto">
            <Row className="justify-content-center">
                <Col xs={10}>
                    {/*<Tab.Container id="metrics" defaultActiveKey="output">*/}
                    {/*    <Nav variant="tabs" defaultActiveKey="/main">*/}
                    {/*        <Nav.Item>*/}
                    {/*            <Nav.Link eventKey="input">Input</Nav.Link>*/}
                    {/*        </Nav.Item>*/}
                    {/*        <Nav.Item>*/}
                    {/*            <Nav.Link eventKey="output">Output</Nav.Link>*/}
                    {/*        </Nav.Item>*/}
                    {/*    </Nav>*/}
                        {/*<Tab.Content>*/}
                        {/*    <Tab.Pane eventKey="input">*/}
                        {/*        <br/>*/}
                        {/*        <h6>JSON Input File</h6>*/}
                        {/*        <Card border="secondary p-2" className="overflow-scroll text-wrap " style={{height:"500px", width:"500px"}} >*/}
                        {/*        <Card.Text>*/}
                        {/*            {JSON.stringify(data)}*/}
                        {/*        </Card.Text>*/}
                        {/*    </Card>*/}
                        {/*        <br/>*/}
                        {/*    </Tab.Pane>*/}
                        {/*    <Tab.Pane eventKey="output">*/}
                        {/*        <br/>*/}
                    <div >
                        <Button onClick={() => { handleCompare() }} variant="dark">
                            Compare
                        </Button>
                        <br/>
                        <div className='sqaure border rounded p-3'>
                        <h6>LE Response File</h6>
                        <Button type='button' onClick={exportToJson} variant="dark">
                            Download as JSON
                        </Button>
                        &nbsp;
                        <CSVLink data={JSON.stringify(response)} filename={"response.csv"}>
                            Download as CSV
                        </CSVLink>
                        </div>
                    </div>
                    <br/>
                    <h6 className="text-muted">Metrics &nbsp;
                        <CSVLink data={csvMetricsContent} filename={"metrics.csv"}
                                 className="btn btn-primary">
                            Download
                        </CSVLink>
                    </h6>
                    {/*<span>*/}
                    <ListGroup horizontal className="d-flex">
                        <ListGroup.Item className="p-3">Total Hours on Road
                            <h2>{metrics[0]}</h2>
                        </ListGroup.Item>
                        <ListGroup.Item className="p-3">Average stops per Driver
                            <h2>{metrics[1]}</h2>
                        </ListGroup.Item>
                        <ListGroup.Item className="p-3">Total Distance Traveled (km)
                            <h2>{metrics[2]}</h2>
                        </ListGroup.Item>
                    </ListGroup>
                        {/*</span>*/}
                    &nbsp;
                    <ListGroup horizontal>
                        <ListGroup.Item >Average Hours on Road
                            <h2>{metrics[3]}</h2>
                        </ListGroup.Item>
                        <ListGroup.Item>Average Stops per Driver /Hour
                            <h2>{metrics[4]}</h2>
                        </ListGroup.Item>
                        <ListGroup.Item >Average Distance Traveled (km)
                            <h2>{metrics[5]}</h2>
                        </ListGroup.Item>
                    </ListGroup>
                    <br/>
                </Col>
                <Col xs={5}>
                    <div className='sqaure border rounded p-3 '>
                    <h6 className="text-muted">Routes</h6>
                    <Button variant="secondary" onClick={handleRouteMapChange}>Route Map</Button>
                        {
                            visibleRouteMap ? (
                        <div className="content" dangerouslySetInnerHTML={{__html: mapForRoutes}}></div>
                            ) :
                                <br/>
                        }
                    {loading ?  <Spinner style={{marginBottom:27}} animation="border"/> : null }
                    </div>
                    <br/>
                </Col>
                <Col xs={5}>
                    <div className='square border rounded p-3 '>
                    <h6 className="text-muted">Shipments</h6>
                    <Button variant="secondary" onClick={handlePackageMapChange}>Shipments Map</Button>
                    {
                        visiblePackageMap ? (
                            <div className="content" dangerouslySetInnerHTML={{__html: mapForPackages}}></div>
                        ) : <br/>
                    }
                    {clusterLoading ?  <Spinner style={{marginBottom:27}} animation="border"/> : null }
                    </div>
                    <br />
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col xs={5}>
                    <div className='sqaure border rounded p-3 '>
                        <h6>Shipment Details</h6>
                        <ListGroup className="w-40">
                            <ListGroup.Item className="d-flex justify-content-between align-items-start">Number Of Shipments: {numShipments}
                            </ListGroup.Item>
                            <ListGroup.Item className="d-flex justify-content-between align-items-start">Number of Missed Shipment: {missedPackages}
                                <div className="align-items-end">
                                </div>
                            </ListGroup.Item>
                            <ListGroup.Item className="d-flex justify-content-between align-items-start">% Missed Shipments: {missedPackagesPercentage}%
                            </ListGroup.Item>
                        </ListGroup>
                    </div>
                </Col>
                <Col xs={5}>
                    {/*<div className='square border rounded p-3 '>*/}
                        {/*<h6>Vehicle Details</h6>*/}
                        {/*<ListGroup className="w-40">*/}
                        {/*    <ListGroup.Item className="d-flex justify-content-between align-items-start">Avg Capacity Used <br/> For All Vehicles(%):*/}
                        {/*        <div className="fw-bold">*/}
                        {/*            82.0*/}
                        {/*        </div>*/}
                        {/*    </ListGroup.Item>*/}
                        {/*</ListGroup>*/}
                        <br/>
                        {/*<p>Vehicle Utilisation Rate</p>*/}
                        {/*<p>insert bar chart here//</p>*/}
                    {/*</div>*/}
                    <br/>
                </Col>
                <Col xs={10}>
                    <MissedPackagesTable/>
                </Col>
            </Row>
        </div>
            </Container>
        </Body>
    )
}

export default Output;