import {Card} from 'react-bootstrap'

interface MmePredictionCardProps {
    cardStyle?: any;
    title: string;
    description: string;
    body: JSX.Element;
}

/*
this card is used to display information and to ensure uniformity in the UI
 */
export default function MmeCard({cardStyle, title, description, body}:MmePredictionCardProps) {
    return (
        <Card style={cardStyle}>
            <Card.Body>
                <Card.Title>{title}</Card.Title>
                <Card.Text>
                    {description}
                </Card.Text>
                {body}
            </Card.Body>
        </Card>
);
}