export default async function GetMetrics(data:Object, response:Object) {
    // const {response} = React.useContext(ResponseContext) as ResponseContextType;
    // const {data} = React.useContext(DataContext) as DataContextType;

    const json = JSON.stringify(response);
    const parsed = JSON.parse(json);
    const route = parsed.routes;
    // as typeof response;
    // const route = Object.entries(parsed)[1][1];
    // const route = Object.entries(response)[1][1];

    //array with vehicle numbers
    const driverIdsArr = new Array<string>();
    // @ts-ignore
    for (let i = 0; i < route.length; i++) {
        driverIdsArr.push(route[i].driverId);
    }
    console.log('driver')
    console.log(driverIdsArr)

    // const vehicleIdsArr = new Array<string>();
    // for (let i = 0; i < route.length; i++) {
    //     vehicleIdsArr.push(route[i].vehicleId);
    // }

    let distanceByVehicle = new Array<number>();
    let numStopsByVehicle = new Array<number>();
    let firstEtaByVehicle = new Array<string>();
    let lastEtaByVehicle = new Array<string>();
    let tripDurationByVehicle = new Array<number>();
    let totalWeightByVehicle = new Array<number>();
    let avgStopsByVehicle = new Array<number>();
    let dist = 0;
    let weight = 0;
    let duration = 0;
    for (let i = 0; i < route.length; i++) {
        for (let j = 0; j < route[i].ships.length; j++) {
            dist = dist + (+route[i].ships[j].distance);
            weight = weight + route[i].ships[j].weight;
            // duration = (route[i].ships[j].eta - route[i].ships[j].start_time) / 3600;
        }
        //calulate duration: first arr etdReadable - routes[i].etaToDepot
        let endDateString = route[i].ships[0].etdReadable;
        let startDateString;
        if(route[i].etaToDepot) {
            startDateString = route[i].etaToDepot;
        } else {
            startDateString = route[i].ships[route[i].ships.length - 1].etaReadable;
        }
        if(endDateString && startDateString) {
            const startTimeString = startDateString.split(" ")[2];
            const [startHours, startMinutes, startSeconds] = startTimeString.split(":"); // Parse hours, minutes, seconds
            const totalStartMinutes = parseInt(startHours, 10) * 60 + parseInt(startMinutes, 10) + parseInt(startSeconds, 10) / 60; // Calculate total minutes
            const endTimeString = endDateString.split(" ")[2];
            console.log('end time')
            console.log(endTimeString)
            const [endHours, endMinutes, endSeconds] = endTimeString.split(":"); // Parse hours, minutes, seconds
            const totalEndMinutes = parseInt(endHours, 10) * 60 + parseInt(endMinutes, 10) + parseInt(endSeconds, 10) / 60; // Calculate total minutes
            duration += (totalStartMinutes - totalEndMinutes)/60;
        }
        // duration += route[i].ships[route[i].ships.length - 1].endtime / 3600;
        distanceByVehicle.push(dist);
        numStopsByVehicle.push(route[i].ships.length);
        firstEtaByVehicle.push(route[i].ships[0].etaReadable);
        lastEtaByVehicle.push(route[i].ships[route[i].ships.length - 1].etaReadable);
        totalWeightByVehicle.push(weight);
        // tripDurationByVehicle.push(duration);
        // duration = 0;
        dist = 0;
        weight = 0;
    }

    for (let i = 0; i < route.length; i++) {
        avgStopsByVehicle.push(tripDurationByVehicle[i] / numStopsByVehicle[i]);
    }

    let totalDistance = 0;
    let totalHours = duration;
    let totalStopsMade = 0;
    for (let i = 0; i < route.length; i++) {
        totalDistance += distanceByVehicle[i];
        // totalHours += tripDurationByVehicle[i];
        totalStopsMade += numStopsByVehicle[i];
    }
    let avgHrs = totalHours / driverIdsArr.length;
    let avgStops = totalStopsMade / driverIdsArr.length;
    let avgStopsPerHr = totalStopsMade / totalHours;
    let avgDist = totalDistance / driverIdsArr.length;


    const metrics = [
        totalHours.toFixed(2),
        avgStops.toFixed(2),
        totalDistance.toFixed(2),
        avgHrs.toFixed(2),
        avgStopsPerHr.toFixed(2),
        avgDist.toFixed(2)
    ]

    return metrics;
}