import React, {useState} from "react";
import Body from '../../components/Body';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Col, Row, Container, Card, Button, Alert} from 'react-bootstrap';
import {useNavigate} from "react-router-dom";
import {ResponseContext} from "../../contexts/LastMileContexts/ResponseContext";
import {ResponseContextType} from "../../contexts/LastMileContexts/ResponseContextType";
import {DataContext} from "../../contexts/LastMileContexts/DataContext";
import {DataContextType} from "../../contexts/LastMileContexts/DataContextType";
import {MetricsContext} from "../../contexts/LastMileContexts/MetricsContext";
import {MetricsContextType} from "../../contexts/LastMileContexts/MetricsContextType";
import CallApi from "../../utils/CallApi";
import {constants} from "../../utils/constants";

interface Content {
    [content: string]: {
        heading: string;
        summary: string;
    };
}

const content : Content= {
    coldChainContent: {
        heading: "Difficulty maintaining cold chain compliance",
        summary: "Cold chain compliance refers to the process of ensuring that products that require refrigeration or freezing are transported and stored within a specific temperature range to maintain their quality and safety. Delivery companies can face several challenges in maintaining cold chain compliance, such as temperature monitoring, equipment failure, and regulatory handling.\n" +
            "\n" +
            "The LogisticsEngine’s ‘skills’ capability is a dedicated parameter ensuring your specialized cargos are matched with the types of fleet they need, all while ensuring high quality of on-time delivery and on-route visibility."
    },
    mixedFleetContent: {
        heading: "Difficulty Managing Pick Up and Drop Off in One Journey with Multiple Depots",
        summary: "Let’s assume you are a fleet operation owning vehicles that are contracted out by local bakeries. You have locations across three parts of the city, and you’re tasked to pick up various cake orders and deliver them across the city with short time windows. \n" +
            "\n" +
            "The Logistics Engine can manage vehicles coming from various depots, as well as picking up and dropping off shipments all on the same trip, while ensuring capacities in all vehicles and on-time deliveries."
    },
    hazardousGoodsContent: {
        heading: "Hazardous Goods Constrained by Restricted Time On the Road",
        summary: "Transporting hazardous goods such as petroleum products presents several challenges for logistics companies, primarily due to the high flammability and volatility of these materials. Some of the key challenges are:\n" +
            "\n" +
            "restricted time windows for transportation\n" +
            "\n" +
            "compliance with regulations. For example, in Singapore, certain dangerous goods are only allowed to be on the road between the hours of 9-5. The Logistics Engine offers advanced route planning solutions for the transportation of hazardous goods according to specific demands. "
    },
    largeFleetContent: {
        heading: "Large Fleets Blocked At City Limit",
        summary: "Delivery companies often face regulatory constraints that prohibit large trucks from entering city limits or certain areas with narrow streets or limited access. Some of the key challenges that delivery companies may face in such situations include:\n" +
            "\n" +
            "Increased Transit Time\n" +
            "\n" +
            "Increased Cost\n" +
            "\n" +
            "Limited Capacity\n" +
            "\n" +
            "Regulatory Compliance\n" +
            "\n" +
            "Safety Concerns\n" +
            "\n" +
            "Thus, delivery companies must find innovative solutions to overcome these challenges while ensuring that they comply with regulatory requirements and meet the needs of their customers.\n" +
            "\n" +
            "The Logistics Engine can efficiently allocate shipments within pre-determined dense regions nd assign corresponding shipments to smaller fleet types, while optimizing on-time deliveries."
    },
    priorityOrdersContent: {
        heading: "Lack of Tools in Identifying and Fulfilling Priority Orders",
        summary: "Similar to Amazon prime shipping, or UPS express critical, sometime shipments need to be ensured that they are delivered on time with no exceptions. Some of the challenges that delivery companies may face when dealing with high-priority shipments alongside lower priority shipments include:\n" +
            "\n" +
            "Time sensitivity, Route optimization, Resource allocation and Communication with customers"+
            "The Logistics Engine can make sure that your priority shipments are always delivered on time, even when total loads are beyond capacity."
    }
};

// @ts-ignore
const SubUseCases = () => {
    let navigate = useNavigate();
    const { response, updateResponse} = React.useContext(ResponseContext) as ResponseContextType;
    const { data, updateData} = React.useContext(DataContext) as DataContextType;
    const {useCase} = React.useContext(MetricsContext) as MetricsContextType;

    const handleBack = () => {
        navigate("/use-case");
    }

    const [inputData, setInputData] = useState('');
    const [responseData, setResponseData] = useState('');

    const [returnToDepot, setReturnToDepot] = useState('');
    const handleReturnToDepot = (event: { target: { value: React.SetStateAction<string>; }; }) =>{
        setReturnToDepot(event.target.value);
    }

    const [trafficConditions, setTrafficConditions] = useState('')
    const handleTrafficConditionChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setTrafficConditions(event.target.value);
    }

    const input = { inputData }
    const getGoogleCloudInputFile = async  () => {
        const gcsInput = await CallApi(constants.CONSOLE_MAPPING_API_DOMAIN,
            constants.CONSOLE_MAPPING_API_READ_GCS, JSON.stringify(input), true);
        if (gcsInput.hasOwnProperty('error')) {
            console.log('Error in consolemappingapi: ', gcsInput, gcsInput.error);
            alert('Error in consolemappingapi');
            return;
        } else {
            console.log('looks hhere: ', gcsInput)
            updateData(JSON.parse(gcsInput));
        }
    }
    //     fetch('http://127.0.0.1:5001/read-gcs', {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json'
    //         },
    //         body: JSON.stringify(input)
    //     })
    //         .then(response => response.text())
    //         .then(data => {
    //             console.log(data);
    //             updateData(JSON.parse(data));
    //         })
    //         .catch(error => {
    //             console.error(error);
    //         });
    // }

    const input2 = { responseData }
    const getGoogleCloudResponseFile = async() => {
        console.log('input')
        console.log(JSON.stringify(input2))
        const gcsResponse = await CallApi(constants.CONSOLE_MAPPING_API_DOMAIN,
            constants.CONSOLE_MAPPING_API_READ_GCS_RESPONSE, JSON.stringify(input2), true);
        if (gcsResponse.hasOwnProperty('error')) {
            console.log('Error in consolemappingapi: ', gcsResponse, gcsResponse.error);
            alert('Error in consolemappingapi');
            return;
        } else {
            updateResponse(JSON.parse(gcsResponse));
        }
    }
    //     fetch('http://127.0.0.1:5001/read-gcs-response', {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json'
    //         },
    //         body: JSON.stringify(input2)
    //     })
    //         .then(response => response.json())
    //         .then(data => {
    //             console.log('data');
    //             console.log(data);
    //             updateResponse(data);
    //         })
    //         .catch(error => {
    //             console.error(error);
    //         });
    // }

    const handleSubmit = () => {
        console.log('handle submit')
        if (useCase === 'coldChainContent') {
            if (trafficConditions === 'light-traffic') {
                if (returnToDepot === 'yes') {
                    setInputData('cold-chain/inputs/sgp_1.2_true.json')
                    setResponseData('cold-chain/responses/sgp_1.2_true_response.json')
                    //return to depot is no
                } else {
                    setInputData('cold-chain/inputs/sgp_1.2_false.json')
                    setResponseData('cold-chain/responses/sgp_1.2_false_response.json')
                }
            } else if (trafficConditions === 'heavy-traffic') {
                if (returnToDepot === 'yes') {
                    setInputData('cold-chain/inputs/sgp_1.8_true.json');
                    setResponseData('cold-chain/responses/sgp_1.8_true_response.json')
                    //return to depot is no
                } else {
                    setInputData('cold-chain/inputs/sgp_1.8_false.json');
                    setResponseData('cold-chain/responses/sgp_1.8_false_response.json')
                }
            }
        } else if (useCase === 'mixedFleetContent') {
            if (trafficConditions === 'light-traffic') {
                if (returnToDepot === 'yes') {
                    setInputData('mpmd/inputs/sgp_small_1.2_true.json');
                    setResponseData('mpmd/responses/sgp_small_1.2_true_response.json')
                } else {
                    setInputData('mpmd/inputs/sgp_small_1.2_false.json');
                    setResponseData('mpmd/responses/sgp_small_1.2_false_response.json')
                }
            } else if (trafficConditions === 'heavy-traffic') {
                if (returnToDepot === 'yes') {
                    setInputData('mpmd/inputs/sgp_small_1.8_true.json');
                    setResponseData('mpmd/responses/sgp_small_1.8_true_response.json')
                } else {
                    setInputData('mpmd/inputs/sgp_small_1.8_false.json');
                    setResponseData('mpmd/responses/sgp_small_1.8_false_response.json')
                }
            }
        } else if (useCase === 'hazardousGoodsContent') {
                if (trafficConditions === 'light-traffic') {
                        setInputData('hazardous/inputs/sgp_1.2_true.json');
                        setResponseData('hazardous/responses/sgp_1.2_true_response.json')
                } else {
                    setInputData('hazardous/inputs/sgp_1.8_true.json');
                    setResponseData('hazardous/responses/sgp_1.8_true_response.json')
                }
        } else if(useCase === 'largeFleetContent') {
            if (trafficConditions === 'light-traffic') {
                if (returnToDepot === 'yes') {
                    setInputData('hetero-fleet/inputs/sgp_1.2_true.json');
                    setResponseData('hetero-fleet/responses/sgp_1.2_true_response.json')
                } else {
                    setInputData('hetero-fleet/inputs/sgp_1.2_false.json');
                    setResponseData('hetero-fleet/responses/sgp_1.2_false_response.json')
                }
            } else if (trafficConditions === 'heavy-traffic') {
                if (returnToDepot === 'yes') {
                    setInputData('hetero-fleet/inputs/sgp_1.8_true.json');
                    setResponseData('hetero-fleet/responses/sgp_1.8_true_response.json')
                } else {
                    setInputData('hetero-fleet/inputs/sgp_1.8_false.json');
                    setResponseData('hetero-fleet/responses/sgp_1.8_false_response.json')
                }
            }
        } else if(useCase === 'priorityOrdersContent') {
            if (trafficConditions === 'light-traffic') {
                if (returnToDepot === 'yes') {
                    setInputData('priority/inputs/sgp_1.2_true.json');
                    setResponseData('priority/responses/sgp_1.2_true_response.json')
                } else {
                    setInputData('priority/inputs/sgp_1.2_false.json');
                    setResponseData('priority/responses/sgp_1.2_false_response.json')
                }
            } else {
                if (returnToDepot === 'yes') {
                    setInputData('priority/inputs/sgp_1.8_true.json');
                    setResponseData('priority/responses/sgp_1.8_true_response.json')
                } else {
                    setInputData('priority/inputs/sgp_1.8_false.json');
                    setResponseData('priority/responses/sgp_1.8_false_response.json')
                }
            }
        }
    }

    const handleGoogleCloud = () => {
        if( inputData !== '' && responseData !== '') {
            getGoogleCloudInputFile();
            getGoogleCloudResponseFile();
            console.log('daattaatata')
            console.log(data)
            console.log(response)
            if(data !== null && response !== null) {
                if(useCase === 'coldChainContent') {
                    navigate("/cold-chain-success-metrics");
                } else if(useCase === 'hazardousGoodsContent') {
                    navigate("/hazardous-goods-success-metrics");
                } else if(useCase === 'largeFleetContent') {
                    navigate("/large-fleet-success-metrics")
                } else if(useCase === 'priorityOrdersContent') {
                    navigate("/priority-orders-success-metrics");
                } else if(useCase === 'mixedFleetContent') {
                    navigate("/mixed-fleet-success-metrics");
                } else if(useCase === 'lateArrivalsContent') {
                    navigate("/late-arrivals-success-metrics");
                }
            }
        }
    }

    return (
        useCase === '' ?
            <Body sidebar>
            <Container className="pt-lg-3 text">
                <Alert variant='danger'>No use case has been selected yet. Please click on the 'use cases' tab on the side bar to choose the use case you want.</Alert>
            </Container>
            </Body>
            :
        <Body sidebar>
            <Container className="pt-lg-3">
                <div className=" mx-auto text">
                    <Row>
                        <Col>
                            <Button className="d-grid gap-2 d-md-block button float-sm-start" onClick={handleBack}>Back</Button>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col>
                            <h3 className='heading'>{content[useCase].heading}</h3>
                            <br/>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={4} >
                            <Card >
                                <Card.Text className="p-2 text-start">
                                    <p className="fw-bold ">Overview</p>
                                    <p>{content[useCase].summary}</p>
                                    {/*<Button onClick={getGoogleCloudInputFile}>Get Google Cloud File</Button>*/}
                                    {/*<Button onClick={getGoogleCloudResponseFile}>Get Google Cloud Response File</Button>*/}
                                </Card.Text>
                            </Card>
                        </Col>
                        <Col xs={8} className='text-start'>
                            <Card border="grey p-2">
                                <Card.Text className="p-2">
                                    <div>
                                        {useCase === 'hazardousGoodsContent' ? null :
                                            <>
                                        <h6>Return To Depot</h6>
                                        &nbsp;
                                        <select onChange={handleReturnToDepot} value={returnToDepot} >
                                            <option disabled={true} value="">
                                                --Choose an option--
                                            </option>
                                            <option value="yes">yes</option>
                                            <option value="no">no</option>
                                        </select>
                                                <br/>
                                                </>
                                        }
                                        {/*all except Too many late arrivals and missed shipments have traffic conditions*/}
                                        {useCase === 'missedPackagesContent' ? null :
                                            <>
                                                <br/>
                                                <h6>Traffic Conditions</h6>
                                                &nbsp;
                                                <select onChange={handleTrafficConditionChange} value={trafficConditions} >
                                                    <option disabled={true} value="">
                                                        --Choose an option--
                                                    </option>
                                                    <option value="light-traffic">Light Traffic</option>
                                                    <option value="heavy-traffic">Heavy Traffic</option>
                                                </select>
                                                &nbsp;
                                                <br/>
                                            </>
                                        }
                                    </div>
                                    <div>
                                        <br/>
                                        <Button onClick={handleSubmit} className="button float-end">Update</Button>
                                    </div>
                                </Card.Text>
                                <br/>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <br/>
                            <Button className="float-end button" onClick={handleGoogleCloud}>Get Metrics
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Container>
        </Body>
    )
}

export default SubUseCases;