import React from "react";
import {useNavigate} from "react-router-dom";
import Body from '../../components/Body';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Button, Col, Row, Container, Table, Card} from "react-bootstrap";
import vehicleicon from "../../utils/img/last-mile/van.png";
import packageicon from "../../utils/img/last-mile/box.png";
import drivericon from "../../utils/img/last-mile/driver.png";
import timeicon from "../../utils/img/last-mile/clock.png";

const LargeFleetSuccessMetrics: React.FC  = () => {
    let navigate = useNavigate();

    const handleMoreInfo = () => {
        navigate("/cold-chain-metrics");
    };

    const handleBack = () => {
        navigate("/sub-use-cases");
    }

    return (
        <Body sidebar>
            <Container className="pt-lg-3">
                <div className="justify-content-center mx-auto">
                    <Row>
                        <Col>
                            <Button className="d-grid gap-2 d-md-block" onClick={handleBack}>Back</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h3 className="text-center">Large Fleets Blocked At City Limit</h3>
                            <br/>
                        </Col>
                        <Col xs={2}>
                            <Button className="float-right bg-success" onClick={handleMoreInfo} >
                                Dashboard
                            </Button>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col xs={11}>
                            <Table striped bordered hover>
                                <thead>
                                <tr>
                                    <th>Vehicles</th>
                                    <th>Vehicle Type</th>
                                    <th>Capacity Per Vehicle</th>
                                    <th>Number Of Shipments</th>
                                    <th>Number of Drivers</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>
                                        <img src={vehicleicon} style={{width:50, height:60}} alt="nil"/> x 6</td>
                                    <td><img src={vehicleicon} style={{width:50, height:50}} alt="nil"/> Trucks</td>
                                    <td>
                                        <img src={packageicon} style={{width:50, height:50}} alt="nil"/> x 50</td>
                                    <td><img src={packageicon} style={{width:50, height:50}} alt="nil"/> x 267</td>
                                    <td><img src={drivericon} style={{width:70, height:70}} className='p-2' alt="nil"/> x 5</td>
                                </tr>
                                <tr>
                                    <td>
                                        <img src={vehicleicon} style={{width:50, height:60}} alt="nil"/> x 4</td>
                                    <td><img src={vehicleicon} style={{width:50, height:50}} alt="nil"/> Vans</td>
                                    <td>
                                        <img src={packageicon} style={{width:50, height:50}} alt="nil"/> x 20</td>
                                    <td><img src={packageicon} style={{width:50, height:50}} alt="nil"/> x 33</td>
                                    <td><img src={drivericon} style={{width:70, height:70}} className='p-2' alt="nil"/> x 5</td>
                                </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={3}>
                        </Col>
                        <Col xs={3}>
                            <Card border="grey p-2">
                                <p className="text-center fw-bold"> Organization Start Time</p>

                                <Card.Text className='text-center'>
                                    <img src={timeicon} style={{width:70, height:70}} className='p-2' alt="nil"/> 9am
                                    <br/>
                                </Card.Text>
                            </Card>
                        </Col>
                        <Col xs={3}>
                            <Card border="grey p-2">
                                <p className="text-center fw-bold"> Standard Shipment Handling Time</p>
                                <Card.Text className='text-center'>
                                    <img src={timeicon} style={{width:70, height:70}} className='p-2' alt="nil"/> 10 mins
                                    <br/>
                                </Card.Text>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Container>
        </Body>
    );
};

export default LargeFleetSuccessMetrics;