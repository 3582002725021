import pako from 'pako';
import {constants} from "./constants";

/*
This function abstracts away the api call to the backend. It takes in the
- api domain,
- the api endpoint,
- the request body,
- and a boolean to indicate if the output is a map or not (default is false)
 */
export default async function CallApi(apiDomain:string, apiEndpoint: string, requestBody: any,
                                      isTheOutPutAMap: boolean = false, isTheOutputAZipFile: boolean = false) {

    // get current os env
    const env = process.env.REACT_APP_ENVIRONMENT;
    let currentEnvironment = "";
    let url = "";
    if (env === "dev") {
        currentEnvironment = '.dev'
        url = apiDomain + currentEnvironment + '.quincus.com/' + apiEndpoint;
    } else if (env === "test") {
        currentEnvironment = '.test'
        url = apiDomain + currentEnvironment + '.quincus.com/' + apiEndpoint;
    } else {
        // uncomment this if you want to run prediction/training on 5000, and console-mapping on 5001
        if (apiDomain === constants.CONSOLE_MAPPING_API_DOMAIN) {
            url = 'http://127.0.0.1:5001/' + apiEndpoint;
        } else {
        // assumes api endpoint is port 5000
        url = '/' + apiEndpoint;
        }
    }
    console.log('env is : ', env)
    console.log('url is: ', url)
    if (typeof requestBody !== "string") {
        requestBody = JSON.stringify(requestBody);
    }
    try {
        const start = performance.now();
        // gzip the request body
        const compressedRequestBody = pako.gzip(JSON.stringify(requestBody));
        const response = await fetch(url, {
            method: "POST",
            headers: {
                'Content-Encoding': 'gzip',
                "Content-Type": "application/json",
            },
            body: compressedRequestBody,
        }).then(async (response: any) => {
            console.log('response: ', response)
            if (! await response.ok) {
                console.log('response not ok')
                const end = performance.now();
                const duration = end - start;
                console.log('for url: ', url, ' duration: ', duration / 1000, 's');
                throw new Error(response.status.toString());
                // note that it is unnecessary to unzip the response as it is automatically unzipped from gzip
            } else {
                const isZipFile = response.headers.get('content-type') === 'application/zip';
                if (isZipFile) {
                    if (isTheOutputAZipFile) {
                        return await response.blob();
                    } else {
                        return {
                            'error: ': 'response is a zip file but isTheOutputAZipFile is false. '
                                + 'If the response is correct, please set isTheOutputAZipFile to true'
                        }
                    }
                } else {
                    return await response.text();
                }
            }
        })
        if (isTheOutPutAMap || isTheOutputAZipFile) {
            const end = performance.now();
            const duration = end - start;
            console.log('for url: ', url, ' duration: ', duration/1000, 's');
            return response;
        } else {
            const end = performance.now();
            const duration = end - start;
            console.log('for url: ', url, ' duration: ', duration/1000, 's');
            return JSON.parse(response);
        }
    } catch (error) {
        return {'error: ': error};
    }

}