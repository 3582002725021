import React, {useEffect, useRef, useState} from 'react';
import {
    Row,
    Col,
    Card,
    Tab,
    Nav,
    Table,
    Button,
    Container,
} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import Body from "../../components/Body";
import  {ResponseContext} from "../../contexts/LastMileContexts/ResponseContext";
import {ResponseContextType} from "../../contexts/LastMileContexts/ResponseContextType";
import {DataContext} from "../../contexts/LastMileContexts/DataContext";
import {DataContextType} from "../../contexts/LastMileContexts/DataContextType";
import {Spinner} from "react-bootstrap";

const UploadDataset = () => {
    const [numVehicles, setNumVehicles] = useState(0);
    const [numPackages, setNumPackages] = useState(0);
    let deliveryCounter = useRef(0);
    let pickupCounter = useRef(0);
    const [type, setType] = useState('');
    const [apiUrl, setApiUrl] = useState('');
    const { updateResponse } = React.useContext(ResponseContext) as ResponseContextType;
    const { data, updateData } = React.useContext(DataContext) as DataContextType;
    const [loading, setLoading]= useState(false);
    let services = new Array<Object>();

    //file uploading
    const [content, setContent] = useState('');

    const handleFileChosen = (file: File) => {
        if(file != null) {
            const fileReader = new FileReader();
            fileReader.readAsText(file);
            fileReader.onload = e => {
                const target = e.target;
                const result = target?.result;
                console.log(result);
                if (typeof result === "string") {
                    setContent(result);
                    //updateData(JSON.parse(content));
                    let jsonObj: Object = JSON.parse(result);
                    updateData(jsonObj);
                    setNumVehicles(Object.entries(jsonObj)[1][1].length);
                    setNumPackages(Object.entries(jsonObj)[2][1].length);
                    services = Object.entries(jsonObj)[2][1];
                   // console.log(typeof services[1]['type']);
                    for(let i=0; i<services.length; i++) {
                        // @ts-ignore
                        if(services[i]['type'] === 'DELIVERY') {
                            deliveryCounter.current = deliveryCounter.current+1;
                        } else { // @ts-ignore
                            if(services[i]['type'] === "PICKUP") {
                                pickupCounter.current = pickupCounter.current + 1;
                            }
                        }
                    }
                }

            }
        }
    }

    // const parsed = JSON.parse(content) as typeof sgData;
    // useEffect(() => {
    //     setNumVehicles(parsed.vehicles.length);
    //     setNumPackages(parsed.services.length);
    // }, [parsed]);

    const handleToggleChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setType(event.target.value);
    }


    useEffect(() => {
        if(type==='DEV') {
            setApiUrl('https://api.logistics.dev.quincus.com/DepotResults');
        } else if(type === 'POC') {
            setApiUrl('https://api.logistics.poc.quincus.com/DepotResults');
        }
    },[type]);

    // run LE
    let leResults: Object;
    let navigate = useNavigate();
    const handleRunLe = async() => {
        console.log(apiUrl)
        console.log(JSON.stringify(data))
        setLoading(true);
        // POST request using fetch inside useEffect React hook
        await fetch(apiUrl,
            {
                method: 'POST',
                headers: ({ 'Access-Control-Allow-Origin': '*', 'Accept' : 'application/json', 'Content-Type': 'application/json', 'Authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJRdWluY3VzTm9uUHJvZCIsIm5hbWUiOiJEU1RlYW0iLCJpYXQiOjE2MzE2NzczMDl9.PatDkmMlTpF4HWUQ6V38IGQuHSHBBXbcKlTJUcCRb-k',
                    'Transfer-Encoding': 'Chunked', 'Access-Control-Allow-Method': '*', 'Access-Control-Max-Age': '100000', 'Referrer-Policy': 'origin'
                }),
                body: JSON.stringify(data),
            })
            .then(response => response.json())
            .then((data) => {
                // console.log('data');
                // console.log(data);
                //  setResults(data);
                leResults = data;
                console.log('results');
                console.log(leResults);
            })
            .catch((err) => {
                console.log('err');
                console.log(err);
                throw err;
            });
        updateResponse(leResults);
        navigate("/output");
    }

    return (
        <Body sidebar>
            <Container className="pt-3 flex-column">
            <Row className="d-flex">
                <Col xs={4} >

                    <form>
                        <div className="form-group pt-2">
                            <label className="">Upload Your Own Dataset</label>
                            <input type='file' id='file' className="input-file" accept=".json"  onChange={e => e != null
                                // @ts-ignore
                                                                                                            ? handleFileChosen(e.target.files[0])
                                                                                                            : 0}/>
                        </div>
                    </form>
                    <br></br>
                    <Card border="dark p-2" className="overflow-scroll text-wrap " style={{height:"500px"}} >
                        <Card.Text>
                            {/*{JSON.stringify(content)}*/}
                            {content}
                        </Card.Text>
                    </Card>

                        <div >
                            <br />
                            Server Type
                            &nbsp;
                            <select onChange={handleToggleChange} value={type} >
                                <option disabled={true} value="">
                                    --Choose an option--
                                </option>
                                <option value="DEV">DEV</option>
                                <option value="POC">POC</option>
                            </select>
                            &nbsp;
                        </div>
                    <div className="float-end pt-2">
                        &nbsp;
                            <Button onClick={() => { handleRunLe() }} variant="dark">
                                Run LE
                            </Button>
                    </div>
                    <div className="float-end pt-2">
                        {loading ?  <Spinner style={{marginBottom:27}} animation="border"/> : null }
                    </div>

                </Col>
                <Col xs={8} >
                    <Tab.Container id="packages-data" defaultActiveKey="third">
                        <Nav variant="tabs" defaultActiveKey="/main">
                            <Nav.Item>
                                <Nav.Link eventKey="first">Delivery</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="second">Pickup</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="third">Delivery + Pickup</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content>
                            <Tab.Pane eventKey="first">
                                <br/>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>
                                            Data Summary
                                        </Card.Title>
                                        <Card.Text>
                                            Number of Vehicles
                                        </Card.Text>
                                        <Card.Text>
                                            {numVehicles}
                                        </Card.Text>
                                        <Card.Text>
                                            Number of Shipments
                                        </Card.Text>
                                        <Card.Text>
                                            {deliveryCounter.current}
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                                <br/>
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                                <br/>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>
                                            Data Summary
                                        </Card.Title>
                                        <Card.Text>
                                            Number of Vehicles
                                        </Card.Text>
                                        <Card.Text>
                                            {numVehicles}
                                        </Card.Text>
                                        <Card.Text>
                                            Number of Shipments
                                        </Card.Text>
                                        <Card.Text>
                                            {pickupCounter.current}
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                                <br/>
                            </Tab.Pane>
                            <Tab.Pane eventKey="third" className="p-2">
                                <br/>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>
                                            Data Summary
                                        </Card.Title>
                                        <Card.Text>
                                            Number of Vehicles
                                        </Card.Text>
                                        <Card.Text>
                                            {numVehicles}
                                        </Card.Text>
                                        <Card.Text>
                                            Number of Shipments
                                        </Card.Text>
                                        <Card.Text>
                                            {numPackages}
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                                <br></br>
                                <p>shipment map</p>
                                <br />
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </Col>

            </Row>
            <Row>
                <Col xs={4}></Col>
                <Col xs={8}>
                    <Table striped bordered hover p-2>
                        <thead>
                        <tr>
                            <th>Speed</th>
                            <th>Capacity (Vehicles)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>meters / second</td>
                            <td>Unit</td>
                        </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
            </Container>
        </Body>
    )
}

export default UploadDataset;