import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { NavLink } from 'react-router-dom';
import '../custom_font.css';

export default function Sidebar() {
    return (
        <Navbar sticky="top" className="flex-column Sidebar text">
            <div className='subheading fw-bolder'>QAI Console</div>
            <Nav variant="pills" className="flex-column custom-nav-pills">
                <Navbar bg="light" variant="light">
                    <Navbar.Brand className="subheading">Middle Mile</Navbar.Brand>
                </Navbar>
                <Nav.Item>
                    <Nav.Link as={NavLink} to="/demo-tool" end>Demo</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link as={NavLink} to="/mme-prediction" end>Prediction</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link as={NavLink} to="/mme-dashboard" end>Dashboard</Nav.Link>
                </Nav.Item>
            </Nav>
            <Navbar bg="light" variant="light">
                <Navbar.Brand className="subheading">Logistics Engine</Navbar.Brand>
            </Navbar>
            <Nav variant="pills" className="flex-column custom-nav-pills">
                {/*<Nav.Item>*/}
                {/*    <Nav.Link as={NavLink} to="/preloaded-datasets" end>Preloaded Datasets</Nav.Link>*/}
                {/*</Nav.Item>*/}
                {/*<Nav.Item>*/}
                {/*    <Nav.Link as={NavLink} to="/upload-dataset" end>Upload Your Own Dataset</Nav.Link>*/}
                {/*</Nav.Item>*/}
                <Nav.Item>
                    <Nav.Link as={NavLink} to="/use-case" end>Use Cases</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link as={NavLink} to="/self-service-homepage" end>Self Service</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link as={NavLink} to="/use-case-generator-homepage" end>Generate Your Own Use Cases</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link as={NavLink} to="/ls-homepage" end>Run LS</Nav.Link>
                </Nav.Item>
            </Nav>
        </Navbar>
    );
}