import * as React from 'react';
import { MetricsContextType} from "./MetricsContextType";

export const MetricsContext = React.createContext<MetricsContextType | null>(null);

interface Props {
    children: React.ReactNode;
}

const MetricsProvider: React.FC<Props> = ({ children }) => {
    const[totalHrsFirst, setTotalHrsFirst] = React.useState(0);
    const updateTotalHrsFirst = (newTotalHrsFirst: number) => {
        setTotalHrsFirst(newTotalHrsFirst);
    };
    const[avgStopsPerDriverFirst, setAvgStopsPerDriverFirst]= React.useState(0);
    const updateAvgStopsPerDriverFirst = (newAvgStopsPerDriverFirst: number) => {
        setAvgStopsPerDriverFirst(newAvgStopsPerDriverFirst);
    }
    const[totalDistFirst, setTotalDistFirst] = React.useState(0);
    const updateTotalDistFirst = (newTotalDistFirst: number) => {
        setTotalDistFirst(newTotalDistFirst);
    }
    const[avgHoursOnRoadFirst, setAvgHoursOnRoadFirst]= React.useState(0);
    const updateAvgHoursOnRoadFirst =(newAvgHoursOnRoadFirst: number) => {
        setAvgHoursOnRoadFirst(newAvgHoursOnRoadFirst);
    }
    const[avgStopsPerDriverPerHourFirst, setAvgStopsPerDriverPerHourFirst]= React.useState(0);
    const updateAvgStopsPerDriverPerHourFirst = (newAvgStopsPerDriverPerHourFirst: number) => {
        setAvgStopsPerDriverPerHourFirst(newAvgStopsPerDriverPerHourFirst);
    }
    const[avgDistFirst, setAvgDistFirst]= React.useState(0);
    const updateAvgDistFirst = (newAvgDistFirst: number) => {
        setAvgDistFirst(newAvgDistFirst);
    }

    const [useCase, setUseCase] = React.useState('');
    const updateUseCase = (newUseCase: string) => {
        setUseCase(newUseCase);
    }

    const [numMissedPackages, setNumMissedPackages] = React.useState(0);
    const updateNumMissedPackages = (newNumMissedPackages: number) => {
        setNumMissedPackages(newNumMissedPackages);
    }


    return <MetricsContext.Provider value={{totalHrsFirst, updateTotalHrsFirst, avgStopsPerDriverFirst, updateAvgStopsPerDriverFirst, totalDistFirst, updateTotalDistFirst,
        avgHoursOnRoadFirst, updateAvgHoursOnRoadFirst, avgStopsPerDriverPerHourFirst,updateAvgStopsPerDriverPerHourFirst,
        avgDistFirst, updateAvgDistFirst, useCase, updateUseCase, numMissedPackages, updateNumMissedPackages}}>
        {children}
    </MetricsContext.Provider>
}

export default MetricsProvider;