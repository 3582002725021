import {Button, Spinner, Table} from "react-bootstrap";
import React from "react";
import MmeCard from "../MiddleMileComponents/MmeCard";
import aStarPrediction from "../../contexts/DemoContents/LatestFiles/predictions/a_star_prediction.json";
import settingsJson from "../../contexts/DemoContents/LatestFiles/settings/base_settings.json";
import {Doughnut, Scatter} from "react-chartjs-2";
import CallApi from "../../utils/CallApi";
import {constants} from "../../utils/constants";
import {Chart as ChartJS, ArcElement, Legend, Tooltip, LineElement, LinearScale, PointElement} from "chart.js";
import sleep from "../../utils/sleep";
import ParsePrediction from "../../utils/DemoSharedFunctions/ParsePredictionJson";
import GenerateStatisticsTable from "../../utils/DemoSharedFunctions/GenerateStatisticsTable";

interface AStarComparisonProps {
    graphJson: any;
    packagesJson: any;
    mmePredictionResults: any;
    aStarPredictionResultsCopy?: any;
    mmePredictionTime: number;
    useCachedFiles: boolean;
}

interface PredictionResults {
    [key: string]: any;
}

export default function AStarRoutingStatisticsComparison({graphJson, packagesJson, mmePredictionResults, aStarPredictionResultsCopy, mmePredictionTime, useCachedFiles}: AStarComparisonProps) {
    const [aStarPredictionResults, setAStarPredictionResults] = React.useState<any>(aStarPredictionResultsCopy? aStarPredictionResultsCopy : null);
    const [loadingPredictionMap, setLoadingPredictionMap] = React.useState<boolean>(false);
    const [predictionMap, setPredictionMap] = React.useState<any>(null);
    const [aStarPredictionTime, setAStarPredictionTime] = React.useState<number>(0);

    const [exploreShipment, setExploreShipment] = React.useState<boolean>(false);

    ChartJS.register(ArcElement, Tooltip, Legend);

    const runAStar = async () => {
        if (mmePredictionResults === null) {
            alert('Please run MME first');
            return;
        }
        if (useCachedFiles) {
            setLoadingPredictionMap(true);
            // uncomment this to use temp
            const SLEEP_TIME = 12000;
            await sleep(SLEEP_TIME);
            setAStarPredictionResults(aStarPrediction)
            setAStarPredictionTime(SLEEP_TIME/1000);

            const mappingResponse = await CallApi(constants.CONSOLE_MAPPING_API_DOMAIN, constants.CONSOLE_MAPPING_API_GENERATE_MME_PACKAGE_ROUTE, JSON.stringify({
                list_of_package_datas: [mmePredictionResults, aStarPrediction],
                list_of_colors: ["Black", "DeepPink"],
                list_of_layer_names: ["MME", "A*"],
                display_suggestions: false,
            }), true);
            if (mappingResponse.hasOwnProperty('error')) {
                console.log('Error in generateMmeMap: ', mappingResponse, mappingResponse.error);
                alert('Error in generateMmeMap');
                return;
            }
            setPredictionMap(mappingResponse);
            setLoadingPredictionMap(false);
        } else {
            setLoadingPredictionMap(true);
            let data = JSON.stringify({
                graph: graphJson,
                packages: packagesJson,
                settings:settingsJson
            });
            const aStarPredictResponse = await CallApi(constants.MME_PREDICTION_API_DOMAIN,
                constants.MME_PREDICTION_API_A_STAR_PREDICT, data, false);
            if (aStarPredictResponse.hasOwnProperty('error')) {
                console.log('Error in generateAStarPredictions: ', aStarPredictResponse, aStarPredictResponse.error);
                alert('Error in generateAStarPredictions');
                return;
            }
            else if (aStarPredictResponse.hasOwnProperty('unique_id')) {
                let getPredictionData = JSON.stringify({
                    unique_id: aStarPredictResponse.unique_id,
                    // this way I can check if it is done
                    retrieve_all: false
                });
                console.log('getPredictionData', getPredictionData)
                while(true) {
                    let getPredictionResponse = await CallApi(constants.MME_PREDICTION_API_DOMAIN,
                        constants.MME_PREDICTION_API_GET_PREDICTION, getPredictionData, false);
                    if (getPredictionResponse.hasOwnProperty('error')) {
                        if (getPredictionResponse.error === 'IncompletePredictionError') {
                            console.log('Prediction still in progress');
                            await sleep(1000);
                        } else {
                            console.log('Error in generatePredictions: ', getPredictionResponse, getPredictionResponse.error);
                            alert('Error in generatePredictions');
                            return;
                        }
                    } else if (getPredictionResponse.hasOwnProperty('all_routing_info')) {
                        // @ts-ignore
                        const results = getPredictionResponse.all_routing_info;
                        console.log(results);
                        setAStarPredictionTime(getPredictionResponse.time);
                        setAStarPredictionResults(results);
                        console.log("a star results are in")
                        break;
                    }
                }
                // call mapping api
                // need to update mapping API to be able to take in two jsons, one for mme and one for a*
                const mappingResponse = await CallApi(constants.CONSOLE_MAPPING_API_DOMAIN, constants.CONSOLE_MAPPING_API_GENERATE_MME_PACKAGE_ROUTE, JSON.stringify({
                    list_of_package_datas: [mmePredictionResults, aStarPredictionResults],
                    list_of_colors: ["Black", "DeepPink"],
                    list_of_layer_names: ["MME", "A*"],
                    display_suggestions: false,
                }), true);
                if (mappingResponse.hasOwnProperty('error')) {
                    console.log('Error in generateMmeMap: ', mappingResponse, mappingResponse.error);
                    alert('Error in generateMmeMap');
                    return;
                }
                setPredictionMap(mappingResponse);
                setLoadingPredictionMap(false);
            } else {
                console.log(aStarPredictResponse)
                const errorMessage = aStarPredictResponse.error +
                    ', error with prediction API';
                console.log(errorMessage);
                alert(errorMessage);
            }
        }
    }

    function MultiplePackagesRoutingStatsTableComparison() {
        // ensure that the isolated package is not stuck
        let singlePredictionMme;
        let singlePredictionAStar;
        for (const packageId in mmePredictionResults as PredictionResults) {
            const packagePath = mmePredictionResults[packageId].path;
            if (packagePath.length > 0) {
                singlePredictionMme = {[packageId]: mmePredictionResults[packageId]};
                singlePredictionAStar = {[packageId]: aStarPredictionResults[packageId]};
                break;
            }
        }

        let {
            numberOfNodesOnTime: numberOfNodesOnTimeMme,
            numberOfNodesLate: numberOfNodesLateMme,
            numberOfNodesStuck: numberOfNodesStuckMme,
            originalLeadTimes: originalLeadTimesMme,
            packagesOnTime: packagesOnTimeMme,
            packagesLate: packagesLateMme,
            packagesStuck: packagesStuckMme
        } = ParsePrediction(mmePredictionResults, packagesJson);

        let {
            numberOfNodesOnTime: numberOfNodesOnTimeAStar,
            numberOfNodesLate: numberOfNodesLateAStar,
            numberOfNodesStuck: numberOfNodesStuckAStar,
            originalLeadTimes: originalLeadTimesAStar,
            packagesOnTime: packagesOnTimeAStar,
            packagesLate: packagesLateAStar,
            packagesStuck: packagesStuckAStar
        } = ParsePrediction(aStarPredictionResults, packagesJson);

        // Scatterplot needed information
        ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend);
        const optionsMme = {
            plugins: {
                title: {
                    display: true,
                    text: 'Lead Time (MME)',
                    font: {
                        size: 18,
                        weight: 'bold',
                    },
                },
            },
            scales: {
                x: {
                    title: {
                        display: true,
                        text: 'Shipment ID (MME)',
                    },
                },
                y: {
                    title: {
                        display: true,
                        text: 'Lead Time (MME)',
                    },
                },
            },
        };
        const dataMme = {
            datasets: [
                {
                    label: 'Lead Time',
                    data: originalLeadTimesMme,
                    pointBackgroundColor: 'black',
                },
                {
                    label: 'On Time',
                    data: packagesOnTimeMme,
                    pointBackgroundColor: 'green',
                },
                {
                    label: 'Late',
                    data: packagesLateMme,
                    pointBackgroundColor: 'orange',
                },
                {
                    label: 'Stuck',
                    data: packagesStuckMme,
                    pointBackgroundColor: 'red',
                }
            ],
        };

        const optionsAStar = {
            plugins: {
                title: {
                    display: true,
                    text: 'Lead Time (A*)',
                    font: {
                        size: 18,
                        weight: 'bold',
                    },
                },
            },
            scales: {
                x: {
                    title: {
                        display: true,
                        text: 'Shipment ID (A*)',
                    },
                },
                y: {
                    title: {
                        display: true,
                        text: 'Lead Time (A*)',
                    },
                },
            },
        };
        const dataAStar = {
            datasets: [
                {
                    label: 'Lead Time',
                    data: originalLeadTimesAStar,
                    pointBackgroundColor: 'black',
                },
                {
                    label: 'On Time',
                    data: packagesOnTimeAStar,
                    pointBackgroundColor: 'green',
                },
                {
                    label: 'Late',
                    data: packagesLateAStar,
                    pointBackgroundColor: 'orange',
                },
                {
                    label: 'Stuck',
                    data: packagesStuckAStar,
                    pointBackgroundColor: 'red',
                }
            ],
        };

        return (
            <>
            <div style={{ display: 'flex', width: '50%' }}>
                {/*this Doughnut is for mme*/}
                <div style={{ width: '30%', height: '30%', flex: 1 }}>
                <Doughnut data={{
                    labels: ["On Time", "Late", "Stuck"],
                    datasets: [
                        {
                            label: "Shipment Status for MME",
                            data: [numberOfNodesOnTimeMme, numberOfNodesLateMme, numberOfNodesStuckMme],
                            backgroundColor: ["green", "orange", "red"],
                            borderColor: ["green", "orange", "red"],
                            borderWidth: 1,
                        },
                    ]
                    }}
                  options={{
                      plugins: {
                        title: {
                            display: true,
                            text: 'MME',
                            font: {
                                size: 18,
                                weight: 'bold',
                            },
                        },
                    }}}/></div>
                {/*this Doughnut is for aStar*/}
                <div style={{ width: '30%', height: '30%', flex: 1}}>
                <Doughnut data={{
                    labels: ["On Time", "Late", "Stuck"],
                    datasets: [
                        {
                            label: "Shipment Status for A*",
                            data: [numberOfNodesOnTimeAStar,numberOfNodesLateAStar, numberOfNodesStuckAStar],
                            backgroundColor: ["green", "orange", "red"],
                            borderColor: ["green", "orange", "red"],
                            borderWidth: 1,
                        },
                    ],
                }}
                options={{
                    plugins: {
                        title: {
                            display: true,
                            text: 'A*',
                            font: {
                                size: 18,
                                weight: 'bold',
                            },
                        },
                    }}}/></div></div>
                <Table>
                    <thead>
                    <tr>
                        <th>
                            Mme(Black) vs <span style={{color: 'magenta'}}>AStar(Magenta)</span>
                        </th>
                    </tr>
                    </thead>
                </Table>
                {GenerateStatisticsTable([mmePredictionResults, aStarPredictionResults], packagesJson)}
            <div style={{display: 'flex'}}>
                <div style={{flex: 1}}>
                    <Scatter options={optionsMme} data={dataMme} />
                </div>
                <div style={{flex: 1}}>
                    <Scatter options={optionsAStar} data={dataAStar} />
                </div>
            </div>
                <Button onClick={() => setExploreShipment(!exploreShipment)}>Explore a Shipment</Button>
                {loadingPredictionMap
                    ? <Spinner/>
                    : (predictionMap && <div style={{ width: '50%'}} className="content"
                                             dangerouslySetInnerHTML={{__html: predictionMap}}></div>)
                }
                {exploreShipment && <AStarRoutingStatisticsComparison graphJson={graphJson} packagesJson={packagesJson} mmePredictionResults={singlePredictionMme} aStarPredictionResultsCopy={singlePredictionAStar} mmePredictionTime={mmePredictionTime} useCachedFiles={useCachedFiles}/>}
            </>
        )
    }

    function calcSavingsAndDisplay(mmeVariable: number, aStarVariable: number, isHigherBetter: boolean) {
        const savings = (mmeVariable - aStarVariable) / mmeVariable * 100;
        const savingsString = savings.toFixed(2);
        const mmeVariableString = mmeVariable.toFixed(2)
        const aStarVariableString = aStarVariable.toFixed(2)
        if (isHigherBetter) {
            if (savings > 0) {
                return <tbody>
                            <tr>
                                <td>{mmeVariableString}</td>
                                <td> | </td>
                                <td style={{color: 'DeepPink'}}>{aStarVariableString}</td>
                            </tr>
                            <tr>
                                <td style={{color: 'green'}}>{savingsString}%</td>
                            </tr>
                        </tbody>
            } else {
                return <tbody>
                    <tr>
                        <td>{mmeVariableString}</td>
                        <td> | </td>
                        <td style={{color: 'DeepPink'}}>{aStarVariableString}</td>
                    </tr>
                    <tr>
                        <td style={{color: 'red'}}>{savingsString}%</td>
                    </tr>
                </tbody>
            }
        } else {
            if (savings > 0) {
                return <tbody>
                    <tr>
                        <td>{mmeVariableString}</td>
                        <td> | </td>
                        <td style={{color: 'DeepPink'}}>{aStarVariableString}</td>
                    </tr>
                    <tr>
                        <td style={{color: 'red'}}>{savingsString}%</td>
                    </tr>
                </tbody>
            } else {
                return <tbody>
                    <tr>
                        <td>{mmeVariableString}</td>
                        <td> | </td>
                        <td style={{color: 'DeepPink'}}>{aStarVariableString}</td>
                    </tr>
                    <tr>
                        <td style={{color: 'green'}}>{savingsString}%</td>
                    </tr>
                </tbody>
            }
        }
    }

    return (
        <MmeCard
            title={'Performance of Industry Standard vs MME'}
            description={''}
            body={
                <div style={{ maxHeight: '400px', overflow: 'auto' }}>
                    {!aStarPredictionResultsCopy && !aStarPredictionResults && <Button onClick={runAStar}>Run A*</Button>}
                    {loadingPredictionMap && <Spinner/>}
                    {mmePredictionResults && aStarPredictionResults && (Object.keys(mmePredictionResults).length > 1
                        ? <MultiplePackagesRoutingStatsTableComparison/>
                        :
                        // there is only one package, but this is easier to do than to use .at(0) on the object
                        Object.entries(mmePredictionResults as PredictionResults).map(([packageId, mmePredictionValue]) => {
                            console.log(aStarPredictionResultsCopy)
                            const aStarPredictionValue:any = Object.values(aStarPredictionResultsCopy)[0];
                            console.log(aStarPredictionValue)
                                return <>
                                    <Table striped bordered hover>
                                        <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Origin Hub</th>
                                            <th>Destination Hub</th>
                                            <th>Delivery Status</th>
                                            <th>Volume Moved</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>{packageId}</td>
                                            <td>{mmePredictionValue.path[0].departure_hub}</td>
                                            <td>{mmePredictionValue.path.at(-1).arrival_hub}</td>
                                            {/* is this correct */}
                                            <td>{mmePredictionValue.is_at_destination
                                                ? 'At Destination'
                                                : mmePredictionValue.is_stuck
                                                    ? 'Stuck'
                                                    : 'Late'} | <span style={{color: 'DeepPink'}}>{aStarPredictionValue.is_at_destination
                                                    ? 'At Destination'
                                                    : aStarPredictionValue.is_stuck
                                                        ? 'Stuck'
                                                        : 'Late'}</span>
                                            </td>
                                            <td>{mmePredictionValue.is_at_destination && mmePredictionValue.arrived_before_pdd
                                                // again, there should only be one package, so using [0] should be correct
                                                ? packagesJson[0].volume
                                                : 'Not on time'} | <span style={{color: 'DeepPink'}}>{aStarPredictionValue.is_at_destination && aStarPredictionValue.arrived_before_pdd
                                                    // again, there should only be one package, so using [0] should be correct
                                                    ? packagesJson[0].volume
                                                : 'Not on time'}</span></td>
                                        </tr>
                                        </tbody>
                                        <thead>
                                        <tr>
                                            <th>Cost</th>
                                            <th>Duration</th>
                                            <th>Number Of Connections Taken</th>
                                            <th>Total Carbon Emissions</th>
                                            <th>Distance</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            {/* is it total_cost or last_cost */}
                                            {/*<td>{mmePredictionValue.total_cost} | {aStarPredictionResults.total_cost}</td>*/}
                                            <td>{calcSavingsAndDisplay(mmePredictionValue.total_cost, aStarPredictionValue.total_cost, false)}</td>
                                            <td>{calcSavingsAndDisplay(mmePredictionValue.duration, aStarPredictionValue.duration, false)}</td>
                                            <td>{calcSavingsAndDisplay(mmePredictionValue.path.length, aStarPredictionValue.path.length, false)}</td>
                                            <td>{calcSavingsAndDisplay(mmePredictionValue.duration / 3600 * 6000 * 3.1, aStarPredictionValue.duration / 3600 * 6000 * 3.1, false)}</td>
                                            <td>{calcSavingsAndDisplay(mmePredictionValue.distance > 0 ? mmePredictionValue.distance : mmePredictionValue.path.reduce((acc: any, subpath: { distance: any; }) => acc + subpath.distance, 0), aStarPredictionValue.distance > 0 ? aStarPredictionValue.distance : aStarPredictionValue.path.reduce((acc: any, subpath: { distance: any; }) => acc + subpath.distance, 0), false)}</td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                    {loadingPredictionMap
                                        ? <Spinner/>
                                        : (predictionMap && <div style={{ width: '75%'}} className="content"
                                                                 dangerouslySetInnerHTML={{__html: predictionMap}}></div>)
                                    }
                                </>
                            }
                        ))}
                </div>
            }/>
    )
}