import {Button, Spinner, Table} from "react-bootstrap";
import React from "react";
import MmeCard from "../MiddleMileComponents/MmeCard";
import CallApi from "../../utils/CallApi";
import {constants} from "../../utils/constants";
import {Doughnut, Scatter} from "react-chartjs-2";
import {Chart as ChartJS, ArcElement, Legend, Tooltip, LineElement, LinearScale, PointElement} from "chart.js";
import mmePredictionDurationOptiJson from "../../contexts/DemoContents/LatestFiles/predictions/pred_results_duration.json";
import mmePredictionDistanceOptiJson from "../../contexts/DemoContents/LatestFiles/predictions/pred_results_distance.json";
// import trainedModelJsonDurationOpti from "../../contexts/DemoContents/LatestFiles/agents/trained_model_with_co2_DURATION.json";
// import trainedModelJsonDistanceOpti from "../../contexts/DemoContents/LatestFiles/agents/trained_model_with_co2_DISTANCE.json";
// import trainedModelJsonCO2Opti from "../../contexts/DemoContents/LatestFiles/agents/trained_model_with_co2_CO2.json";

import sleep from "../../utils/sleep";
import ParsePrediction from "../../utils/DemoSharedFunctions/ParsePredictionJson";
import GenerateStatisticsTable from "../../utils/DemoSharedFunctions/GenerateStatisticsTable";
import settingsDictionary from "../../contexts/DemoContents/LatestFiles/settings/base_settings.json";

interface DifferentOptimisationsRoutingStatisticsProps {
    graphJson: any;
    packagesJson: any;
    mmePredictionCostOpti: any;
    useCached: boolean;
}

export default function DifferentOptimisationsRoutingStatistics({graphJson, packagesJson, mmePredictionCostOpti, useCached}: DifferentOptimisationsRoutingStatisticsProps) {
    const [completedPredictions, setCompletedPredictions] = React.useState<boolean>(false);

    const [mmePredictionDistanceOpti, setMmePredictionDistanceOpti] = React.useState<any>(null);
    const [mmePredictionDurationOpti, setMmePredictionDurationOpti] = React.useState<any>(null);

    const [costPredictionMap, setCostPredictionMap] = React.useState<any>(null);
    const [distancePredictionMap, setDistancePredictionMap] = React.useState<any>(null);
    const [durationPredictionMap, setDurationPredictionMap] = React.useState<any>(null);
    const [loadingPredictionMap, setLoadingPredictionMap] = React.useState<boolean>(false);
    const [runningPrediction, setRunningPrediction] = React.useState<boolean>(false);


    ChartJS.register(ArcElement, Tooltip, Legend);

    const generatePredictions = async () => {
        setRunningPrediction(true);
        setLoadingPredictionMap(true);
        if (useCached) {
            await sleep(20000);
            setMmePredictionDistanceOpti(mmePredictionDistanceOptiJson);
            setMmePredictionDurationOpti(mmePredictionDurationOptiJson);
        } else {

            // todo remove the bottom two lines after testing that the live files work
            setMmePredictionDistanceOpti(mmePredictionDistanceOptiJson);
            setMmePredictionDurationOpti(mmePredictionDurationOptiJson);

            // todo fix the bottom portion to properly call the API, but new agents have to be trained before
            // prediction can be done
            // let predictData = JSON.stringify({
            //     graph: graphJson,
            //     agent: trainedModelJson,
            //     packages: packagesDataJson,
            //     settings: settingsDictionary,
            //     retrieve_all_later: true,
            // });
            // const predictResponse = await CallApi(constants.MME_PREDICTION_API_DOMAIN,
            //     constants.MME_PREDICTION_API_PREDICT, predictData, false);
            // if (predictResponse.hasOwnProperty('error')) {
            //     console.log('Error in generatePredictions: ', predictResponse, predictResponse.error);
            //     alert('Error in generatePredictions');
            //     return;
            // } else if (predictResponse.hasOwnProperty('unique_id')) {
            //     const predictionResponseID = predictResponse.unique_id;
            //     alert('Here is your predictionID for access later: ' + predictionResponseID);
            //     console.log('Here is your predictionID for access later: ' + predictionResponseID + '. You can now ' +
            //         'navigate away from this page and come back later to retrieve your predictions.');
            // } else {
            //     console.log(predictResponse)
            //     const errorMessage = predictResponse.error +
            //         ', error with prediction API';
            //     console.log(errorMessage);
            //     alert(errorMessage);
            //     return;
            // }



        }
        const mappingResponseCost = await CallApi(constants.CONSOLE_MAPPING_API_DOMAIN, constants.CONSOLE_MAPPING_API_GENERATE_MME_PACKAGE_ROUTE, JSON.stringify({
            list_of_package_datas: [mmePredictionDistanceOptiJson],
            list_of_colors: ['black'],
            display_suggestions: false,
        }), true);
        if (mappingResponseCost.hasOwnProperty('error')) {
            console.log('Error in generateMmeMap: ', mappingResponseCost, mappingResponseCost.error);
            alert('Error in generateMmeMap');
            return;
        }
        setCostPredictionMap(mappingResponseCost);
        const mappingResponseDistance = await CallApi(constants.CONSOLE_MAPPING_API_DOMAIN, constants.CONSOLE_MAPPING_API_GENERATE_MME_PACKAGE_ROUTE, JSON.stringify({
            list_of_package_datas: [mmePredictionDistanceOptiJson],
            list_of_colors: ['black'],
            display_suggestions: false,
        }), true);
        if (mappingResponseDistance.hasOwnProperty('error')) {
            console.log('Error in generateMmeMap: ', mappingResponseDistance, mappingResponseDistance.error);
            alert('Error in generateMmeMap');
            return;
        }
        setDistancePredictionMap(mappingResponseDistance);
        const mappingResponseDuration = await CallApi(constants.CONSOLE_MAPPING_API_DOMAIN, constants.CONSOLE_MAPPING_API_GENERATE_MME_PACKAGE_ROUTE, JSON.stringify({
            list_of_package_datas: [mmePredictionDurationOptiJson],
            list_of_colors: ['black'],
            display_suggestions: false,
        }), true);
        if (mappingResponseDuration.hasOwnProperty('error')) {
            console.log('Error in generateMmeMap: ', mappingResponseDuration, mappingResponseDuration.error);
            alert('Error in generateMmeMap');
            return;
        }
        setDurationPredictionMap(mappingResponseDuration);
        setRunningPrediction(false);
        setLoadingPredictionMap(false);
        setCompletedPredictions(true);
    }

    function MultiplePackagesRoutingStatsTableComparison() {
        let {
            numberOfNodesOnTime: numberOfNodesOnTimeCost,
            numberOfNodesLate: numberOfNodesLateCost,
            numberOfNodesStuck: numberOfNodesStuckCost,
            originalLeadTimes: originalLeadTimesCost,
            packagesOnTime: packagesOnTimeCost,
            packagesLate: packagesLateCost,
            packagesStuck: packagesStuckCost
        } = ParsePrediction(mmePredictionCostOpti, packagesJson);

        let {
            numberOfNodesOnTime: numberOfNodesOnTimeDuration,
            numberOfNodesLate: numberOfNodesLateDuration,
            numberOfNodesStuck: numberOfNodesStuckDuration,
            originalLeadTimes: originalLeadTimesDuration,
            packagesOnTime: packagesOnTimeDuration,
            packagesLate: packagesLateDuration,
            packagesStuck: packagesStuckDuration
        } = ParsePrediction(mmePredictionDurationOpti, packagesJson);

        let {
            numberOfNodesOnTime: numberOfNodesOnTimeDistance,
            numberOfNodesLate: numberOfNodesLateDistance,
            numberOfNodesStuck: numberOfNodesStuckDistance,
            originalLeadTimes: originalLeadTimesDistance,
            packagesOnTime: packagesOnTimeDistance,
            packagesLate: packagesLateDistance,
            packagesStuck: packagesStuckDistance
        } = ParsePrediction(mmePredictionDistanceOpti, packagesJson);



        // Scatterplot needed information
        ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend);
        const optionsCost = {
            plugins: {
                title: {
                    display: true,
                    text: 'Cost Optimization',
                    font: {
                        size: 18,
                        weight: 'bold',
                    },
                },
            },
            scales: {
                x: {
                    title: {
                        display: true,
                        text: 'Shipment ID',
                    },
                },
                y: {
                    title: {
                        display: true,
                        text: 'Lead Time (Hours)',
                    },
                },
            },
        };
        const dataCost = {
            datasets: [
                {
                    label: 'Lead Time',
                    data: originalLeadTimesCost,
                    pointBackgroundColor: 'black',
                },
                {
                    label: 'On Time',
                    data: packagesOnTimeCost,
                    pointBackgroundColor: 'green',
                },
                {
                    label: 'Late',
                    data: packagesLateCost,
                    pointBackgroundColor: 'orange',
                },
                {
                    label: 'Stuck',
                    data: packagesStuckCost,
                    pointBackgroundColor: 'red',
                }
            ],
        };

        const optionsDistance = {
            plugins: {
                title: {
                    display: true,
                    text: 'Distance Optimization',
                    font: {
                        size: 18,
                        weight: 'bold',
                    },
                },
            },
            scales: {
                x: {
                    title: {
                        display: true,
                        text: 'Shipment ID',
                    },
                },
                y: {
                    title: {
                        display: true,
                        text: 'Lead Time (Hours)',
                    },
                },
            },
        };
        const dataDistance = {
            datasets: [
                {
                    label: 'Lead Time',
                    data: originalLeadTimesDistance,
                    pointBackgroundColor: 'black',
                },
                {
                    label: 'On Time',
                    data: packagesOnTimeDistance,
                    pointBackgroundColor: 'green',
                },
                {
                    label: 'Late',
                    data: packagesLateDistance,
                    pointBackgroundColor: 'orange',
                },
                {
                    label: 'Stuck',
                    data: packagesStuckDistance,
                    pointBackgroundColor: 'red',
                }
            ],
        };

        const optionsDuration = {
            plugins: {
                title: {
                    display: true,
                    text: 'Duration Optimization',
                    font: {
                        size: 18,
                        weight: 'bold',
                    },
                },
            },
            scales: {
                x: {
                    title: {
                        display: true,
                        text: 'Shipment ID',
                    },
                },
                y: {
                    title: {
                        display: true,
                        text: 'Lead Time (Hours)',
                    },
                },
            },
        };
        const dataDuration = {
            datasets: [
                {
                    label: 'Lead Time',
                    data: originalLeadTimesDuration,
                    pointBackgroundColor: 'black',
                },
                {
                    label: 'On Time',
                    data: packagesOnTimeDuration,
                    pointBackgroundColor: 'green',
                },
                {
                    label: 'Late',
                    data: packagesLateDuration,
                    pointBackgroundColor: 'orange',
                },
                {
                    label: 'Stuck',
                    data: packagesStuckDuration,
                    pointBackgroundColor: 'red',
                }
            ],
        };

        return (
            <>
                <div style={{ display: 'flex', width: '60%' }}>
                    {/*this Doughnut is for mme*/}
                    <div style={{ width: '30%', height: '30%', flex: 1 }}>
                        <Doughnut data={{
                            labels: ["On Time", "Late", "Stuck"],
                            datasets: [
                                {
                                    label: "Shipment Status for Cost",
                                    data: [numberOfNodesOnTimeCost, numberOfNodesLateCost, numberOfNodesStuckCost],
                                    backgroundColor: ["green", "orange", "red"],
                                    borderColor: ["green", "orange", "red"],
                                    borderWidth: 1,
                                },
                            ],
                        }}
                                  options={{
                                      plugins: {
                                          title: {
                                              display: true,
                                              text: 'Cost',
                                              font: {
                                                  size: 18,
                                                  weight: 'bold',
                                              },
                                          },
                                      }}}/></div>
                    {/*this Doughnut is for Distance*/}
                    <div style={{ width: '30%', height: '30%', flex: 1 }}>
                        <Doughnut data={{
                            labels: ["On Time", "Late", "Stuck"],
                            datasets: [
                                {
                                    label: "Shipment Status for Distance",
                                    data: [numberOfNodesOnTimeDistance, numberOfNodesLateDistance, numberOfNodesStuckDistance],
                                    backgroundColor: ["green", "orange", "red"],
                                    borderColor: ["green", "orange", "red"],
                                    borderWidth: 1,
                                },
                            ],
                        }}
                                  options={{
                                      plugins: {
                                          title: {
                                              display: true,
                                              text: 'Distance',
                                              font: {
                                                  size: 18,
                                                  weight: 'bold',
                                              },
                                          },
                                      }}}/></div>
                    {/*this Doughnut is for Duration*/}
                    <div style={{ width: '30%', height: '30%', flex: 1 }}>
                        <Doughnut data={{
                            labels: ["On Time", "Late", "Stuck"],
                            datasets: [
                                {
                                    label: "Shipment Status for Duration",
                                    data: [numberOfNodesOnTimeDuration, numberOfNodesLateDuration, numberOfNodesStuckDuration],
                                    backgroundColor: ["green", "orange", "red"],
                                    borderColor: ["green", "orange", "red"],
                                    borderWidth: 1,
                                },
                            ],
                        }}
                                  options={{
                                      plugins: {
                                          title: {
                                              display: true,
                                              text: 'Duration',
                                              font: {
                                                  size: 18,
                                                  weight: 'bold',
                                              },
                                          },
                                      }}}/></div>
                </div>
                <Table>
                    <thead>
                    <tr>
                        <th>
                            Cost(Black) vs <span style={{color: 'magenta'}}>Distance(Magenta)</span> vs <span style={{color: 'blue'}}>Duration(Blue)</span>
                        </th>
                    </tr>
                    </thead>
                </Table>
                {GenerateStatisticsTable([mmePredictionCostOpti, mmePredictionDistanceOpti, mmePredictionDurationOpti], packagesJson)}
                <div style={{display: 'flex'}}>
                    <div style={{flex: 1}}>
                        <Scatter style={{height:'26vh'}} options={optionsCost} data={dataCost} />
                    </div>
                    <div style={{flex: 1}}>
                        <Scatter style={{height:'26vh'}} options={optionsDistance} data={dataDistance} />
                    </div>
                    <div style={{flex: 1}}>
                        <Scatter style={{height:'26vh'}} options={optionsDuration} data={dataDuration} />
                    </div>
                </div>
                {loadingPredictionMap
                    ? <Spinner/>
                    : (costPredictionMap && distancePredictionMap && durationPredictionMap &&
                        <div style={{display: 'flex'}}>
                            <div style={{flex: 1}} className="content"
                                 dangerouslySetInnerHTML={{__html: costPredictionMap}}>
                            </div>
                            <div style={{flex: 1}} className="content"
                                 dangerouslySetInnerHTML={{__html: distancePredictionMap}}>
                            </div>
                            <div style={{flex: 1}} className="content"
                                 dangerouslySetInnerHTML={{__html: durationPredictionMap}}>
                            </div>
                        </div>)
                }
            </>
        )
    }

    return (
        <MmeCard
            title={'Different Optimization Comparison'}
            description={''}
            body={
                <div style={{ maxHeight: '400px', overflow: 'auto' }}>
                    {!completedPredictions &&
                        <Button onClick={generatePredictions}>Explore Different Optimizations</Button>
                    }
                    {runningPrediction
                        ? <Spinner/>
                        : <>{mmePredictionCostOpti && mmePredictionDistanceOpti && mmePredictionDistanceOpti && <MultiplePackagesRoutingStatsTableComparison/>}</>
                    }
                </div>
            }/>
    )
}