import React, {useState} from "react";
import Body from "../../components/Body";
import 'bootstrap/dist/css/bootstrap.min.css';
import {Col, Row, Container, Button, ListGroup, Card} from 'react-bootstrap';
import outputJson from "../../utils/data/LastMile/LsSolution.json";
// import inputJson from "../../utils/data/LastMile/BATCH_1_20.json";
import {useNavigate} from "react-router-dom";
import {downloadFile} from "../../utils/downloadFIle";
// @ts-ignore
import { CSVLink } from "react-csv";
import VehicleDistanceChart from "../../components/LastMileComponents/VehicleDistanceChart";
import { ResponseContext} from "../../contexts/LastMileContexts/ResponseContext";
import { ResponseContextType} from "../../contexts/LastMileContexts/ResponseContextType";
import {DataContext} from "../../contexts/LastMileContexts/DataContext";
import {DataContextType} from "../../contexts/LastMileContexts/DataContextType";
import downloadIcon from '../../utils/img/download-icon.png';
import CallApi from "../../utils/CallApi";
import {constants} from "../../utils/constants";

const LsDashboard:React.FC = () => {

    const {lsResponse} = React.useContext(ResponseContext) as ResponseContextType;
    const {LSdata} = React.useContext(DataContext) as DataContextType;
    console.log(lsResponse)
    let navigate = useNavigate();
    // const handleBack = () => {
    //     navigate("/use-case-generator-homepage")
    // }
    console.log("lsResponse", lsResponse)

    const exportToJson = (e: { preventDefault: () => void; }) => {
        e.preventDefault()
        downloadFile({
            data: JSON.stringify(lsResponse),
            fileName: 'response.json',
            fileType: 'text/json',
        })
    }

    //metrics content
    let csvMetricsContent = " Vehicle ID, Total Distance (km), No. of Stops, Vehicle Start Time, Vehicle End Time, Total Traveled Time \n";
    const json = JSON.stringify(lsResponse);
    const parsedJson = JSON.parse(json) as typeof outputJson;
    const parsed = Object.entries(parsedJson)[0][1];
    let route = parsed.routes;
    for (let i = 0; i < route.length; i++) {
        csvMetricsContent += JSON.stringify(route[i].vehicleId) + ',';
        csvMetricsContent += JSON.stringify(route[i].distanceToDepot) + ',';
        csvMetricsContent += JSON.stringify(route[i].services.length) + ',';
        csvMetricsContent += JSON.stringify(route[i].vehicleStartTime) + ',';
        csvMetricsContent += JSON.stringify(route[i].vehicleEndTime) + ',';
        csvMetricsContent += JSON.stringify(route[i].vehicleEndTime - route[i].vehicleStartTime) + '\n';
    }

   let totalCarbonEmissions = 0;
    // @ts-ignore
    if(parsed.totalCarbonEmission != null) {
        // @ts-ignore
        totalCarbonEmissions = parsed.totalCarbonEmission;
    }
    console.log("route", totalCarbonEmissions)

    let distanceArrForEachVehicle = [];
    let vehicleIDs = [];
    let numShipments = 0;
    for (let i = 0; i < route.length; i++) {
        distanceArrForEachVehicle.push(route[i].distanceToDepot);
        vehicleIDs.push(route[i].vehicleId);
        numShipments += route[i].services.length;
    }

    const handleSettings = () => {
        navigate("/self-service-settings-page")
    }

    // route map and package map
    const[visiblePackageMap, setVisiblePackageMap] = React.useState(false);
    const [mapForPackages, setMapForPackages] = useState('');
    const [visibleRouteMap, setVisibleRouteMap] = useState(false);
    const [mapForRoutes, setMapForRoutes] = useState('');
    const handlePackageMapChange = () => {
        setVisiblePackageMap(!visiblePackageMap);
        packageMap();
    }

    const packageMap = () => {
        CallApi(constants.CONSOLE_MAPPING_API_DOMAIN, constants.CONSOLE_MAPPING_API_GENERATE_PACKAGE_MAP_LS, JSON.stringify({
            LSdata: LSdata,
        }), true)
            .then((data) => {
                console.log('shipment map data');
                console.log(data);
                setMapForPackages(data);
            })
    }

    const handleRouteMapChange = () => {
        setVisibleRouteMap(!visibleRouteMap);
        routeMap();
    }

    const routeMap = () => {
        // updateLsResponse(sampleresponse);
        CallApi(constants.CONSOLE_MAPPING_API_DOMAIN, constants.CONSOLE_MAPPING_API_GENERATE_ROUTE_MAP_LS, JSON.stringify({
            LSdata: LSdata,
            LsResponse: lsResponse,
        }), true)
            .then((data) => {
                console.log('string');
                console.log(data);
                setMapForRoutes(data);
            })
    }

    return (
        <Body sidebar>
            <Container className="pt-lg-3">
                <Row>
                    {/*<Col xs={1}>*/}
                    {/*/!*<div className="justify-content-center mx-auto">*!/*/}
                    {/*    <Button className='float-sm-start button' onClick={handleBack}>Back</Button>*/}
                    {/*</Col>*/}
                    &nbsp;
                    <Col>
                        <h4 className='heading text-start'>   Dashboard</h4>
                    </Col>
                    <Col><div className='text float-end'>
                        <h6>Adjust Your Case: &nbsp;
                        <Button className="btn-outline-dark button" onClick={handleSettings}>Settings</Button>
                        </h6>
                    </div></Col>
                </Row>
                    <Row className="text-start">
                        <Col>
                        <div className='text'>
                            <Button onClick={exportToJson} type='button' variant="light" className="btn btn-primary btn-sm ">
                                <img src={downloadIcon} style={{width:20, height:20}} alt="nil" />JSON
                            </Button>
                            &nbsp;
                            <CSVLink data={JSON.stringify(parsed)} filename={"response.csv"} className="btn btn-primary btn-sm btn-light">
                                <img src={downloadIcon} style={{width:20, height:20}} alt="nil" />CSV
                            </CSVLink>
                            {/*<h6 className='subheading'>LE Response File</h6>*/}
                            {/*<div>*/}
                            {/*    <Button onClick={exportToJson} type='button' variant="light" className="btn btn-primary btn-sm ">*/}
                            {/*        <img src={downloadIcon} style={{width:20, height:20}} alt="nil" />JSON*/}
                            {/*    </Button>*/}
                            {/*    &nbsp;*/}
                            {/*    <CSVLink data={JSON.stringify(parsed)} filename={"response.csv"} className="btn btn-primary btn-sm btn-light">*/}
                            {/*        <img src={downloadIcon} style={{width:20, height:20}} alt="nil" />CSV*/}
                            {/*    </CSVLink>*/}
                            {/*</div>*/}
                        </div>
                        </Col>
                    </Row>
                <hr/>
                    <Row className='text'>
                        <Col xs={8}>
                            <h6 className="text-start subheading">Metrics &nbsp;
                                <CSVLink
                                    data={csvMetricsContent}
                                    filename={"metrics.csv"}
                                >
                                    <img src={downloadIcon} style={{width:20, height:20}} alt="nil" />
                                </CSVLink>
                            </h6>
                        </Col>
                    </Row>
                <Row className="justify-content-between">
                    <Col sm={4} className="mb-3 d-flex">
                        <Card className="flex-fill">
                            <Card.Body>
                                <Card.Title className='lightText'>Total Hours on Road</Card.Title>
                                <Card.Text className="heading">{(parsed.totalTraveledTime/3600).toFixed(2)}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={4} className="mb-3 d-flex">
                        <Card className="flex-fill">
                            <Card.Body>
                                <Card.Title className='lightText'>Average cost</Card.Title>
                                <Card.Text className="heading">{(parsed.avgCost).toFixed(2)}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={4} className="mb-3 d-flex">
                        <Card className="flex-fill">
                            <Card.Body>
                                <Card.Title className='lightText'>Total Distance Traveled (km)</Card.Title>
                                <Card.Text className="heading">{(parsed.totalDistance/1000).toFixed(2)}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="justify-content-between">
                    <Col sm={4} className="mb-3 d-flex">
                        <Card className="flex-fill">
                            <Card.Body>
                                <Card.Title className='lightText'>Average Hours on Road</Card.Title>
                                <Card.Text className="heading">{(parsed.avgTravelTime/3600).toFixed(2)}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={4} className="mb-3 d-flex">
                        <Card className="flex-fill">
                            <Card.Body>
                                <Card.Title className='lightText'>Average Stops per Driver /Hour</Card.Title>
                                <Card.Text className="heading">{(parsed.avgStopsPerDriverPerHour).toFixed(2)}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={4} className="mb-3 d-flex">
                        <Card className="flex-fill">
                            <Card.Body>
                                <Card.Title className='lightText'>Average Distance Traveled (km)</Card.Title>
                                <Card.Text className="heading">{parsed.avgDistance/1000}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <hr/>
                    <Row className="text-start text">
                        <Col xs={6} className='heading'>
                            <VehicleDistanceChart distanceArrForEachVehicle={distanceArrForEachVehicle} vehicleId={vehicleIDs}/>
                        </Col>
                        <Col>
                            <br/>
                            <br/>
                            <ListGroup className="w-40">
                                <ListGroup.Item className="d-flex justify-content-between align-items-start"><b>Number Of Shipments:</b> {numShipments}
                                </ListGroup.Item>
                                {/*<ListGroup.Item className="d-flex justify-content-between align-items-start">Number of Missed Shipments:*/}
                                {/*</ListGroup.Item>*/}
                                {/*<ListGroup.Item className="d-flex justify-content-between align-items-start">% Missed Shipments: %*/}
                                {/*</ListGroup.Item>*/}
                                <ListGroup.Item className="d-flex justify-content-between align-items-start">
                                    <b>Number of Vehicles Used:</b>{parsed.nbDriversUsed}
                                </ListGroup.Item>
                                { totalCarbonEmissions === 0 ? <></>
                                    : <>
                                    <ListGroup.Item className="d-flex justify-content-between align-items-start">
                                        <b>Total Carbon Emission:</b> {totalCarbonEmissions} kt
                                </ListGroup.Item>
                                    </>
                                }
                            </ListGroup>
                        </Col>
                    </Row>
                <br/>
                <Row>
                    <hr/>
                    <Col xs={6}>
                        <div className="p-2">
                            <h6 className="subheading">Shipment Map</h6>
                            <Button className="button" onClick={handlePackageMapChange}>
                                {visiblePackageMap ? 'Hide Map' : 'Show Map'}
                            </Button>
                            {visiblePackageMap && (
                                <div className="content" dangerouslySetInnerHTML={{ __html: mapForPackages }}></div>
                            )}
                            {!visiblePackageMap && (
                                <>
                                    <br />
                                </>
                            )}
                        </div>
                        <br />
                    </Col>
                    <Col xs={6}>
                        <div className="p-2">
                            <h6 className="subheading">Route Map</h6>
                            <Button className="button" onClick={handleRouteMapChange}>
                                {visibleRouteMap ? 'Hide Map' : 'Show Map'}
                            </Button>
                            {visibleRouteMap && (
                                <div className="content" dangerouslySetInnerHTML={{ __html: mapForRoutes }}></div>
                            )}
                            {!visibleRouteMap && (
                                <>
                                    <br />
                                </>
                            )}
                        </div>
                        <br />
                    </Col>
                </Row>
            </Container>
        </Body>
    )
}

export default LsDashboard;