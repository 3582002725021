import Body from '../components/Body';
import gifImage from '../contexts/GeneralContexts/quincus-animation-scheme.gif';

export default function HomePage() {
    return (
        <Body
            sidebar={true}>
            <div >
                <img style={{objectFit:'cover', width:'100%'}} src={gifImage} alt="Animated GIF" />
            </div>
        </Body>
    );
}