import React from "react";
import Body from "../../components/Body";
import 'bootstrap/dist/css/bootstrap.min.css';
import {Col, Row, Container} from 'react-bootstrap';
import {useNavigate} from "react-router-dom";
import inputJson from "../../utils/data/LastMile/BATCH_1_20.json";
import { ResponseContext} from "../../contexts/LastMileContexts/ResponseContext";
import { ResponseContextType} from "../../contexts/LastMileContexts/ResponseContextType";
import {DataContext} from "../../contexts/LastMileContexts/DataContext";
import {DataContextType} from "../../contexts/LastMileContexts/DataContextType";

const LSHomepage: React.FC  = () => {

    const {updateLsResponse} = React.useContext(ResponseContext) as ResponseContextType;
    const { updateLSdata} = React.useContext(DataContext) as DataContextType;
    const [loadingButton, setLoadingButton] = React.useState('');
    // const [ setContent] = useState('');
    let navigate = useNavigate();

    // const testLS = async() => {
    //     await fetch('https://api.logistics-optimizer.dev.quincus.com/demo')
    //         .catch((err) => {
    //             console.log('err');
    //             console.log(err);
    //             throw err;
    //         })
    // }
    // @ts-ignore
    const handleRunLS = async(button) => {
        setLoadingButton(button);
        await fetch('http://127.0.0.1:5000/routes',
            {
                // mode: 'no-cors',
                method: 'POST',
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Accept': 'application/json',
                    'Content-Type':  "application/json",
                    'Access-Control-Allow-Methods': '*'
                },
                body: JSON.stringify(inputJson),
            }).then(response => response.json())
            .then((data) => {
                console.log('data');
                console.log(data);
                updateLsResponse(data);
            }).catch((err) => {
                console.log('err');
                console.log(err);
                throw err;
            })
        navigate('/ls-dashboard')
        setLoadingButton('');
        // setLsLoading(false)
    };

    const handleFileChosen = (file: File) => {
        if(file != null) {
            const fileReader = new FileReader();
            fileReader.readAsText(file);
            fileReader.onload = e => {
                const target = e.target;
                const result = target?.result;
                console.log(result);
                if (typeof result === "string") {
                    // setContent(result);
                    //updateData(JSON.parse(content));
                    let jsonObj: Object = JSON.parse(result);
                    updateLSdata(jsonObj);
                }

            }
        }
    }


    return (
        <Body sidebar>
            <Container className="pt-lg-3">
                <div>
                    <h4 className="heading text-start">LS Demo</h4>
                    <Row className='text-start'>
                        <Col xs={8} className="d-grid align-items-center">
                            <button className="button btn btn-block btn-primary btn-lg text"  disabled={loadingButton === 'A'} onClick={() => handleRunLS('A')}>
                                {loadingButton === 'A' ? 'Loading...' : 'Use Preloaded Dataset'}
                            </button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <br/>
                            <h3>OR</h3>
                            <h6 className="subheading">Upload Your Own Dataset</h6>
                            <input type='file' id='file' className="input-file" accept=".json"  onChange={e => e != null
                                // @ts-ignore
                                ? handleFileChosen(e.target.files[0])
                                : 0}/>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col className="d-grid align-items-center">
                            <button className="button btn btn-block btn-primary btn-lg text"  disabled={loadingButton === 'B'} onClick={() => handleRunLS('B')}>
                                {loadingButton === 'B' ? 'Loading...' : 'Run LS with Loaded Dataset'}
                            </button>
                        </Col>
                        <Col xs={4}></Col>
                    </Row>
                </div>
            </Container>
        </Body>
    );
};

export default LSHomepage;