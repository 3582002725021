import React from "react";
import Body from '../../components/Body';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Row, Container, Button} from 'react-bootstrap';
import {useNavigate} from "react-router-dom";
import coldchainpic from '../../utils/img/last-mile/coldChainPic.png'
import mixedfleetpic from '../../utils/img/last-mile/zf-extends-suite-of-fleet-solutions-to-light-commercial-vehicles-in-europe_w555_h555.jpg'
import hazardousgoodspic from '../../utils/img/last-mile/US-Compliance-October-Blog-Header-Hazmat-1024x423-1.jpg'
import largefleetpic from '../../utils/img/last-mile/best route planner for delivery drivers-20230224-015504.webp'
import prioritypic from '../../utils/img/last-mile/61ba7431356cc5e4cbdfc2e3_skilled truc driver-20230223-091912.png'
import {MetricsContext} from "../../contexts/LastMileContexts/MetricsContext";
import {MetricsContextType} from "../../contexts/LastMileContexts/MetricsContextType";

// interface Content {
//     [activeContent: string]: {
//         heading: string;
//         summary: string;
//     };
// }
//
// const content : Content= {
//     coldChainContent: {
//         heading: "Difficulty maintaining cold chain compliance",
//         summary: "Summary 1"
//     },
//     lateArrivalsContent: {
//         heading: "Too Many Late Arrivals and Missed Shipments",
//         summary: "Summary 2"
//     },
//     mixedFleetContent: {
//         heading: "Difficulty Managing Mixed Fleet Types at Large Scale",
//         summary: "Summary 3"
//     },
//     hazardousGoodsContent: {
//         heading: "Difficulty Managing Hazardous Goods",
//         summary: "Summary 4"
//     },
//     largeFleetContent: {
//         heading: "Difficulty Managing Large Fleet",
//         summary: "Summary 5"
//     }
// };

const UseCase: React.FC  = () => {

    let navigate = useNavigate();
    const {useCase, updateUseCase} = React.useContext(MetricsContext) as MetricsContextType;

    function handleColdChain() {
        updateUseCase('coldChainContent');
        navigate("/sub-use-cases")
        console.log(useCase)
    }

    function handleMixedFleet() {
        updateUseCase('mixedFleetContent');
        navigate("/sub-use-cases")
        console.log(useCase)
    }

    function handleLargeFleet() {
        updateUseCase('largeFleetContent');
        navigate("/sub-use-cases")
        console.log(useCase)
    }

    function handleHazardousGoods() {
        updateUseCase('hazardousGoodsContent');
        navigate("/sub-use-cases")
        console.log(useCase)
    }
    function handlePriorityOrders() {
        updateUseCase('priorityOrdersContent');
        navigate("/sub-use-cases")
        console.log(useCase)
    }

    return (
        <Body sidebar>
            <Container className="pt-lg-3">
                <div className="justify-content-center mx-auto">
                    <Row className="justify-content-center">
                        <Col>
                            <h3 className='text-center heading'>What are your pain points?</h3>
                        </Col>
                    </Row>
                    <Row className="subheading">
                        <Col xs={4}>
                            <div className="p-4">
                            <Button variant='light' onClick={handleColdChain}>
                                <img src={coldchainpic} style={{width:300, height:200}} alt="nil"/>
                                <br/>
                                Difficulty Maintaining Cold Chain Compliance
                            </Button>
                            </div>
                        </Col>
                        <Col xs={4}>
                            <div className="p-4">
                                <Button variant='light' onClick={handleMixedFleet}>
                                    <img src={mixedfleetpic} style={{width:300, height:200}} alt="nil"/>
                                    <br/>
                                    Difficulty Managing Pick Up and Drop Off in One Journey with Multiple Depots
                                </Button>
                            </div>
                        </Col>
                        <Col xs={4}>
                            <div className="p-4">
                                <Button variant='light' onClick={handleLargeFleet}>
                                    <img src={largefleetpic} style={{width:300, height:200}} alt="nil"/>
                                    <br/>
                                    Large Fleets Blocked At City Limit
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <Row className="subheading">
                        <Col xs={4}>
                            <div className="p-4">
                                <Button variant='light' onClick={handleHazardousGoods}>
                                    <img src={hazardousgoodspic} style={{width:300, height:200}} alt="nil"/>
                                    <br/>
                                    Hazardous Goods Constrained by Restricted Time On the Road
                                </Button>
                            </div>
                        </Col>
                        <Col xs={4}>
                            <div className="p-4">
                                <Button variant='light' onClick={handlePriorityOrders}>
                                    <img src={prioritypic} style={{width:300, height:200}} alt="nil"/>
                                    <br/>
                                    Lack of Tools in Identifying and Fulfilling Priority Orders
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </Body>
    )
}

export default UseCase;