import Body from '../../components/Body';
import {Button, Container, Form} from "react-bootstrap";
import MmeCard from "../../components/MiddleMileComponents/MmeCard";
import React, {useRef} from "react";
import JSZip from "jszip";
import CallApi from "../../utils/CallApi";
import {constants} from "../../utils/constants";
import VisualisationOfNetwork from "../../components/DemoComponents/VisualisationOfNetwork";
import NetworkInfo from "../../components/DemoComponents/NetworkInfo";
import IsolatedFacilities from "../../components/DemoComponents/IsolatedFacilities";
import PackageInfo from "../../components/DemoComponents/PackageInfo";
import PackagesBreakdown from "../../components/DemoComponents/PackagesBreakdown";
import RoutingStatistics from "../../components/DemoComponents/RoutingStatistics";
import AStarRoutingStatisticsComparison from "../../components/DemoComponents/AStarRoutingStatisticsComparison";
import AlternativeRoutingStatisticsComparison
    from "../../components/DemoComponents/AlternativeRoutingStatisticsComparison";
import DifferentOptimisationsRoutingStatistics
    from "../../components/DemoComponents/DifferentOptimisationsRoutingStatistics";
import CatastropheRoutingComparison from "../../components/DemoComponents/CatastropheRoutingComparison";

export default function MmeDashboard() {

    const [done, setDone] = React.useState(false);

    const mmePredictionResult = useRef(null);
    const mmePredictionTime = useRef(-1);
    const resultsToDownload = useRef(null);
    const graphJsonToDownload = useRef(null);
    const trainedModelJsonToDownload = useRef(null);
    const packagesDataJsonToDownload = useRef(null);
    const settingsJsonToDownload = useRef(null);

    const downloadZip = () => {
        // @ts-ignore
        const blob = new Blob([resultsToDownload.current], { type: 'application/zip' });
        const blobURL = URL.createObjectURL(blob);

        // Create a download link element
        const downloadLink = document.createElement('a');
        downloadLink.href = blobURL;

        // Set the file name for the download
        downloadLink.download = 'downloads.zip';

        // Programmatically click the download link
        downloadLink.click();

        // Remove the temporary object URL
        URL.revokeObjectURL(blobURL);
    }

    // this was to ensure that all components use the same MME prediction result
    const handleMmePredictionChange = (newMmePredictionResult: any, newMmePredictionTime: number) => {
        mmePredictionResult.current = newMmePredictionResult;
        mmePredictionTime.current = newMmePredictionTime;
    };

    // todo fix if AlternativeRoutingStatisticsComparison is used
    // let singlePredictionMmeForAlt;
    // let singlePackageForAlt;
    // for (const packageId in mmePredictionResult as any) {
    //     // this was to ensure that the choice of package for alternative routing was valid
    //     console.log('look here', mmePredictionResult[packageId])
    //     console.log('keys', Object.values(mmePredictionResult[packageId]))
    //     const packagePath = mmePredictionResult[packageId].path;
    //     if (packagePath.length > 0) {
    //         singlePredictionMmeForAlt = {[packageId]: mmePredictionResult[packageId]};
    //         singlePackageForAlt = [multiplePackagesJson.find((packageJson) => packageJson.id === Number(packageId))];
    //         break;
    //     }
    // }

    const onSubmitPredictionID = async (event: React.ChangeEvent<HTMLFormElement>) => {
        event.preventDefault(); // can consider removing
        /*
        Ok here is the logic
        If coming from the prediction page, then there is no issue as everything is provided in location.state
        else if coming to this page specifically, then we need the graph, trained model, and packages data from the user
        so:
        if (location.state) {parse from location.state and use those values}
        else {ask the user to upload the prediction ID first to see if prediction is complete,
            if so, use the same prediction ID to get the graph and trained model and packages from an API}
         */
        const formData = new FormData(event.target);
        const formDataObj = Object.fromEntries(formData.entries())
        let getPredictionData = JSON.stringify({
            unique_id: formDataObj.predictionID,
            // this way I can check if it is done
            retrieve_all: false
        });
        console.log('getPredictionData', getPredictionData)
        let getPredictionResponse = await CallApi(constants.MME_PREDICTION_API_DOMAIN,
            constants.MME_PREDICTION_API_GET_PREDICTION, getPredictionData, false);
        if (getPredictionResponse.hasOwnProperty('error')) {
            if (getPredictionResponse.error === 'IncompletePredictionError') {
                console.log('Prediction still in progress');
                alert('Prediction still in progress, come back later');
                return;
            } else {
                console.log('Error in generatePredictions: ', getPredictionResponse, getPredictionResponse.error);
                alert('Error in generatePredictions');
                return;
            }
        } else if (getPredictionResponse.hasOwnProperty('all_routing_info')) {
            getPredictionData = JSON.stringify({
                unique_id: formDataObj.predictionID,
                retrieve_all: true
            });
            getPredictionResponse = await CallApi(constants.MME_PREDICTION_API_DOMAIN,
                constants.MME_PREDICTION_API_GET_PREDICTION, getPredictionData, false, true);
            if (getPredictionResponse.hasOwnProperty('error')) {
                if (getPredictionResponse.error === 'IncompletePredictionError') {
                    console.log('Prediction still in progress');
                    alert('Prediction still in progress, come back later');
                } else {
                    console.log('Error in generatePredictions: ', getPredictionResponse, getPredictionResponse.error);
                    alert('Error in generatePredictions');
                    return;
                }
            } else {
                // Parse the zip file and extract the internal JSON files
                const zip = new JSZip();
                await zip.loadAsync(getPredictionResponse);

                // Check if the file exists before reading its content
                const predictionFile = zip.file('prediction.json');
                const graphFile = zip.file('graph.json');
                const agentFile = zip.file('agent.json');
                const packageFile = zip.file('packages.json');
                const settingsFile = zip.file('settings.json');

                if (predictionFile && graphFile && agentFile && packageFile && settingsFile) {
                    // Files exist, proceed with reading their contents
                    const predictionDict = JSON.parse(await predictionFile.async('string') || '{}');
                    const graphDict = JSON.parse(await graphFile.async('string') || '{}');
                    const agentDict = JSON.parse(await agentFile.async('string') || '{}');
                    const packageDict = JSON.parse(await packageFile.async('string') || '{}');
                    const settingsDict = JSON.parse(await settingsFile.async('string') || '{}');

                    // Do something with the extracted JSON files, e.g., set them in state
                    mmePredictionResult.current = predictionDict;
                    console.log('mmePredictionResult.current', mmePredictionResult.current)
                    // @ts-ignore
                    graphJsonToDownload.current = graphDict;
                    // @ts-ignore
                    trainedModelJsonToDownload.current = agentDict;
                    // @ts-ignore
                    packagesDataJsonToDownload.current = packageDict;
                    // @ts-ignore
                    settingsJsonToDownload.current = settingsDict;

                    // Save the blob zip to a useState variable for later downloading
                    // @ts-ignore
                    resultsToDownload.current = getPredictionResponse;
                    setDone(true);
                    return;
                } else {
                    const errorMessage = getPredictionResponse +
                        ', error with prediction API: missing files in zip ';
                    console.log(errorMessage);
                    alert(errorMessage);
                    return;
                }
            }
        } else {
            console.log(getPredictionResponse)
            const errorMessage = getPredictionResponse.error +
                ', error with prediction API';
            console.log(errorMessage);
            alert(errorMessage);
            return;
        }
    };

    return (
        <Body sidebar>
            {!done
                ? <Form onSubmit={onSubmitPredictionID} key={0}>
                    <MmeCard
                        title={'Enter a prediction ID'}
                        description={''}
                        body={
                            <>
                                <Form.Group controlId="formPredictionID">
                                    <Form.Label>Prediction ID</Form.Label>
                                    <Form.Control type="text" placeholder="Enter prediction ID" name="predictionID"/>
                                </Form.Group>
                            </>
                        }
                    />
                    <Button variant="primary" type="submit">Submit</Button>
                </Form>
                : <center>
                    <Container>
                        <VisualisationOfNetwork graphJson={graphJsonToDownload.current}/>
                        <div style={{float: 'left', width: '50%'}}>
                            <NetworkInfo graphJson={graphJsonToDownload.current}/>
                        </div>
                        <div style={{float: 'right', width: '50%'}}>
                            <IsolatedFacilities graphJson={graphJsonToDownload.current}/>
                        </div>
                        <div style={{float: 'left', width: '100%'}}>
                            <PackageInfo packageJson={packagesDataJsonToDownload.current} graphJson={graphJsonToDownload.current}/>
                        </div>
                        <PackagesBreakdown packageJson={packagesDataJsonToDownload.current}/>
                        <RoutingStatistics graphJson={graphJsonToDownload.current} trainedModelJson={trainedModelJsonToDownload.current} packagesJson={packagesDataJsonToDownload.current} mmePredictionJson={mmePredictionResult.current} mmePredictionTimeGenerated={30} onMmePredictionGenerated={handleMmePredictionChange} useCached={false}/>
                        <AStarRoutingStatisticsComparison graphJson={graphJsonToDownload.current} packagesJson={packagesDataJsonToDownload.current} mmePredictionResults={mmePredictionResult.current} mmePredictionTime={mmePredictionTime.current} useCachedFiles={false} />
                        {/* todo fix the above if you need this*/}
                        {/*<AlternativeRoutingStatisticsComparison packagesJson={singlePackageForAlt} mmePredictionResultSinglePackage={singlePredictionMmeForAlt}/>*/}
                        <DifferentOptimisationsRoutingStatistics graphJson={graphJsonToDownload.current} packagesJson={packagesDataJsonToDownload.current} mmePredictionCostOpti={mmePredictionResult.current} useCached={false}/>
                        <CatastropheRoutingComparison graphJson={graphJsonToDownload.current} trainedModelJson={trainedModelJsonToDownload.current} packagesJson={packagesDataJsonToDownload.current} mmePredictionResults={mmePredictionResult.current}/>
                        <Button onClick={downloadZip}>Download Files</Button>
                    </Container>
                </center>
            }
        </Body>

    );
}