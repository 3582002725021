import {Table} from "react-bootstrap";
import React from "react";
import ParsePrediction from "./ParsePredictionJson";

interface PredictionResults {
    [key: string]: any;
}

/*
this component takes in a list of prediction data, and a packagesJson, and generates a table of statistics
 */
export default function GenerateStatisticsTable(listOfPredictionData: any, packagesJson: any) {
    // this ensures that colors are uniform across all tables and graphs
    // this list of colors is also used by console-mapping-api to ensure uniformity and intuitiveness
    const colors = ['magenta', 'blue', 'pink', 'purple', 'grey'];

    // for listOfPredictionData, run each of them through ParsePrediction, then add the results to a list
    const parsedListOfPredictionData = listOfPredictionData.map((predictionData: PredictionResults) => ParsePrediction(predictionData, packagesJson));

    function calcSavingsAndDisplay(parsedListOfPredictionData: any, dictKey: string, isHigherBetter: boolean,
                                   additionalFunction=(x: number) => x) {
        return <>
            {additionalFunction(parsedListOfPredictionData[0][dictKey]).toFixed(2)}
            {parsedListOfPredictionData.length > 1 && parsedListOfPredictionData.slice(1).map((predictionData: any, index: number) => {
                return <>
                    <> | </>
                    <span style={{color: colors[index]}}>{additionalFunction(predictionData[dictKey]).toFixed(2)}</span>
                </>
            })}
        {parsedListOfPredictionData.length > 1 &&
            <>
            <em>
            <br></br>
                {isHigherBetter
                    ? parsedListOfPredictionData[0][dictKey]-parsedListOfPredictionData[1][dictKey] <  0
                        ? <span style={{color: 'red'}}>
                            {(Math.abs(parsedListOfPredictionData[0][dictKey]-parsedListOfPredictionData[1][dictKey])/
                                parsedListOfPredictionData[0][dictKey]*100).toFixed(2)}%
                            </span>
                        : <span style={{color: 'green'}}>
                            {(Math.abs(parsedListOfPredictionData[0][dictKey]-parsedListOfPredictionData[1][dictKey])/
                                parsedListOfPredictionData[0][dictKey]*100).toFixed(2)}%
                            </span>
                    : parsedListOfPredictionData[0][dictKey]-parsedListOfPredictionData[1][dictKey] >  0
                        ? <span style={{color: 'red'}}>
                            {(Math.abs(parsedListOfPredictionData[0][dictKey]-parsedListOfPredictionData[1][dictKey])/
                                parsedListOfPredictionData[0][dictKey]*100).toFixed(2)}%
                            </span>
                        :<span style={{color: 'green'}}>
                            {(Math.abs(parsedListOfPredictionData[0][dictKey]-parsedListOfPredictionData[1][dictKey])/
                                parsedListOfPredictionData[0][dictKey]*100).toFixed(2)}%
                            </span>
                }
                {parsedListOfPredictionData.length > 2 && parsedListOfPredictionData.slice(2).map((predictionData: any) => {
                    return <>
                        <> | </>
                        {isHigherBetter
                            ? parsedListOfPredictionData[0][dictKey]-parsedListOfPredictionData[1][dictKey] <  0
                                ? <span style={{color: 'red'}}>
                            {(Math.abs(parsedListOfPredictionData[0][dictKey]-predictionData[dictKey])/
                                parsedListOfPredictionData[0][dictKey]*100).toFixed(2)}%
                            </span>
                                : <span style={{color: 'green'}}>
                            {(Math.abs(parsedListOfPredictionData[0][dictKey]-predictionData[dictKey])/
                                parsedListOfPredictionData[0][dictKey]*100).toFixed(2)}%
                            </span>
                            : parsedListOfPredictionData[0][dictKey]-parsedListOfPredictionData[1][dictKey] >  0
                                ? <span style={{color: 'red'}}>
                            {(Math.abs(parsedListOfPredictionData[0][dictKey]-predictionData[dictKey])/
                                parsedListOfPredictionData[0][dictKey]*100).toFixed(2)}%
                            </span>
                                :<span style={{color: 'green'}}>
                            {(Math.abs(parsedListOfPredictionData[0][dictKey]-predictionData[dictKey])/
                                parsedListOfPredictionData[0][dictKey]*100).toFixed(2)}%
                            </span>
                        }
                    </>
                })}
            </em>
            </>
        }
        </>
    }

    return <>
        <Table striped bordered hover>
            <thead>
            <tr>
                <th style={{textAlign: 'center'}} colSpan={3}>Totaled Metrics for On-Time Shipments
                    <br/>
                        <i>All of the metrics calculated below are based only on
                        the ON-TIME shipments, LATE shipments are NOT taken into consideration in these calculations</i>
                </th>
            </tr>
            </thead>
            <thead>
            <tr>
                <th>Total Volume Moved</th>
                <th>Total Number Of Connections Taken</th>
                <th>Total Cost</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>{calcSavingsAndDisplay(parsedListOfPredictionData, 'totalVolumeMoved', true)}</td>
                <td>{calcSavingsAndDisplay(parsedListOfPredictionData, 'totalNumberOfConnectionsTaken', false)}</td>
                <td>{calcSavingsAndDisplay(parsedListOfPredictionData, 'totalCost', false)}</td>
            </tr>
            </tbody>
            <thead>
            <tr>
                <th>Total Duration (Hours)</th>
                <th>Total Carbon Emissions (Kg of CO<sub>2</sub>)</th>
                <th>Total Distance (km)</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>{calcSavingsAndDisplay(parsedListOfPredictionData, 'totalDuration', false, (x:number)=>x/3600)}</td>
                <td>{calcSavingsAndDisplay(parsedListOfPredictionData, 'totalCo2EmissionsUniqueEdges', false)}</td>
                <td>{calcSavingsAndDisplay(parsedListOfPredictionData, 'totalDistance', false)}</td>
            </tr>
            </tbody>
        </Table>
        <Table striped bordered hover>
            <thead>
            <tr>
                <th style={{textAlign: 'center'}} colSpan={5}>Average Metrics for Shipment Routes
                    <br/>
                    <i>All of the metrics calculated below are based only on
                        the ON-TIME shipments, LATE shipments are NOT taken into consideration in these calculations</i>
                </th>
            </tr>
            </thead>
            <thead>
            <tr>
                <th>Average Number of Connections</th>
                <th>Average Cost</th>
                <th>Average Duration (Hours)</th>
                <th>Average Carbon Emissions (Kg of CO<sub>2</sub>)</th>
                <th>Average Distance (km)</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>{calcSavingsAndDisplay(parsedListOfPredictionData, 'averageNumberOfConnectionsTaken', false)}</td>
                <td>{calcSavingsAndDisplay(parsedListOfPredictionData, 'averageCost', false)}</td>
                <td>{calcSavingsAndDisplay(parsedListOfPredictionData, 'averageDuration', false, (x:number)=>x/3600)}</td>
                <td>{calcSavingsAndDisplay(parsedListOfPredictionData, 'averageCo2Emissions', false)}</td>
                <td>{calcSavingsAndDisplay(parsedListOfPredictionData, 'averageDistance', false)}</td>
            </tr>
            </tbody>
        </Table>
    </>
}