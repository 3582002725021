import Container from 'react-bootstrap/Container';
import Stack from 'react-bootstrap/Stack';
import DemoSideBar from "./DemoSideBar";

interface BodyProps {
    demoSideBar?: boolean;
    children?: JSX.Element;
}

export default function DemoBody({demoSideBar, children}:BodyProps) {
    return (
        <Container>
            <Stack direction="horizontal" className="Body">
                {demoSideBar && <DemoSideBar />}
                <Container className="Content">
                    {children}
                </Container>
            </Stack>
        </Container>
    );
}