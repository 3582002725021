import {ListGroup, ListGroupItem, Table} from "react-bootstrap";
import React from "react";
import MmeCard from "../MiddleMileComponents/MmeCard";

interface IsolateFacilitiesProps {
    graphJson: any;
}

export default function IsolatedFacilities({graphJson}: IsolateFacilitiesProps) {


    return (
        <MmeCard
            title={'Isolated Facilities'}
            description={''}
            body={
                <Table striped bordered hover>
                    <tbody>
                    <tr>
                        <th>Only has Incoming Connections</th>
                        <td>
                            <ListGroup>
                                {graphJson && graphJson.meta_data.banned_origins.length > 0
                                        ? graphJson.meta_data.banned_origins.map((onlyIncomingConnections: any) =>
                                            <ListGroupItem>{onlyIncomingConnections}</ListGroupItem>)
                                        : 'None'
                                }
                            </ListGroup>
                        </td>
                    </tr>
                    <tr>
                        <th>Only has Outgoing Connections</th>
                        <td>
                            <ListGroup>
                                {graphJson && graphJson.meta_data.banned_destinations.length > 0
                                    ? graphJson.meta_data.banned_destinations.map((onlyOutgoingConnections: any) =>
                                        <ListGroupItem>{onlyOutgoingConnections}</ListGroupItem>)
                                    : 'None'
                                }
                            </ListGroup>
                        </td>
                    </tr>
                    </tbody>
                </Table>
            }/>
    )
}