import React, {useState} from "react";
import { ResponseContext} from "../../contexts/LastMileContexts/ResponseContext";
import { ResponseContextType} from "../../contexts/LastMileContexts/ResponseContextType";
import {DataContext} from "../../contexts/LastMileContexts/DataContext";
import {DataContextType} from "../../contexts/LastMileContexts/DataContextType";
import {Table} from "react-bootstrap";
// @ts-ignore
import { CSVLink } from "react-csv";
import {MetricsContext} from "../../contexts/LastMileContexts/MetricsContext";
import {MetricsContextType} from "../../contexts/LastMileContexts/MetricsContextType";

// @ts-ignore
const MissedPackagesTable = () => {
    const {data} = React.useContext(DataContext) as DataContextType;
    const {response} = React.useContext(ResponseContext) as ResponseContextType;
    const {updateNumMissedPackages} = React.useContext(MetricsContext) as MetricsContextType;

    // manipulating LE response
    const json = JSON.stringify(response);
    const parsed = JSON.parse(json);
    const route = parsed.routes;
    console.log('route');
    console.log(route);

    //missedIDS
    //missedIDs is array of missed IDs
    let missedIDsArr;
    let missedIDs: any;
    let reasonMissed;
    let getMissedIds;
    try {
        missedIDsArr = parsed.missedIds;
        // missedIDs = Object.entries(missedIDsArr)[0][1];
        reasonMissed = Object.entries(missedIDsArr)[0][0];
        getMissedIds = missedIDs.map((item: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined, i: React.Key | null | undefined) => {
            return (
                <li key={i}>{item}</li>
            )
        });
    } catch (err: unknown) {
        if (err instanceof Error) {
            console.log(err.message);
        }
    }

    // downloading missed packages
    //get missedpackages ID >> getMissedIds
    // filter response based on ID then retrieve relevant data
    let csvContent = '';
    const [filteredData, setFilteredData] = useState([]);
    const jsonData = JSON.stringify(data);
    const parsedData = JSON.parse(jsonData) as typeof data;
    //shipArr is an array of objects
    const shipArr = Object.entries(parsedData)[2][1];
    console.log('shipArr');
    console.log(shipArr);
    console.log(typeof shipArr);

    let finalResults = new Array<Object>();
    let result: Object = [];

    function filterMissedPackages() {
        for (let i = 0; i < missedIDs.length; i++) {
            for (let j = 0; j < shipArr.length; j++) {
                let txt: string = shipArr[j].id;
                if (txt.toString().charAt(0) === ("'")) {
                    txt = txt.substring(1);
                }
                //txt.toString().replace("'","");
                if (txt === missedIDs[i]) {
                    result = shipArr[i];
                    finalResults.push(result);
                }
                //console.log('filter');
            }
        }
        // @ts-ignore
        setFilteredData(finalResults);
        return filteredData;
    }

    for (let i = 0; i < filteredData.length; i++) {
        let string = filteredData[i];
        csvContent += JSON.stringify(string) + "\n";
    }


    // let numShipments = shipArr.length;
    let missedPackages = 0;
    if(missedIDsArr !== false) {
        // @ts-ignore
        missedPackages = missedIDs.length;
    }
    updateNumMissedPackages(missedPackages);

    return (
        <div>
                <h6 className="text-muted">Missed Shipments &nbsp; {missedPackages}
                    <CSVLink data={csvContent} filename={"missedPackages.csv"}
                             className="btn btn-primary" onClick={filterMissedPackages}>
                        Download
                    </CSVLink>
                </h6>
                <Table striped bordered hover p-2>
                    <thead className="bg-dark text-white">
                    <tr>
                        <th>Shipment ID</th>
                        <th>Reason Missed</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <ul>{getMissedIds}</ul>
                        <td>{reasonMissed}</td>
                    </tr>
                    </tbody>
                </Table>
                {/*        </Tab.Pane>*/}
                {/*    </Tab.Content>*/}
                {/*</Tab.Container>*/}
        </div>
    )
}

export default MissedPackagesTable;


