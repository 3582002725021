import {Table} from "react-bootstrap";
import React from "react";
import MmeCard from "../MiddleMileComponents/MmeCard";

interface NetworkInfoProps {
    graphJson: any;
}

export default function NetworkInfo({graphJson}: NetworkInfoProps) {


    return (
        <MmeCard
            title={'Network Information'}
            description={''}
            body={
                <Table striped bordered hover>
                    <tbody>
                    <tr>
                        <th>Number of Facilities</th>
                        <td>{graphJson.graph.nodes.length}</td>
                    </tr>
                    <tr>
                        <th>Number of Connections</th>
                        <td>{graphJson && graphJson.graph.edges.length}</td>
                    </tr>
                    <tr>
                        <th>Average Number of Connections per Facility</th>
                        <td>{graphJson && Math.round(graphJson.graph.edges.length/graphJson.graph.nodes.length)}</td>
                    </tr>
                    </tbody>
                </Table>
            }/>
    )
}