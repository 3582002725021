import React, {useState} from 'react';
import Body from '../../components/Body';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Row, ListGroup, Table, Button, Container, Badge} from 'react-bootstrap';
import { ResponseContext} from "../../contexts/LastMileContexts/ResponseContext";
import { ResponseContextType} from "../../contexts/LastMileContexts/ResponseContextType";
import {DataContext} from "../../contexts/LastMileContexts/DataContext";
import {DataContextType} from "../../contexts/LastMileContexts/DataContextType";
import {SecondDataContextType} from "../../contexts/LastMileContexts/SecondDataContextType";
import {SecondDataContext} from "../../contexts/LastMileContexts/SecondDataContext";
import { SecondResponseContextType} from "../../contexts/LastMileContexts/SecondResponseContextType";
import {SecondResponseContext} from "../../contexts/LastMileContexts/SecondResponseContext";
import {MetricsContext} from "../../contexts/LastMileContexts/MetricsContext";
import {MetricsContextType} from "../../contexts/LastMileContexts/MetricsContextType";
// @ts-ignore
import { CSVLink } from "react-csv";
import {Spinner} from "react-bootstrap";
import CallApi from "../../utils/CallApi";
import {constants} from "../../utils/constants";
// import {useNavigate} from 'react-router-dom';
// import sgData from "../../utils/data/LastMile/sgp_400.json";

const CompareDataset: React.FC  = () => {
    const {data} = React.useContext(DataContext) as DataContextType;
    const {response} = React.useContext(ResponseContext) as ResponseContextType;
    const {secondData} = React.useContext(SecondDataContext) as SecondDataContextType;
    const {secondResponse} = React.useContext(SecondResponseContext) as SecondResponseContextType;
    const {totalHrsFirst, avgStopsPerDriverFirst,  totalDistFirst,
        avgHoursOnRoadFirst, avgStopsPerDriverPerHourFirst, avgDistFirst} = React.useContext(MetricsContext) as MetricsContextType;

    // const jsonData = JSON.stringify(data);
    // const parsedData = JSON.parse(jsonData) as typeof data;
    const jsonSecondData = JSON.stringify(secondData);
    const parsedSecondData = JSON.parse(jsonSecondData) as typeof secondData;
    // const jsonResponse = JSON.stringify(response);
    // const parsedResponse = JSON.parse(jsonResponse) as typeof response;
    const jsonSecondResponse = JSON.stringify(secondResponse);
    const parsedSecondResponse = JSON.parse(jsonSecondResponse) as typeof secondResponse;

    const [mapForRoutes, setMapForRoutes] = useState('');
    const [mapForPackages, setMapForPackages] = useState('');
    const [loading, setLoading] = useState(false);
    const [clusterLoading, setClusterLoading] = useState(false);

    //shipment details

    //vehicle details
    console.log(' data');
    console.log(data);
    console.log('second data');
    console.log(secondData);
    console.log('second response');
    console.log(secondResponse);
    const[visibleRouteMap, setVisibleRouteMap] = useState(false);
    const[visiblePackageMap, setVisiblePackageMap] = React.useState(false);
    const handleRouteMapChange = () => {
        setLoading(true);
        setVisibleRouteMap(!visibleRouteMap);
        routeMap();
    };

    const handlePackageMapChange = () => {
        setClusterLoading(true);
        setVisiblePackageMap(!visiblePackageMap);
        clusterMap();
    }

    //vehicle utilization rate
    //compare_numVehicles >
    // Tells how many more vehicles were used compared to the minimum number of vehicles necessary as determined by capacity
    // if(parsedData != '') {
    //     const dataServices = Object.entries(parsedData)[2][1];
    //     const dataVehicles = Object.entries(parsedData)[1][1];
    //     const totRequirement = dataServices.size();
    //     console.log(totRequirement)
    //     //get min vehicles needed
    // }
    // if(parsedResponse != '') {
    //     const route = Object.entries(parsedResponse)[1][1];
    //     const vehiclesUsed = route.length();
    //     console.log('vehicles used')
    //     console.log(vehiclesUsed)
    //}

    // @ts-ignore
    const downloadFile = ({secondData, fileName, fileType}) => {
        // Create a blob with the data we want to download as a file
        const blob = new Blob([secondData], {type: fileType})
        // Create an anchor element and dispatch a click event on it
        // to trigger a download
        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    const exportToJson = (e: { preventDefault: () => void; }) => {
        e.preventDefault()
        downloadFile({
            secondData: JSON.stringify(secondResponse),
            fileName: 'response.json',
            fileType: 'text/json',
        })
    }

    // manipulating LE response
    // const json = JSON.stringify(secondResponse);
    // const parsed = JSON.parse(json) as typeof secondResponse;
    const route = Object.entries(parsedSecondResponse)[1][1];
    console.log('route');
    console.log(route);

    const driverIdsArr = new Array<string>();
    for (let i = 0; i < route.length; i++) {
        driverIdsArr.push(route[i].driverId);
    }
    console.log('driver')
    console.log(driverIdsArr)

    const vehicleIdsArr = new Array<string>();
    for (let i = 0; i < route.length; i++) {
        vehicleIdsArr.push(route[i].vehicleId);
    }
    console.log('driver')
    console.log(driverIdsArr)
    console.log('test')
    console.log(Object.entries(parsedSecondResponse)[2][1])

    //missedIDS
    //missedIDs is array of missed IDs
    let missedIDsArr;
    let missedIDs: any;
    let reasonMissed;
    let getMissedIds;
    try {
        missedIDsArr = Object.entries(parsedSecondResponse)[2][1];
        missedIDs = Object.entries(missedIDsArr)[0][1];
        reasonMissed = Object.entries(missedIDsArr)[0][0];
        getMissedIds = missedIDs.map((item: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined, i: React.Key | null | undefined) => {
            return (
                <li key={i}>{item}</li>
            )
        });
    } catch (err: unknown) {
        if (err instanceof Error) {
            console.log(err.message);
        }
    }

    //downloading metrics
    let distanceByVehicle = new Array<number>();
    let numStopsByVehicle = new Array<number>();
    let firstEtaByVehicle = new Array<string>();
    let lastEtaByVehicle = new Array<string>();
    let tripDurationByVehicle = new Array<number>();
    let totalWeightByVehicle = new Array<number>();
    let avgStopsByVehicle = new Array<number>();
    let dist = 0;
    let weight = 0;
    let duration = 0;
    for (let i = 0; i < route.length; i++) {
        for (let j = 0; j < route[i].ships.length; j++) {
            dist = dist + (+route[i].ships[j].distance);
            weight = weight + route[i].ships[j].weight;
            duration = (route[i].ships[j].eta - route[i].ships[j].start_time) / 3600;
        }
        distanceByVehicle.push(dist);
        numStopsByVehicle.push(route[i].ships.length);
        firstEtaByVehicle.push(route[i].ships[0].etaReadable);
        lastEtaByVehicle.push(route[i].ships[route[i].ships.length - 1].etaReadable);
        totalWeightByVehicle.push(weight);
        tripDurationByVehicle.push(duration);
        duration = 0;
        dist = 0;
        weight = 0;
    }

    for (let i = 0; i < route.length; i++) {
        avgStopsByVehicle.push(tripDurationByVehicle[i] / numStopsByVehicle[i]);
    }

    //metrics content
    let csvMetricsContent = " Vehicle ID, Total Distance (km), No. of Stops, Driver ID, First ETA, Last ETA, Trip Duration (hr), Total Weight Carried, Avg Stops per Hr, Max Capacity Used (%) \n";
    for (let i = 0; i < route.length; i++) {
        csvMetricsContent += JSON.stringify(route[i].vehicleId) + ',';
        csvMetricsContent += JSON.stringify(distanceByVehicle[i]) + ',';
        csvMetricsContent += JSON.stringify(numStopsByVehicle[i]) + ',';
        csvMetricsContent += JSON.stringify(route[i].driverId) + ',';
        csvMetricsContent += JSON.stringify(firstEtaByVehicle[i]) + ',';
        csvMetricsContent += JSON.stringify(lastEtaByVehicle[i]) + ',';
        csvMetricsContent += JSON.stringify(tripDurationByVehicle[i]) + ',';
        csvMetricsContent += JSON.stringify(totalWeightByVehicle[i]) + ',';
        csvMetricsContent += JSON.stringify(avgStopsByVehicle[i]) + ',';
        csvMetricsContent += "100 \n";
    }

    let totalDistance = 0;
    let totalHours = 0;
    let totalStopsMade = 0;
    for (let i = 0; i < route.length; i++) {
        totalDistance += distanceByVehicle[i];
        totalHours += tripDurationByVehicle[i];
        totalStopsMade += numStopsByVehicle[i];
    }
    let avgHrs = totalHours / driverIdsArr.length;
    let avgStops = totalStopsMade / driverIdsArr.length;
    let avgStopsPerHr = totalStopsMade / totalHours;
    let avgDist = totalDistance / driverIdsArr.length;

    // downloading missed packages
    //get missedpackages ID >> getMissedIds
    // filter response based on ID then retrieve relevant data
    let csvContent = '';
    const [filteredData, setFilteredData] = useState([]);
    //shipArr is an array of objects
    const shipArr = Object.entries(parsedSecondData)[2][1];
    console.log('shipArr');
    console.log(shipArr);
    console.log(typeof shipArr);
    let numShipments = shipArr.length;

    let finalResults = new Array<Object>();
    let result: Object = [];

    function filterMissedPackages() {
        for (let i = 0; i < missedIDs.length; i++) {
            for (let j = 0; j < shipArr.length; j++) {
                let txt: string = shipArr[j].id;
                if (txt.toString().charAt(0) === ("'")) {
                    txt = txt.substring(1);
                }
                if (txt === missedIDs[i]) {
                    result = shipArr[i];
                    finalResults.push(result);
                }
            }
        }
        // @ts-ignore
        setFilteredData(finalResults);
        return filteredData;
    }
    let missedPackages = 0;
    if(missedIDsArr !== false) {
        missedPackages = missedIDs.length;
    }
    let missedPackagesPercentage = (missedPackages/numShipments) * 100;

    console.log('final results')
    console.log(filteredData);

    for (let i = 0; i < filteredData.length; i++) {
        let string = filteredData[i];
        csvContent += JSON.stringify(string) + "\n";
    }

    //route map
    const routeMap = () => {
        setLoading(true);
        CallApi(constants.CONSOLE_MAPPING_API_DOMAIN, constants.CONSOLE_MAPPING_API_GENERATE_ROUTE_MAP, JSON.stringify({
            data: data,
            response: response
        }), true)
            .then((data) => {
                console.log('string');
                console.log(data);
                setMapForRoutes(data);
            })
        setLoading(false);
    }

    const clusterMap = () => {
        CallApi(constants.CONSOLE_MAPPING_API_DOMAIN, constants.CONSOLE_MAPPING_API_GENERATE_CLUSTER_MAP, JSON.stringify({
            data: data,
            response: response
        }), true)
            .then((data) => {
                console.log('string');
                console.log(data);
                setMapForPackages(data);
            })
        setClusterLoading(false);
    }

    //comparing metrics
    let totalHrsDiff = (totalHours-totalHrsFirst)/100;
    let avgStopsDiff = (avgStops-avgStopsPerDriverFirst)/100;
    let totalDistDiff = (totalDistance-totalDistFirst) / 100;
    let avgHrsDiff = (avgHrs-avgHoursOnRoadFirst) / 100;
    let avgStopsPerHrDiff = (avgStopsPerHr-avgStopsPerDriverPerHourFirst)/ 100;
    let avgDistDiff = (avgDist-avgDistFirst)/100;

    //fleet efficiency
    // capacity_analysis: get the average of the percent capacity used for all vehicles


    return (
        <Body sidebar>
            <Container className="pt-lg-3">
                <div className="justify-content-center mx-auto">
                    <Row className="justify-content-center">
                        <Col xs={10}>
                            <div className='sqaure border rounded p-3'>
                                <h6>New Results</h6>
                                <Button type='button' onClick={exportToJson} variant="dark">
                                    Download as JSON
                                </Button>
                                &nbsp;
                                <CSVLink data={JSON.stringify(secondResponse)} filename={"response.csv"}>
                                    Download as CSV
                                </CSVLink>
                            </div>
                            <br/>
                            <h6 className="text-muted">Metrics &nbsp;
                                <CSVLink data={csvMetricsContent} filename={"metrics.csv"}
                                         className="btn btn-primary">
                                    Download
                                </CSVLink>
                            </h6>
                            {/*<span>*/}
                            <ListGroup horizontal className="d-flex">
                                <ListGroup.Item className="p-3">Total Hours on Road
                                    <h2>{totalHours.toFixed(2)}</h2>
                                    {totalHrsDiff === 0 ? <></> :
                                        totalHrsDiff < 0 ?
                                    <Badge bg="danger" pill>
                                        {totalHrsDiff.toFixed(2)}%
                                    </Badge> :
                                            <Badge bg="success" pill>
                                                +{totalHrsDiff.toFixed(2)}%
                                            </Badge>
                                    }
                                </ListGroup.Item>
                                <ListGroup.Item className="p-3">Average stops per Driver
                                    <h2>{avgStops.toFixed(2)}</h2>
                                    {avgStopsDiff === 0 ? <></> :
                                        avgStopsDiff < 0 ?
                                    <Badge bg="danger" pill>
                                        {avgStopsDiff.toFixed(2)}%
                                    </Badge> :
                                            <Badge bg="success" pill>
                                                +{avgStopsDiff.toFixed(2)}%
                                            </Badge>
                                    }
                                </ListGroup.Item>
                                <ListGroup.Item className="p-3">Total Distance Traveled (km)
                                    <h2>{totalDistance.toFixed(2)}</h2>
                                    {totalDistDiff === 0 ? <></> :
                                        totalDistDiff < 0 ?
                                    <Badge bg="danger" pill>
                                        {totalDistDiff.toFixed(2)}%
                                    </Badge> :
                                            <Badge bg="success" pill>
                                                +{totalDistDiff.toFixed(2)}%
                                            </Badge>
                                    }
                                </ListGroup.Item>
                            </ListGroup>
                            {/*</span>*/}
                            &nbsp;
                            <ListGroup horizontal>
                                <ListGroup.Item className="p-3">Average Hours on Road
                                    <h2>{avgHrs.toFixed(2)}</h2>
                                    {avgHrsDiff === 0 ? <></> :
                                        avgHrsDiff < 0 ?
                                            <Badge bg="danger" pill>
                                                {avgHrsDiff.toFixed(2)}%
                                            </Badge> :
                                    <Badge bg="success" pill>
                                        +{avgHrsDiff.toFixed(2)}%
                                    </Badge>
                                    }
                                </ListGroup.Item>
                                <ListGroup.Item className="p-3">Average Stops per Driver /Hour
                                    <h2>{avgStopsPerHr.toFixed(2)}</h2>
                                    {avgStopsPerHrDiff === 0 ? <></> :
                                        avgStopsPerHrDiff < 0 ?
                                    <Badge bg="danger" pill>
                                        {avgStopsPerHrDiff.toFixed(2)}%
                                    </Badge> :
                                            <Badge bg="success" pill>
                                                +{avgStopsPerHrDiff.toFixed(2)}%
                                            </Badge>
                                    }
                                </ListGroup.Item>
                                <ListGroup.Item className="p-3" >Average Distance Traveled (km)
                                    <h2>{avgDist.toFixed(2)}</h2>
                                    {avgDistDiff=== 0 ? <></> :
                                        avgDistDiff < 0 ?
                                            <Badge bg="danger" pill>
                                                {avgDistDiff.toFixed(2)}%
                                            </Badge> :
                                            <Badge bg="success" pill>
                                                +{avgDistDiff.toFixed(2)}%
                                            </Badge>
                                    }
                                </ListGroup.Item>
                            </ListGroup>
                            <br/>
                        </Col>
                        <Col xs={5}>
                            <div className='square border rounded p-3'>
                            <h6 className="text-muted">Routes</h6>
                            <Button variant='secondary' onClick={handleRouteMapChange}>Route Map</Button>
                            {
                                visibleRouteMap ? (
                                        <div className="content" dangerouslySetInnerHTML={{__html: mapForRoutes}}></div>
                                    ) :
                                    <br/>
                            }
                            {loading ?  <Spinner style={{marginBottom:27}} animation="border"/> : null }
                            </div>
                            <br/>
                        </Col>
                        <Col xs={5}>
                            <div className='square border rounded p-3'>
                            <h6 className="text-muted">Shipments</h6>
                            <Button variant='secondary' onClick={handlePackageMapChange}>Shipments Map</Button>
                            {
                                visiblePackageMap ? (
                                    <div className="content" dangerouslySetInnerHTML={{__html: mapForPackages}}></div>
                                ) : <br/>
                            }
                            {clusterLoading ?  <Spinner style={{marginBottom:27}} animation="border"/> : null }
                            </div>
                            <br />

                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col xs={5}>
                            <div className='square border rounded p-3'>
                            {/*<div className="square border border-3 p-3">*/}
                                <h6>Shipment Details</h6>
                                <ListGroup className="w-40">
                                    <ListGroup.Item className="d-flex justify-content-between align-items-start">Number Of Shipments: {numShipments}
                                        {/*<Badge bg="danger" pill>*/}
                                        {/*    -2%*/}
                                        {/*</Badge>*/}
                                    </ListGroup.Item>
                                    <ListGroup.Item className="d-flex justify-content-between align-items-start">Number of Missed Shipments: {missedPackages}
                                            {/*<Badge bg="danger" pill>*/}
                                            {/*    -2%*/}
                                            {/*</Badge>*/}
                                    </ListGroup.Item>
                                    <ListGroup.Item className="d-flex justify-content-between align-items-start">% Missed Shipments: {missedPackagesPercentage}%
                                        {/*<Badge bg="danger" pill>*/}
                                        {/*    -2%*/}
                                        {/*</Badge>*/}
                                    </ListGroup.Item>
                                </ListGroup>
                            </div>
                        </Col>
                        <Col xs={5}>
                            <div className='sqaure border rounded p-3'>
                                <h6>Vehicle Details</h6>
                                <ListGroup className="w-40">
                                    <ListGroup.Item className="d-flex justify-content-between align-items-start">Avg Capacity Used <br/> For All Vehicles(%):
                                        <div className="fw-bold">
                                            82.0
                                        </div>
                                        <Badge bg="success" pill>
                                            +1%
                                        </Badge>
                                    </ListGroup.Item>
                                </ListGroup>
                                <br/>
                                <p>Vehicle Utilisation Rate</p>
                                <p>insert bar chart here</p>
                            </div>
                            <br/>
                        </Col>
                        <Col xs={10}>
                            <h6 className="text-muted">Missed Shipments &nbsp;
                                <CSVLink data={csvContent} filename={"missedPackages.csv"}
                                         className="btn btn-primary" onClick={filterMissedPackages}>
                                    Download
                                </CSVLink>
                            </h6>
                            <Table striped bordered hover p-2>
                                <thead className="bg-dark text-white">
                                <tr>
                                    <th>Shipment ID</th>
                                    <th>Reason Missed</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    {getMissedIds === undefined ? <h6>No Missed Shipments</h6> :
                                    <ul>{getMissedIds}</ul>
                                    }
                                    <td>{reasonMissed}</td>
                                </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </div>
            </Container>
        </Body>
    )
}

export default CompareDataset;