import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { NavLink } from 'react-router-dom';
import React from "react";

// Will be used in the future to add a top bar to the demo pages
export default function DemoSideBar() {
    return (
        <Navbar sticky="top" className="flex-column Sidebar" expand="lg">
            <Nav.Item>
                <Nav.Link as={NavLink} to="/" end>Go Back</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link as={NavLink} to="/demo-tool" end>Demo Tool</Nav.Link>
            </Nav.Item>
        </Navbar>

    );
}