import {Spinner} from "react-bootstrap";
import React, {useEffect} from "react";
import CallApi from "../../utils/CallApi";
import {constants} from "../../utils/constants";
import MmeCard from "../MiddleMileComponents/MmeCard";

interface VisualisationOfNetworkProps {
    graphJson: any;
    secondGraphJson?: any;
}

export default function VisualisationOfNetwork({graphJson, secondGraphJson}: VisualisationOfNetworkProps) {

    const [graphMap, setGraphMap] = React.useState<any>(null);
    const [secondGraphMap, setSecondGraphMap] = React.useState<any>(null);

    const [loadingGraphMap, setLoadingGraphMap] = React.useState<boolean>(false);

    useEffect(() => {
        const callMappingApi = async () => {
            setLoadingGraphMap(true);
            const response = await CallApi(constants.CONSOLE_MAPPING_API_DOMAIN, constants.CONSOLE_MAPPING_API_GENERATE_DEMO_GRAPH_MAP, JSON.stringify({
                graph: graphJson,
            }), true);
            if (response.hasOwnProperty('error')) {
                console.log('Error in generateMmeMap: ', response, response.error);
                alert('Error in generateMmeMap');
                return;
            }
            setGraphMap(response);
            if (secondGraphJson !== undefined) {
                const secondResponse = await CallApi(constants.CONSOLE_MAPPING_API_DOMAIN, constants.CONSOLE_MAPPING_API_GENERATE_DEMO_GRAPH_MAP, JSON.stringify({
                    killed: ['ONT', 'DFW'],
                    graph: secondGraphJson,
                }), true);
                if (secondResponse.hasOwnProperty('error')) {
                    console.log('Error in generateMmeMap: ', secondResponse, secondResponse.error);
                    alert('Error in generateMmeMap');
                    return;
                }
                setSecondGraphMap(secondResponse);
            }
            setLoadingGraphMap(false);
        };
        callMappingApi().then(() => console.log('callMappingApiForVisNetwork() done'));
    }, [graphJson, secondGraphJson]);

    return (
        <MmeCard
            title={'Visualisation of Network'}
            description={'A map of the current graph'}
            body={
                <div style={{ width: '30rem' }}>
                    {loadingGraphMap
                        ? <Spinner/>
                        : <>
                            {secondGraphMap
                                ? <div>
                                    <div className="content" dangerouslySetInnerHTML={{__html: secondGraphMap}}></div>
                                    Black facilities are offline and grey connections are not usable
                                </div>
                                : <div className="content" dangerouslySetInnerHTML={{__html: graphMap}}></div>
                            }
                        </>}
                </div>
            }/>
    )
}