import * as React from 'react';
import {SecondResponseContextType} from "./SecondResponseContextType";

export const SecondResponseContext = React.createContext<SecondResponseContextType | null>(null);

interface Props {
    children: React.ReactNode;
}

const SecondResponseProvider: React.FC<Props> = ({ children }) => {
    const [secondResponse, setSecondResponse] = React.useState<Object>({});

    const updateSecondResponse = (newResponse: Object) => {
        setSecondResponse(newResponse);
        console.log('response');
        console.log(newResponse)
    };

    return <SecondResponseContext.Provider value={{ secondResponse, updateSecondResponse}}>
        {children}
    </SecondResponseContext.Provider>
}

export default SecondResponseProvider;