import * as React from 'react';
import {SecondDataContextType} from "./SecondDataContextType";

export const SecondDataContext = React.createContext<SecondDataContextType | null>(null);

interface Props {
    children: React.ReactNode;
}

const SecondDataProvider: React.FC<Props> = ({ children }) => {
    const [secondData, setSecondData] = React.useState<Object>([]);

    const updateSecondData = (newData: Object) => {
        setSecondData(newData);
        console.log('data');
        console.log(newData)
    };

    return <SecondDataContext.Provider value={{ secondData, updateSecondData}}>
        {children}
    </SecondDataContext.Provider>
}

export default SecondDataProvider;