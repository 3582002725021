import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import ResponseProvider from "./contexts/LastMileContexts/ResponseContext";
import DataProvider from "./contexts/LastMileContexts/DataContext";
import SecondResponseProvider from "./contexts/LastMileContexts/SecondResponseContext";
import SecondDataProvider from "./contexts/LastMileContexts/SecondDataContext";
import MetricsProvider from "./contexts/LastMileContexts/MetricsContext";

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './utils/authConfig';

// Bootstrap components
import 'bootstrap/dist/css/bootstrap.min.css';

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      {/* the MsalProvider is for the microsoft SSO login functionality*/}
      <MsalProvider instance={msalInstance}>
          <ResponseProvider>
              <DataProvider>
                  <SecondResponseProvider>
                      <SecondDataProvider>
                          <MetricsProvider>
                            <App />
                          </MetricsProvider>
                      </SecondDataProvider>
                  </SecondResponseProvider>
              </DataProvider>
          </ResponseProvider>
      </MsalProvider>
  </React.StrictMode>
);
