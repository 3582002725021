import React from "react";
import Body from "../../components/Body";
import 'bootstrap/dist/css/bootstrap.min.css';
import {Col, Row, Container, Button, Spinner, Card} from 'react-bootstrap';
import CallApi from "../../utils/CallApi";
import {constants} from "../../utils/constants";
import {DataContext} from "../../contexts/LastMileContexts/DataContext";
import {DataContextType} from "../../contexts/LastMileContexts/DataContextType";
import { ResponseContext} from "../../contexts/LastMileContexts/ResponseContext";
import { ResponseContextType} from "../../contexts/LastMileContexts/ResponseContextType";
import {useNavigate} from "react-router-dom";
import inputJson from "../../utils/data/LastMile/useCaseSample.json";
import {downloadFile} from "../../utils/downloadFIle";

const SelfServiceHomepage:React.FC = () => {
    const [locationChoice, setLocationChoice] = React.useState('');
    const [numSimpleDelivery, setNumberSimpleDelivery] = React.useState(20);
    const [numSimplePickup, setNumberSimplePickups] = React.useState(0);
    const [numOnTheGo, setNumberOnTheGo] = React.useState(0);
    const [generatedFile, setGeneratedFile] = React.useState(Object);
    const {LSdata, updateLSdata, simpleDeliveries, onTheGo, simplePickups, location,
        startHour, startMinute, startAmPm, endHour, endMinute, endAmPm,
        updateLocation, updateOnTheGo, updateSimpleDeliveries, updateSimplePickups,
    updateStartMinute, updateStartAmPm, updateStartHour, updateEndMinute, updateEndAmPm, updateEndHour,
    endTime, startTime, updateEndTime, updateStartTime} = React.useContext(DataContext) as DataContextType;
    const [loading, setLoading] = React.useState(false);
    const [LsLoading, setLsLoading] = React.useState(false);
    const {updateLsResponse} = React.useContext(ResponseContext) as ResponseContextType;
    let navigate = useNavigate();



    const handleLocationChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setLocationChoice(event.target.value);
        // @ts-ignore
        updateLocation(event.target.value);
    };
    console.log('locationChoice');
    console.log(location);
    // @ts-ignore
    const handleNumberOnTheGoChange = (event) => {
        setNumberOnTheGo(event.target.value);
        updateOnTheGo(event.target.value);
    }

    // @ts-ignore
    const handleNumberSimplePickupsChange = (event) => {
        setNumberSimplePickups(event.target.value);
        updateSimplePickups(event.target.value);
    }

    // @ts-ignore
    const handleNumberSimpleDeliveriesChange = event => {
        setNumberSimpleDelivery(event.target.value);
        updateSimpleDeliveries(event.target.value);
    }

    //time picker
    // @ts-ignore
    const handleStartHourChange = (event) => {
        // setStartHour(event.target.value);
        updateStartHour(event.target.value);
        updateStartTime((parseInt(event.target.value) * 3600 + parseInt(startMinute) * 60));
    };

    // @ts-ignore
    const handleStartMinuteChange = (event) => {
        // setStartMinute(event.target.value);
        updateStartMinute(event.target.value);
        updateStartTime(parseInt(event.target.value) * 60 + parseInt(startHour) * 3600);
    }

    // @ts-ignore
    const handleStartAmPmChange = (event) => {
        updateStartAmPm(event.target.value);
        if (event.target.value === 'AM') {
            updateStartTime(parseInt(startHour) * 3600 + parseInt(startMinute) * 60);
        } else {
            updateStartTime((parseInt(startHour) + 12) * 3600 + parseInt(startMinute) * 60);
        }
    }

    // @ts-ignore
    const handleEndHourChange = (event) => {
        updateEndHour(event.target.value);
        // updateEndHourChange(endHour);
        updateEndTime(parseInt(event.target.value)*3600 + parseInt(endMinute)*60);
    }

    // @ts-ignore
    const handleEndMinuteChange = (event) => {
        updateEndMinute(event.target.value);
        updateEndTime(parseInt(event.target.value) * 60 + parseInt(endHour) * 3600);
    }

    // @ts-ignore
    const handleEndAmPmChange = (event) => {
        updateEndAmPm(event.target.value);
        if (event.target.value === 'AM') {
            if(startAmPm === 'AM') {
                updateEndTime(parseInt(endHour) * 3600  + parseInt(endMinute) * 60);
            } else {
                updateEndTime(parseInt(endHour + 12) * 3600  + parseInt(endMinute) * 60);
            }
        } else {
            if(startAmPm === 'AM') {
                updateEndTime(parseInt(endHour) + 12 - parseInt(startHour));
            } else {
                updateEndTime(parseInt(endHour) - parseInt(startHour));
            }
        }
    }

    const handleGenerate = async () => {
        if (locationChoice === '') {
            alert('Please enter a location')
            return;
        } else {
            setLoading(true);
            try {
                const response = await CallApi(
                    constants.CONSOLE_MAPPING_API_DOMAIN,
                    constants.CONSOLE_MAPPING_API_GENERATE_USE_CASE_SELF_SERVICE,
                    JSON.stringify({
                        locationChoice: locationChoice,
                        startTime: startTime,
                        endTime: endTime,
                        numSimpleDelivery: numSimpleDelivery,
                        numSimplePickup: numSimplePickup,
                        numOnTheGo: numOnTheGo,
                    }),
                    false
                );
                setGeneratedFile(response);
                // updateLSdata(response);
                // console.log(generatedFile);
                // let responseString = JSON.stringify(response);
                // updateLSdata(JSON.parse(responseString));

            } catch (e) {
                console.error(e);
            } finally {
                setLoading(false);
            }
        }
    };
    console.log('lsData')
    console.log(JSON.stringify(LSdata));

    const jsonData = JSON.stringify(generatedFile);
    const parsed = JSON.parse(jsonData);
    const editedData = parsed.SELF_SERVICE;
    const parsedData = editedData as typeof inputJson;
    console.log('parsedData', parsedData);


    const downloadUseCase = (e: { preventDefault: () => void; }) => {
        e.preventDefault()
        downloadFile({
            data: JSON.stringify(parsedData),
            fileName: 'useCase.json',
            fileType: 'text/json',
        })
    }
    const handleRunLs = async() => {
        setLsLoading(true);
        updateLSdata(parsedData);
        await fetch(
            // 'https://api.logistics-optimizer.dev.quincus.com/routes',
            'http://127.0.0.1:5000/routes',
            {
                // mode: 'no-cors',
                method: 'POST',
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Accept': 'application/json',
                    'Content-Type':  "application/json",
                    'Access-Control-Allow-Methods': '*'
                },
                body: JSON.stringify(parsedData),
            }).then(response => response.json())
            .then((data) => {
                console.log('data');
                console.log(data);
                updateLsResponse(data);
            }).catch((err) => {
                console.log('err');
                console.log(err);
                throw err;
            })
        navigate('/ls-dashboard');
        setLsLoading(false);
    };

    return (
        <Body sidebar>
            <Container className="pt-lg-3">
                <Row>
                    <h4 className="text-start heading">Supercharge Your Last Mile, Delight Your Customers, And Save Time In Minutes</h4>
                    <br />
                </Row>
                {JSON.stringify(generatedFile) === '{}' ?
                    <>
                <Row>
                    {/*{JSON.stringify(generatedFile) === '{}' ? (*/}
                    <Col xs={8} className="text">
                        <div className="text-start">
                            <h6 className="subheading">Location</h6>
                            <select className="form-select" onChange={handleLocationChange} value={locationChoice}>
                                <option disabled value="">
                                    --Choose an option--
                                </option>
                                <option value="san_francisco">San Francisco</option>
                                <option value="singapore">Singapore</option>
                                <option value="boston">Boston</option>
                                <option value="chicago">Chicago</option>
                                <option value="london">London</option>
                                <option value="los_angeles">Los Angeles</option>
                                <option value="madrid">Madrid</option>
                                <option value="manhattan">Manhattan</option>
                                <option value="paris">Paris</option>
                                <option value="rome">Rome</option>
                                <option value="san_diego">San Diego</option>
                                <option value="seattle">Seattle</option>
                                {/*<option value="USA">USA</option>*/}
                            </select>
                            <br />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={8} className="text">
                        <h6 className="subheading">Start Time</h6>
                        <div className="d-flex align-items-center">
                            <select className="form-select" onChange={handleStartHourChange} value={startHour}>
                                <option disabled value="">
                                    Hour
                                </option>
                                {Array.from({ length: 12 }, (_, i) => i + 1).map((hour) => (
                                    <option key={hour} value={hour}>
                                        {hour}
                                    </option>
                                ))}
                            </select>
                            <span className="mx-1">:</span>
                            <select className="form-select" onChange={handleStartMinuteChange} value={startMinute}>
                                <option disabled value="">
                                    Minutes
                                </option>
                                {Array.from({ length: 6 }, (_, i) => i * 10).map((minute) => (
                                    <option key={minute} value={minute}>
                                        {minute}
                                    </option>
                                ))}
                            </select>
                            <select className="form-select" onChange={handleStartAmPmChange} value={startAmPm}>
                                <option disabled value="">
                                    AM/PM
                                </option>
                                <option value="AM">AM</option>
                                <option value="PM">PM</option>
                            </select>
                        </div>
                        <br />
                        <h6 className="subheading">End Time</h6>
                        <div className="d-flex align-items-center">
                            <select className="form-select" onChange={handleEndHourChange} value={endHour}>
                                <option disabled value="">
                                    Hour
                                </option>
                                {Array.from({ length: 12 }, (_, i) => i + 1).map((hour) => (
                                    <option key={hour} value={hour}>
                                        {hour}
                                    </option>
                                ))}
                            </select>
                            <span className="mx-1">:</span>
                            <select className="form-select" onChange={handleEndMinuteChange} value={endMinute}>
                                <option disabled value="">
                                    Minutes
                                </option>
                                {Array.from({ length: 6 }, (_, i) => i * 10).map((minute) => (
                                    <option key={minute} value={minute}>
                                        {minute}
                                    </option>
                                ))}
                            </select>
                            <select className="form-select" onChange={handleEndAmPmChange} value={endAmPm}>
                                <option disabled value="">
                                    AM/PM
                                </option>
                                <option value="AM">AM</option>
                                <option value="PM">PM</option>
                            </select>
                        </div>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col xs={8} className="text">
                        <h6 className="subheading">Shipments</h6>
                        <div className="border border-1 p-4">
                            <h6 className="text">Simple Deliveries (From Depot to Customers)</h6>
                            <input
                                type="number"
                                className="form-control"
                                defaultValue={simpleDeliveries}
                                onChange={handleNumberSimpleDeliveriesChange}
                            />
                            <br/>
                            <h6 className="text">Simple Pickups (From Customers to Depot)</h6>
                            <input
                                type="number"
                                className="form-control"
                                defaultValue={simplePickups}
                                onChange={handleNumberSimplePickupsChange}
                            />
                            <br/>
                            <h6 className="text">On-the-go (From Customers to Customers)</h6>
                            <input
                                type="number"
                                className="form-control"
                                defaultValue={onTheGo}
                                onChange={handleNumberOnTheGoChange}
                            />
                        </div>
                        <br />
                    </Col>
                </Row>
                <Row>
                    <Col xs={8} className='text'>
                        <Button variant="primary" className="float-end text btn-block button" onClick={handleGenerate} disabled={loading}>
                            {loading ? 'Loading...' : 'Generate'}
                        </Button>
                        {loading && <Spinner animation="border" variant="primary" className="float-end" />}
                    </Col>
                </Row>
                    </> : (
                        <>
                    <Row>
                            <Col xs={5}>
                                <h6 className="text-start subheading">Generated File</h6>
                                <Card border="grey" className="p-2 overflow-auto text-wrap align-content-left text" style={{ height: "600px" }}>
                                    <Card.Body>
                                        <Card.Text className="text-start">{JSON.stringify(generatedFile)}</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col>
                                <div>
                                    <br />
                                    <Button variant="primary" className="text btn-block button" onClick={handleRunLs} disabled={LsLoading}>
                                        {LsLoading ? 'Loading...' : 'Run LS'}
                                    </Button>
                                    {/*{loading && <Spinner animation="border" variant="primary" className="float-end" />}*/}
                                    {/*<Button className="button text" onClick={handleRunLs}>*/}
                                    {/*    Run LS*/}
                                    {/*</Button>*/}
                                    &nbsp;
                                    <Button className="button text" onClick={() => setGeneratedFile({})}>
                                        Generate New File
                                    </Button>
                                    &nbsp;
                                    <Button className="button text" onClick={downloadUseCase}>
                                        Download File
                                    </Button>
                                    &nbsp;
                                </div>
                            </Col>

                </Row>
                        </>
                    )}
            </Container>
        </Body>
    )
}

export default SelfServiceHomepage;