
import Body from '../../components/Body';
import React, {useState, useEffect, useRef } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Nav, Tab, Container, Col, Card, Row, Table, Button} from 'react-bootstrap';
import { useNavigate} from 'react-router-dom';
import sgData from "../../utils/data/LastMile/sgp_400.json";
import indoData from "../../utils/data/LastMile/dat.json";
import uabData from "../../utils/data/LastMile/1400_waypoints.json";
import {SecondResponseContextType} from "../../contexts/LastMileContexts/SecondResponseContextType";
import {SecondResponseContext} from "../../contexts/LastMileContexts/SecondResponseContext";
import {SecondDataContextType} from "../../contexts/LastMileContexts/SecondDataContextType";
import {SecondDataContext} from "../../contexts/LastMileContexts/SecondDataContext";
import {Spinner} from "react-bootstrap";

const PreloadedDatasets: React.FC = () => {
    const [selected, setSelected] = useState('');
    const [numVehicles, setNumVehicles] = useState(0);
    const [numPackages, setNumPackages] = useState(0);
    let deliveryCounter = useRef(0);
    let pickupCounter = useRef(0);
    // const [country, setCountry] = useState('');
    const [apiUrl, setApiUrl] = useState('');
    const [type, setType] = useState('');
    let leResults: Object;
    const {updateSecondResponse} = React.useContext(SecondResponseContext) as SecondResponseContextType;
    const {secondData, updateSecondData} = React.useContext(SecondDataContext) as SecondDataContextType;
    const [loading, setLoading] = useState(false);

    const [breakType, setBreakType] = useState('');
    const [returnToDepotType, setReturnToDepotType] = useState('');

    //cost
    const [costDist, setCostDist] = useState('');
    const [costWaitTime, setCostWaitTime] = useState('');
    const [costServiceTime, setCostServiceTime] = useState('');
    const [costTransportTime, setCostTransportTime] = useState('');
    const [depotType, setDepotType] = useState('');
    const [vehicleType, setVehicleType] = useState('');
    const [timeWindow, setTimeWindow] = useState('');
    const handleCostDistChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setCostDist(event.target.value);
    }
    const handleCostWaitTimeChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setCostWaitTime(event.target.value);
    }
    const handleCostServiceTimeChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setCostServiceTime(event.target.value);
    }
    const handleCostTransportTimeChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setCostTransportTime(event.target.value);
    }

    const jsonData = JSON.stringify(secondData);
    const parsedData = JSON.parse(jsonData) as typeof secondData;

    if (!(costDist === '')) {
        Object.entries(parsedData)[0][1].vehicle_cost_per_distance = costDist;
    }
    if (!(costWaitTime === '')) {
        Object.entries(parsedData)[0][1].vehicle_cost_per_waiting_time = costWaitTime;
    }
    if (!(costServiceTime === '')) {
        Object.entries(parsedData)[0][1].vehicle_cost_per_service_time = costServiceTime;
    }
    if (!(costTransportTime === '')) {
        Object.entries(parsedData)[0][1].vehicle_cost_per_transport_time = costTransportTime;
    }

    // selection for which dataset to use
    const handleChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        deliveryCounter.current = 0;
        pickupCounter.current = 0;
        setSelected(event.target.value);
    };

    // selection for DEV/ POC
    const handleToggleChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setType(event.target.value);
    }

    const handleBreakTypeChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setBreakType(event.target.value);
    }

    const handleReturnToDepotChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setReturnToDepotType(event.target.value);
    }
    const handleDepotTypeChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setDepotType(event.target.value);
    }
    const handleVehicleTypeChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setVehicleType(event.target.value);
    }
    const handleTimeWindowChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setTimeWindow(event.target.value);
    }
    const[visibleVehicleDetails, setVisibleVehicleDetails] = useState(false);
    const handleVehicleDetailsChange = () => {
        setLoading(true);
        setVisibleVehicleDetails(!visibleVehicleDetails);
    };

    useEffect(() => {
            if (selected === 'singapore') {
                //card details
                setNumVehicles(sgData.vehicles.length);
                setNumPackages(sgData.services.length);
                // setCountry('singapore');
                updateSecondData(sgData);

                sgData.services.map(service => {
                    if (service.type === "DELIVERY") {
                        deliveryCounter.current = deliveryCounter.current + 1;
                    }
                    return deliveryCounter;
                })

                sgData.services.map(service => {
                    if (service.type === "PICKUP") {
                        pickupCounter.current = pickupCounter.current + 1;
                    }
                    return pickupCounter;
                })
            } else if (selected === 'indonesia') {
                // @ts-ignore
                setNumVehicles(indoData.vehicles.length);
                // @ts-ignore
                setNumPackages(indoData.services.length);
                // setCountry('indonesia');
                updateSecondData(indoData);

                // @ts-ignore
                indoData.services.map((service: { type: string; }) => {
                    if (service.type === "DELIVERY") {
                        deliveryCounter.current = deliveryCounter.current + 1;
                    }
                    return deliveryCounter;
                })

                // @ts-ignore
                indoData.services.map(service => {
                    if (service.type === "PICKUP") {
                        pickupCounter.current = pickupCounter.current + 1;
                    }
                    return pickupCounter;
                })
            } else if (selected === 'united-arab-emirates') {
                setNumVehicles(uabData.vehicles.length);
                setNumPackages(uabData.services.length);
                // setCountry('united-arab-emirates');
                updateSecondData(uabData);

                uabData.services.map(service => {
                    if (service.type === "DELIVERY") {
                        deliveryCounter.current = deliveryCounter.current + 1;
                    }
                    return deliveryCounter;
                })

                uabData.services.map(service => {
                    if (service.type === "PICKUP") {
                        pickupCounter.current = pickupCounter.current + 1;
                    }
                    return pickupCounter;
                })
            }
        }, [selected, updateSecondData]
    )

    // determining which API URL to use
    useEffect(() => {
        if (type === 'DEV') {
            setApiUrl('https://api.logistics.dev.quincus.com/DepotResults');
        } else if (type === 'POC') {
            setApiUrl('https://api.logistics.poc.quincus.com/DepotResults');
        }
    }, [type]);


    //do drivers have breaks?
    // useEffect(() => {
    let settings = null;
    let vehicles = null;
    let services = null;
    // if(parsedData!== '') {
    //     settings = Object.entries(parsedData)[0][1];
    //     vehicles = Object.entries(parsedData)[1][1];
    //     services = Object.entries(parsedData)[2][1];
    // }

    if (breakType === 'yes') {

        console.log(JSON.stringify(settings));
        if(Object.entries(parsedData)[0][1].break_check === "false"){
            Object.entries(parsedData)[0][1].break_check = true;
            // updateData(parsedData);
        }
    } else if (breakType === 'no') {
        if(Object.entries(parsedData)[0][1].break_check === "true"){
            Object.entries(parsedData)[0][1].break_check = false;
        }
    }
    // }, [breakType, parsedData]);

    //return to depot in the end?
    //useEffect(() => {
    if (returnToDepotType === 'yes') {
        if(Object.entries(parsedData)[0][1].return_to_depot === "false") {
            Object.entries(parsedData)[0][1].return_to_depot = true;
        }
        // const returnSettings  = Object.entries(parsedData)[0][1].return_to_depot;
    } else if (returnToDepotType === 'no') {
        if(Object.entries(parsedData)[0][1].return_to_depot === "true") {
            Object.entries(parsedData)[0][1].return_to_depot = false;
        }
    }
    //}, [returnToDepotType, parsedData, data]);

    //clustering?

    //run le
    let navigate = useNavigate();
    const handleRunLe = async () => {
        console.log(apiUrl)
        console.log(JSON.stringify(parsedData))
        setLoading(true);
        // POST request using fetch inside useEffect React hook
        await fetch(apiUrl,
            {
                //mode:'no-cors',
                method: 'POST',
                headers: ({
                    'Access-Control-Allow-Origin': '*',
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJRdWluY3VzTm9uUHJvZCIsIm5hbWUiOiJEU1RlYW0iLCJpYXQiOjE2MzE2NzczMDl9.PatDkmMlTpF4HWUQ6V38IGQuHSHBBXbcKlTJUcCRb-k',
                    'Transfer-Encoding': 'Chunked',
                    'Access-Control-Allow-Method': '*',
                    'Access-Control-Max-Age': '100000',
                    'Referrer-Policy': 'origin'
                }),
                body: JSON.stringify(parsedData),
            }).then(response => response.json())
            .then((data) => {
                console.log('data');
                console.log(data);
                //  setResults(data);
                leResults = data;
                console.log('results');
                console.log(leResults);
            }).catch((err) => {
                console.log('err');
                console.log(err);
                throw err;
            });
        updateSecondData(parsedData);
        updateSecondResponse(leResults);
        console.log('done');
        navigate("/compare-dataset");
    }

    return (
        <Body sidebar>
            <Container className="pt-3" style={{ height: "85vh" }}>
                <Row class="container d-flex flex-row ml-2">
                    <Col xs={3} class="d-flex">
                        Input Dataset
                        <div className="d-grid gap-2">
                            <select onChange={handleChange} value={selected} >
                                {/*// onChange={(e) => setSelected(e.target.value)}>*/}
                                <option disabled={true} value="">
                                    --Choose an option--
                                </option>
                                <option value="singapore">Singapore</option>
                                <option value="indonesia">Indonesia</option>
                                <option value="united-arab-emirates">United Arab Emirates</option>
                            </select>
                        </div>
                        <div>
                            Depot Type
                            &nbsp;
                            <select onChange={handleDepotTypeChange} value={depotType} >
                                <option disabled={true} value="">
                                    --Choose an option--
                                </option>
                                <option value="single-depot">Single Depot</option>
                                <option value="multi-depot">Multi Depot</option>
                                <option value="multi-stops">Multi Stops</option>
                            </select>
                        </div>
                        <div >
                            Server Type
                            &nbsp;
                            <select onChange={handleToggleChange} value={type} >
                                <option disabled={true} value="">
                                    --Choose an option--
                                </option>
                                <option value="DEV">DEV</option>
                                <option value="POC">POC</option>
                            </select>
                            &nbsp;
                        </div>
                        <br/>
                        <Card border="dark p-2" className="overflow-scroll text-wrap " style={{height:"500px"}}>
                            {/*{settings === null ? <></> :*/}
                            <Card.Text className="text-wrap">
                                {/*<h6>Settings</h6>*/}
                                {/*{JSON.stringify(settings)}*/}
                                {/*<br/>*/}
                                {/*<h6>Vehicles</h6>*/}
                                {/*{JSON.stringify(vehicles)}*/}
                                {/*<br/>*/}
                                {/*<h6>Services</h6>*/}
                                {/*{JSON.stringify(services)}*/}
                                {JSON.stringify(parsedData)}
                                {/*<DisplayJsonCard country={country}/>*/}
                            </Card.Text>
                            {/*}*/}
                        </Card>
                        <div className="float-end pt-2">
                            <Button onClick={() => { handleRunLe() }} variant="dark">
                                Run LE
                            </Button>
                        </div>
                        {/*<div className="float-end pt-2">*/}
                        {/*    {loading ?  <Spinner style={{marginBottom:27}} animation="border"/> : null }*/}
                        {/*</div>*/}
                        <br/>
                    </Col>
                    <Col xs={3} class="justify-content-center">
                        <div>
                            Do drivers have breaks?
                            &nbsp;
                            <select onChange={handleBreakTypeChange} value={breakType} >
                                <option disabled={true} value="">
                                    --Choose an option--
                                </option>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                            </select>
                        </div>

                        <div>
                            Do vehicles return to depot?
                            &nbsp;
                            <select onChange={handleReturnToDepotChange} value={returnToDepotType} >
                                <option disabled={true} value="">
                                    --Choose an option--
                                </option>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                            </select>
                        </div>
                        <div>
                            <br/>
                            <Button variant="light" onClick={handleVehicleDetailsChange}>Vehicles</Button>
                            {
                                visibleVehicleDetails ? (
                                        <div className="square border p-3 border-2">
                                            <h6>Vehicle Cost</h6>
                                            <form>
                                                <label>
                                                    Costs per distance
                                                    <br/>
                                                    <input type="text" name="costPerDist"  value={costDist} onChange={handleCostDistChange} />
                                                </label>
                                                <br/>
                                                <label>
                                                    Cost per waiting time
                                                    <br/>
                                                    <input type="text" name="costPerWaitTime"  value={costWaitTime} onChange={handleCostWaitTimeChange} />
                                                </label>
                                                <br/>
                                                <label>
                                                    Cost per service time
                                                    <br/>
                                                    <input type="text" name="costPerServiceTime"  value={costServiceTime} onChange={handleCostServiceTimeChange} />
                                                </label>
                                                <br/>
                                                <label>
                                                    Cost per transport time
                                                    <br/>
                                                    <input type="text" name="costPerTransportTime"  value={costTransportTime} onChange={handleCostTransportTimeChange} />
                                                </label>
                                            </form>
                                            <br/>
                                            <h6>Vehicle Type</h6>
                                            <select onChange={handleVehicleTypeChange} value={vehicleType} >
                                                <option disabled={true} value="">
                                                    --Choose an option--
                                                </option>
                                                <option value="bikes">Bikes</option>
                                                <option value="vans">Vans</option>
                                                <option value="trucks">Trucks</option>
                                            </select>
                                        </div>
                                    ) :
                                    <br/>
                            }
                        </div>
                        <h6>Time Window</h6>
                        <select onChange={handleTimeWindowChange} value={timeWindow} >
                            <option disabled={true} value="">
                                --Choose an option--
                            </option>
                            <option value="short">30mins</option>
                            <option value="medium">Two Hourly</option>
                            <option value="long">Daily</option>
                        </select>
                        <h6>Skills</h6>
                        <h6>Clustering</h6>
                        <h6>Working Hours</h6>
                    </Col>
                    <Col xs={6} class="justify-content-center">
                        <Tab.Container id="packages-data" defaultActiveKey="third">
                            <Nav variant="tabs" defaultActiveKey="/main">
                                <Nav.Item>
                                    <Nav.Link eventKey="first">Delivery</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="second">Pickup</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="third">Delivery + Pickup</Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <br/>
                                    <Card>
                                        <Card.Body>
                                            <Card.Title>
                                                Data Summary
                                            </Card.Title>
                                            <Card.Text>
                                                Number of Vehicles
                                            </Card.Text>
                                            <Card.Text>
                                                {numVehicles}
                                            </Card.Text>
                                            <Card.Text>
                                                Number of Shipments
                                            </Card.Text>
                                            <Card.Text>
                                                {deliveryCounter.current}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    <br/>
                                    <Card>
                                        <Card.Body>
                                            <Card.Title>
                                                Data Summary
                                            </Card.Title>
                                            <Card.Text>
                                                Number of Vehicles
                                            </Card.Text>
                                            <Card.Text>
                                                {numVehicles}
                                            </Card.Text>
                                            <Card.Text>
                                                Number of Shipments
                                            </Card.Text>
                                            <Card.Text>
                                                {pickupCounter.current}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Tab.Pane>
                                <Tab.Pane eventKey="third" className="p-2">
                                    <br/>
                                    <Card>
                                        <Card.Body>
                                            <Card.Title>
                                                Data Summary
                                            </Card.Title>
                                            <Card.Text>
                                                Number of Vehicles
                                            </Card.Text>
                                            <Card.Text>
                                                {numVehicles}
                                            </Card.Text>
                                            <Card.Text>
                                                Number of Shipments
                                            </Card.Text>
                                            <Card.Text>
                                                {numPackages}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                        <br/>
                        <Table striped bordered hover p-2>
                            <thead>
                            <tr>
                                <th>Speed</th>
                                <th>Capacity (Vehicles)</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>meters / second</td>
                                <td>Unit</td>
                            </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
        </Body>
    );
};

export default PreloadedDatasets;