import React from "react";
import {useNavigate} from "react-router-dom";
import Body from '../../components/Body';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Button, Col, Row, Container, Table, Card} from "react-bootstrap";
import vehicleicon from "../../utils/img/last-mile/van.png";
import packageicon from "../../utils/img/last-mile/box.png";
import drivericon from "../../utils/img/last-mile/driver.png";
import timeicon from "../../utils/img/last-mile/clock.png";
import priorityicon from "../../utils/img/last-mile/alert.png";

const PriorityOrdersSuccessMetrics: React.FC  = () => {
    let navigate = useNavigate();

    const handleMoreInfo = () => {
        navigate("/cold-chain-metrics");
    };

    const handleBack = () => {
        navigate("/sub-use-cases");
    }

    return (
        <Body sidebar>
            <Container className="pt-lg-3">
                <div className="justify-content-center mx-auto">
                    <Row>
                        <Col>
                            <Button className="d-grid gap-2 d-md-block" onClick={handleBack}>Back</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h3 className="text-center">Lack of Tools in Identifying and Fulfilling Priority Orders</h3>
                            <br/>
                        </Col>
                        <Col xs={2}>
                            <Button className="float-right bg-success" onClick={handleMoreInfo} >
                                Dashboard
                            </Button>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col xs={6}>
                            <Table striped bordered hover>
                                <thead>
                                <tr>
                                    <th>Vehicles</th>
                                    <th>Capacity Per <br/>Vehicle</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>
                                        <img src={vehicleicon} style={{width:50, height:60}} alt="nil"/> x 5</td>
                                    <td>
                                        <img src={packageicon} style={{width:50, height:50}} alt="nil"/> x 50</td>
                                </tr>
                                </tbody>
                            </Table>
                        </Col>

                        <Col xs={6}>
                            <Table striped bordered hover>
                                <thead>
                                <tr>
                                    <th>Priority</th>
                                    <th>Number of Shipments</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>
                                        <img src={priorityicon} style={{width:50, height:40}} alt="nil"/> 1</td>
                                    <td>
                                        <img src={packageicon} style={{width:50, height:50}} alt="nil"/> x 50</td>
                                </tr>
                                <tr>
                                    <td><img src={priorityicon} style={{width:50, height:40}} alt="nil"/> 5</td>
                                    <td>
                                        <img src={packageicon} style={{width:50, height:50}} alt="nil"/> x 50</td>
                                </tr>
                                <tr>
                                    <td>
                                        <img src={priorityicon} style={{width:50, height:40}} alt="nil"/> 7</td>
                                    <td>
                                        <img src={packageicon} style={{width:50, height:50}} alt="nil"/> x 100</td>
                                </tr>
                                <tr>
                                    <td>
                                        <img src={priorityicon} style={{width:50, height:40}} alt="nil"/> 8</td>
                                    <td>
                                        <img src={packageicon} style={{width:50, height:50}} alt="nil"/> x 100</td>
                                </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={4}>
                            <Card border="grey p-2">
                                <p className="text-center fw-bold"> Drivers</p>
                                <Card.Text className='text-center'>
                                    <img src={drivericon} style={{width:70, height:70}} className='p-2' alt="nil"/> x 5
                                    <br/>
                                    <p className='text-center'>30 mins breaks for drivers</p>
                                </Card.Text>
                            </Card>
                        </Col>
                        <Col xs={4}>
                            <Card border="grey p-2">
                                <p className="text-center fw-bold"> Organization Start Time</p>

                                <Card.Text className='text-center'>
                                    <img src={timeicon} style={{width:70, height:70}} className='p-2' alt="nil"/> 9am<br/>
                                    <br/>
                                </Card.Text>
                            </Card>
                        </Col>
                        <Col xs={4}>
                            <Card border="grey p-2">
                                <p className="text-center fw-bold"> Standard Shipment Handling Time</p>
                                <Card.Text className='text-center'>
                                    <img src={timeicon} style={{width:70, height:70}} className='p-2' alt="nil"/> 5 mins<br/><br/>
                                </Card.Text>
                            </Card>
                        </Col>

                    </Row>
                </div>
            </Container>
        </Body>
    );
};

export default PriorityOrdersSuccessMetrics;