export const constants = {
    MME_TRAINING_API_DOMAIN: "https://mme-training",
    MME_PREDICTION_API_DOMAIN: "https://mme-prediction",
    CONSOLE_MAPPING_API_DOMAIN: "https://console-mapping-api",
    MME_TRAINING_API_TRAIN_MODEL: "train-model",
    MME_TRAINING_API_GET_TRAINED_MODEL: "get-trained-model",
    MME_PREDICTION_API_PREDICT: "predict",
    MME_PREDICTION_API_GET_PREDICTION: "get-prediction",
    MME_PREDICTION_API_A_STAR_PREDICT: "a-star-predict",
    MME_PREDICTION_API_CATASTROPHE_PREDICT: "catastrophe-predict",
    MME_PREDICTION_API_GENERATE_PACKAGE: "generate-package",
    CONSOLE_MAPPING_API_GENERATE_MME_PACKAGE_ROUTE: "generate-mme-package-route",
    CONSOLE_MAPPING_API_GENERATE_DEMO_GRAPH_MAP: "generate-demo-graph-map",
    CONSOLE_MAPPING_API_GENERATE_CLUSTER_MAP: "cluster-map",
    CONSOLE_MAPPING_API_GENERATE_ROUTE_MAP: "route-map",
    CONSOLE_MAPPING_API_GENERATE_HUBS_MAP: "generate-hubs-map",
    CONSOLE_MAPPING_API_GENERATE_VEHICLE_UTILIZATION_METRICS: "generate-vehicle-utilization-metrics",
    CONSOLE_MAPPING_API_READ_GCS: "read-gcs",
    CONSOLE_MAPPING_API_READ_GCS_RESPONSE: "read-gcs-response",
    CONSOLE_MAPPING_API_GENERATE_USE_CASES: "generate-use-cases",
    CONSOLE_MAPPING_API_GENERATE_USE_CASE_SELF_SERVICE: "generate-use-case-self-service",
    CONSOLE_MAPPING_API_GENERATE_PACKAGE_MAP_LS: "package-map-ls",
    CONSOLE_MAPPING_API_GENERATE_ROUTE_MAP_LS: "route-map-ls"

    // REACT_APP_MME_TRAINING_TRAIN_MODEL_LOCAL=http://127.0.0.1:5000/train-model
    // REACT_APP_MME_TRAINING_GET_TRAINED_MODEL_LOCAL=http://127.0.0.1:5000/get-trained-model
    // REACT_APP_MME_PREDICTION_PREDICT_LOCAL=http://127.0.0.1:5000/predict
    // REACT_APP_CONSOLE_MAPPING_GENERATE_MME_PACKAGE_ROUTE_LOCAL=http://127.0.0.1:5001/generate-mme-package-route
    // REACT_APP_CONSOLE_MAPPING_GENERATE_DEMO_GRAPH_MAP_LOCAL=http://127.0.0.1:5001/generate-demo-graph-map
    //
    // REACT_APP_MME_TRAINING_TRAIN_MODEL_DEV=https://mme-training.dev.quincus.com/train-model
    // REACT_APP_MME_TRAINING_GET_TRAINED_MODEL_DEV=https://mme-training.dev.quincus.com/get-trained-model
    // REACT_APP_MME_PREDICTION_PREDICT_DEV=https://mme-prediction.dev.quincus.com/predict
    // REACT_APP_CONSOLE_MAPPING_GENERATE_MME_PACKAGE_ROUTE_DEV=https://console-mapping.dev.quincus.com/generate-mme-package-route
    // REACT_APP_CONSOLE_MAPPING_GENERATE_DEMO_GRAPH_MAP_DEV=https://console-mapping.dev.quincus.com/generate-demo-graph-map
    //
    // REACT_APP_MME_TRAINING_TRAIN_MODEL_TEST=https://mme-training.test.quincus.com/train-model
    // REACT_APP_MME_TRAINING_GET_TRAINED_MODEL_TEST=https://mme-training.test.quincus.com/get-trained-model
    // REACT_APP_MME_PREDICTION_PREDICT_TEST=https://mme-prediction.test.quincus.com/predict
    // REACT_APP_CONSOLE_MAPPING_GENERATE_MME_PACKAGE_ROUTE_TEST=https://console-mapping.test.quincus.com/generate-mme-package-route
    // REACT_APP_CONSOLE_MAPPING_GENERATE_DEMO_GRAPH_MAP_TEST=https://console-mapping.test.quincus.com/generate-demo-graph-map
}