import React, {useState} from "react";
import Body from '../../components/Body';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Col, Row, Container, Button, Card, Spinner, Alert} from 'react-bootstrap';
import {constants} from "../../utils/constants";
import CallApi from "../../utils/CallApi";
import {useNavigate} from "react-router-dom";
import {downloadFile} from "../../utils/downloadFIle";
import { ResponseContext} from "../../contexts/LastMileContexts/ResponseContext";
import { ResponseContextType} from "../../contexts/LastMileContexts/ResponseContextType";
import {DataContext} from "../../contexts/LastMileContexts/DataContext";
import {DataContextType} from "../../contexts/LastMileContexts/DataContextType";
import inputJson from "../../utils/data/LastMile/useCaseSample.json";
import '../../custom_font.css';


const UseCaseGeneratorHomepage: React.FC  = () => {
    const [generatedFile, setGeneratedFile] = React.useState(Object);
    const [numberVT, setNumberVehicleType] = React.useState(1);
    const [numberDepot, setNumberDepot] = React.useState(1);
    const {updateLsResponse} = React.useContext(ResponseContext) as ResponseContextType;
    const {LSdata, updateLSdata, updateLocation} = React.useContext(DataContext) as DataContextType;
    const [numSimpleDelivery, setNumberSimpleDelivery] = React.useState(20);
    const [numSimplePickup, setNumberSimplePickups] = React.useState(0);
    const [numOnTheGo, setNumberOnTheGo] = React.useState(0);
    const [LsLoading, setLsLoading] = React.useState(false);

    const [loading, setLoading] = React.useState(false);
    const [locationChoice, setLocationChoice] = React.useState('');
    const handleLocationChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setLocationChoice(event.target.value);
        // @ts-ignore
        updateLocation(event.target.value);
    };
    // @ts-ignore
    const handleNumberOnTheGoChange = (event) => {
        setNumberOnTheGo(event.target.value);
    }

    // @ts-ignore
    const handleNumberSimplePickupsChange = (event) => {
        setNumberSimplePickups(event.target.value);
    }

    // @ts-ignore
    const handleNumberSimpleDeliveriesChange = event => {
        setNumberSimpleDelivery(event.target.value);
    }

    // vehicle type
    // @ts-ignore
    const handleNumberVehicleTypeChange = event => {
        setNumberVehicleType(event.target.value);
    }

    //depots
    // @ts-ignore
    const handleNumberDepotChange = event => {
        setNumberDepot(event.target.value);
    }

    //networks
    const [networkToggle, setNetworkToggle] = React.useState('map');
    const handleNetworkChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setNetworkToggle(event.target.value);
    }


    const [breakToggle, setBreakToggle] = React.useState('none');
    // @ts-ignore
    const handleBreakChange = (event) => {
        setBreakToggle(event.target.value);
    }

    const handleGenerate = async () => {
        setLoading(true);
        try {
            const response = await CallApi(
                constants.CONSOLE_MAPPING_API_DOMAIN,
                constants.CONSOLE_MAPPING_API_GENERATE_USE_CASES,
                JSON.stringify({
                    locationChoice: locationChoice,
                    numSimpleDelivery: numSimpleDelivery,
                    numSimplePickup: numSimplePickup,
                    numOnTheGo: numOnTheGo,
                    startTime: startTime,
                    endTime: endTime,
                    priorityToggle,
                    carbonToggle,
                    demandItem : demandItem,
                    demandSize: demandSize,
                    demandWeight: demandWeight,
                    demandVolume: demandVolume,
                    timeWindowsToggle: timeWindowsToggle,
                    serviceTimeToggle: serviceTimeToggle,
                    networkToggle: networkToggle,
                    numberVT: numberVT,
                    maxAmpToggle: maxAmpToggle,
                    minAmpToggle: minAmpToggle,
                    maxCustToggle: maxCustToggle,
                    minCustToggle: minCustToggle,
                    breakToggle: breakToggle,
                    sameRouteToggle: sameRouteToggle,
                    inSequenceToggle: inSequenceToggle,
                    inDirectSequenceToggle: inDirectSequenceToggle,
                    mandatoryToggle: mandatoryToggle,
                    forbiddenToggle: forbiddenToggle,
                    numberDepot: numberDepot,
                    driversStartAtHome: driversStartAtHome,
                }),
                false
            );
            setGeneratedFile(response);
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };
    console.log('lsData');
    console.log(LSdata);

    let navigate = useNavigate();
    const jsonData = JSON.stringify(generatedFile);
    const parsed = JSON.parse(jsonData);
    const editedData = parsed.SELF_SERVICE;
    const parsedData = editedData as typeof inputJson;
    console.log('parsedData', parsedData);
    const handleRunLs = async() => {
        updateLSdata(parsedData);
        setLsLoading(true);
        await fetch(
            // 'https://api.logistics-optimizer.dev.quincus.com/routes',
            'http://127.0.0.1:5000/routes',
            {
                // mode: 'no-cors',
                method: 'POST',
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Accept': 'application/json',
                    'Content-Type':  "application/json",
                    'Access-Control-Allow-Methods': '*'
                },
                body: JSON.stringify(parsedData),
            }).then(response => response.json())
            .then((data) => {
                console.log('data');
                console.log(data);
                updateLsResponse(data);
            }).catch((err) => {
                console.log('err');
                console.log(err);
                throw err;
            })
        navigate('/ls-dashboard')
        setLsLoading(false);
    };


    const downloadUseCase = (e: { preventDefault: () => void; }) => {
        e.preventDefault()
        downloadFile({
            data: JSON.stringify(generatedFile),
            fileName: 'useCase.json',
            fileType: 'text/json',
        })
    }

    //time picker
    const [startHour, setStartHour] = useState('7');
    const [startMinute, setStartMinute] = useState('00');
    const [startAmPm, setStartAmPm] = useState('AM');
    const [endHour, setEndHour] = useState('5');
    const [endMinute, setEndMinute] = useState('00');
    const [endAmPm, setEndAmPm] = useState('PM');
    const [endTime, setEndTime] = useState(61200);
    const [startTime, setStartTime] = useState(25200);

    const handleStartHourChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setStartHour(event.target.value);
        // @ts-ignore
        setStartTime(parseInt(event.target.value) * 3600 + startMinute * 60);
    };

    const handleStartMinuteChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setStartMinute(event.target.value);
        // @ts-ignore
        setStartTime(parseInt(event.target.value) * 60 + parseInt(startHour) * 3600);
    }

    const handleStartAmPmChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setStartAmPm(event.target.value);
        if (event.target.value === 'AM') {
            // @ts-ignore
            setStartTime(parseInt(startHour) * 3600 + parseInt(startMinute) * 60);
        } else {
            // @ts-ignore
            setStartTime((parseInt(startHour) + 12) * 3600 + parseInt(startMinute) * 60);
        }
    }

    const handleEndHourChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setEndHour(event.target.value);
        // @ts-ignore
        setEndTime(parseInt(event.target.value)*3600 + parseInt(endMinute)*60);
    }

    const handleEndMinuteChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setEndMinute(event.target.value);
        // @ts-ignore
        setEndTime(parseInt(event.target.value) * 60 + parseInt(endHour) * 3600);
    }

    const handleEndAmPmChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setEndAmPm(event.target.value);
        if (event.target.value === 'AM') {
            if(startAmPm === 'AM') {
                // @ts-ignore
                setEndTime(parseInt(endHour) * 3600  + parseInt(endMinute) * 60);
            } else {
                // @ts-ignore
                setEndTime(parseInt(endHour + 12) * 3600  + parseInt(endMinute) * 60);
            }
        } else {
            if(startAmPm === 'AM') {
                // @ts-ignore
                setEndTime(parseInt(endHour) + 12 - startHour);
            } else {
                // @ts-ignore
                setEndTime(parseInt(endHour) - startHour);
            }
        }
    }

    // service time
    const [serviceTimeToggle, setServiceTimeToggle] = useState('off');
    // @ts-ignore
    const handleServiceTimeChange=(event)=>{
        setServiceTimeToggle(event.target.value);
    }

    //time windows
    const [timeWindowsToggle, setTimeWindowsToggle] = useState('off');
    // @ts-ignore
    const handleTimeWindowsChange=(event)=>{
        setTimeWindowsToggle(event.target.value);
    }

    // priority
    const [priorityToggle, setPriorityToggle] = useState('off');
    // @ts-ignore
    const handlePriorityChange=(event)=>{
        setPriorityToggle(event.target.value);
    }

    // carbon emissions
    const [carbonToggle, setCarbonToggle] = useState('off');
    // @ts-ignore
    const handleCarbonChange=(event)=>{
        setCarbonToggle(event.target.value);
    }

    //constraints
    const [sameRouteToggle, setSameRouteToggle] = useState('off');
    // @ts-ignore
    const handleSameRouteChange=(event)=>{
        setSameRouteToggle(event.target.value);
    }
    const [inSequenceToggle, setInSequenceToggle] = useState('off');
    // @ts-ignore
    const handleInSequenceChange=(event)=>{
        setInSequenceToggle(event.target.value);
    }
    const [inDirectSequenceToggle, setInDirectSequenceToggle] = useState('off');
    // @ts-ignore
    const handleInDirectSequenceChange=(event)=>{
        setInDirectSequenceToggle(event.target.value);
    }

    //demand
    const [demandItem, setDemandItem] = useState('off');
    // @ts-ignore
    const handleItemChange=(event)=>{
        setDemandItem(event.target.value);
    }
    const [demandSize, setDemandSize] = useState('on');
    // @ts-ignore
    const handleSizeChange=(event)=>{
        setDemandSize(event.target.value);
    }
    const [demandWeight, setDemandWeight] = useState('off');
    // @ts-ignore
    const handleWeightChange=(event)=>{
        setDemandWeight(event.target.value);
    }
    const [demandVolume, setDemandVolume] = useState('off');
    // @ts-ignore
    const handleVolumeChange=(event)=>{
        setDemandVolume(event.target.value);
    }

    //route constraints
    const [maxAmpToggle, setMaxAmpToggle] = useState('off');
    // @ts-ignore
    const handleMaxAmplitudeChange=(event)=>{
        setMaxAmpToggle(event.target.value);
    }
    const [minAmpToggle, setMinAmpToggle] = useState('off');
    // @ts-ignore
    const handleMinAmplitudeChange=(event)=>{
        setMinAmpToggle(event.target.value);
    }
    const [maxCustToggle, setMaxCustomers] = useState('off');
    // @ts-ignore
    const handleMaxCustomersChange=(event)=>{
        setMaxCustomers(event.target.value);
    }
    const [minCustToggle, setMinCustomers] = useState('off');
    // @ts-ignore
    const handleMinCustomersChange=(event)=>{
        setMinCustomers(event.target.value);
    }

    //mandoatory
    const [mandatoryToggle, setMandatoryToggle] = useState('off');
    // @ts-ignore
    const handleMandatoryToggleChange=(event)=>{
        setMandatoryToggle(event.target.value);
    }
    const [forbiddenToggle, setForbiddenToggle] = useState('off');
    // @ts-ignore
    const handleForbiddenToggleChange=(event)=>{
        setForbiddenToggle(event.target.value);
    }
    const [driversStartAtHome, setDriversStartAtHome] = useState('False');
    // @ts-ignore
    const handleDriversStartAtHomeChange=(event)=>{
        setDriversStartAtHome(event.target.value);
    }


    return (
        <Body sidebar>
            <Container className="pt-lg-3">
                <div className="justify-content-center mx-auto">
                    {JSON.stringify(generatedFile) === '{}' ?
                        <>
                            <Row>
                                <h4 className="text-start heading">Generate Your Own Use Cases</h4>
                                <br />
                            </Row>
                            <Row>
                                {/*{JSON.stringify(generatedFile) === '{}' ? (*/}
                                <Col xs={8} className="text">
                                    <div className="text-start">
                                        <h4 >Use Case</h4>
                                        <h6 className="subheading">Location</h6>
                                        <select className="form-select" onChange={handleLocationChange} value={locationChoice}>
                                            <option disabled value="">
                                                --Choose an option--
                                            </option>
                                            <option value="san_francisco">San Francisco</option>
                                            <option value="singapore">Singapore</option>
                                            <option value="boston">Boston</option>
                                            <option value="chicago">Chicago</option>
                                            <option value="london">London</option>
                                            <option value="los_angeles">Los Angeles</option>
                                            <option value="madrid">Madrid</option>
                                            <option value="manhattan">Manhattan</option>
                                            <option value="paris">Paris</option>
                                            <option value="rome">Rome</option>
                                            <option value="san_diego">San Diego</option>
                                            <option value="seattle">Seattle</option>
                                            {/*<option value="USA">USA</option>*/}
                                        </select>
                                        <br />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={8} className='text'>
                                    <h6 className="subheading">Start At</h6>
                                    <div className="d-flex align-items-center">
                                        <select className="form-select" onChange={handleStartHourChange} value={startHour}>
                                            <option disabled value="">
                                                Hour
                                            </option>
                                            {Array.from({ length: 12 }, (_, i) => i + 1).map((hour) => (
                                                <option key={hour} value={hour}>
                                                    {hour}
                                                </option>
                                            ))}
                                        </select>
                                        <span className="mx-1">:</span>
                                        <select className="form-select" onChange={handleStartMinuteChange} value={startMinute}>
                                            <option disabled value="">
                                                Minutes
                                            </option>
                                            {Array.from({ length: 6 }, (_, i) => i * 10).map((minute) => (
                                                <option key={minute} value={minute}>
                                                    {minute}
                                                </option>
                                            ))}
                                        </select>
                                        <select className="form-select" onChange={handleStartAmPmChange} value={startAmPm}>
                                            <option disabled value="">
                                                AM/PM
                                            </option>
                                            <option value="AM">AM</option>
                                            <option value="PM">PM</option>
                                        </select>
                                    </div>
                                    <br />
                                    <h6 className="subheading">End At</h6>
                                    <div className="d-flex align-items-center">
                                        <select className="form-select" onChange={handleEndHourChange} value={endHour}>
                                            <option disabled value="">
                                                Hour
                                            </option>
                                            {Array.from({ length: 12 }, (_, i) => i + 1).map((hour) => (
                                                <option key={hour} value={hour}>
                                                    {hour}
                                                </option>
                                            ))}
                                        </select>
                                        <span className="mx-1">:</span>
                                        <select className="form-select" onChange={handleEndMinuteChange} value={endMinute}>
                                            <option disabled value="">
                                                Minutes
                                            </option>
                                            {Array.from({ length: 6 }, (_, i) => i * 10).map((minute) => (
                                                <option key={minute} value={minute}>
                                                    {minute}
                                                </option>
                                            ))}
                                        </select>
                                        <select className="form-select" onChange={handleEndAmPmChange} value={endAmPm}>
                                            <option disabled value="">
                                                AM/PM
                                            </option>
                                            <option value="AM">AM</option>
                                            <option value="PM">PM</option>
                                        </select>
                                    </div>
                                    <br/>
                                </Col>
                            </Row>
                            <Row>
                                {/*{JSON.stringify(generatedFile) === '{}' ? (*/}
                                <Col xs={8} className="text">
                                    <div className="text-start">
                                        <h6 className="subheading">Priority</h6>
                                        <select className="form-select" onChange={handlePriorityChange} value={priorityToggle}>
                                            <option disabled value="">
                                                --Choose an option--
                                            </option>
                                            <option value="on">On</option>
                                            <option value="off">Off</option>
                                        </select>
                                        <br />
                                        <h6 className="subheading">Carbon Emissions</h6>
                                        <select className="form-select" onChange={handleCarbonChange} value={carbonToggle}>
                                            <option disabled value="">
                                                --Choose an option--
                                            </option>
                                            <option value="on">On</option>
                                            <option value="off">Off</option>
                                        </select>
                                        <br />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={8} className="text">
                                    <h4>Customers</h4>
                                    <h6 className="subheading">Shipments</h6>
                                    <div className="border border-1 p-4">
                                        <h6 className="text">Simple Deliveries (From Depot to Customers)</h6>
                                        <input
                                            type="number"
                                            className="form-control"
                                            defaultValue={20}
                                            onChange={handleNumberSimpleDeliveriesChange}
                                        />
                                        <br/>
                                        <h6 className="text">Simple Pickups (From Customers to Depot)</h6>
                                        <input
                                            type="number"
                                            className="form-control"
                                            defaultValue={0}
                                            onChange={handleNumberSimplePickupsChange}
                                        />
                                        <br/>
                                        <h6 className="text">On-the-go (From Customers to Customers)</h6>
                                        <input
                                            type="number"
                                            className="form-control"
                                            defaultValue={0}
                                            onChange={handleNumberOnTheGoChange}
                                        />
                                    </div>
                                    <br />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={8} className='text'>
                                    <h6 className="subheading">Demand</h6>
                                    <div className="border border-1 p-4">
                                        <div className="d-flex">
                                            <div>
                                                <h6 className="text">Item</h6>
                                                <select className="form-select" onChange={handleItemChange} value={demandItem}>
                                                    <option disabled value="">
                                                        --Choose an option--
                                                    </option>
                                                    <option value="on">On</option>
                                                    <option value="off">Off</option>
                                                </select>
                                            </div>
                                            &nbsp;&nbsp;&nbsp;
                                            <div>
                                                <h6 className="text">Size</h6>
                                                <select className="form-select" onChange={handleSizeChange} value={demandSize}>
                                                    <option disabled value="">
                                                        --Choose an option--
                                                    </option>
                                                    <option value="on">On</option>
                                                    <option value="off">Off</option>
                                                </select>
                                            </div>
                                            &nbsp;&nbsp;&nbsp;
                                        </div>
                                        <br/>
                                        <div className="d-flex">
                                            <div>
                                                <h6 className="text">Weight</h6>
                                                <select className="form-select" onChange={handleWeightChange} value={demandWeight}>
                                                    <option disabled value="">
                                                        --Choose an option--
                                                    </option>
                                                    <option value="on">On</option>
                                                    <option value="off">Off</option>
                                                </select>
                                            </div>
                                            &nbsp;&nbsp;&nbsp;
                                            <div>
                                                <h6 className="text">Volume</h6>
                                                <select className="form-select" onChange={handleVolumeChange} value={demandVolume}>
                                                    <option disabled value="">
                                                        --Choose an option--
                                                    </option>
                                                    <option value="on">On</option>
                                                    <option value="off">Off</option>
                                                </select>
                                                &nbsp;&nbsp;&nbsp;
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <br/>
                            <Row>
                                <Col xs={8} className='text'>
                                    <h6 className="subheading">Time Windows</h6>
                                    <select className="form-select" onChange={handleTimeWindowsChange} value={timeWindowsToggle}>
                                        <option disabled value="">
                                            --Choose an option--
                                        </option>
                                        <option value="on">On</option>
                                        <option value="off">Off</option>
                                    </select>
                                    <br/>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={8} className='text'>
                                    <h6 className="subheading">Service Time</h6>
                                    <select className="form-select" onChange={handleServiceTimeChange} value={serviceTimeToggle}>
                                        <option disabled value="">
                                            --Choose an option--
                                        </option>
                                        <option value="off">Off</option>
                                        <option value="on">On</option>
                                    </select>
                                    <br />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={8} className='text'>
                                    <h4>Networks</h4>
                                    <select className="form-select" onChange={handleNetworkChange} value={networkToggle}>
                                        <option disabled value="">
                                            --Choose an option--
                                        </option>
                                        <option value="map">map</option>
                                        <option value="euclidean">euclidean</option>
                                        <option value="manhattan">manhattan</option>
                                        <option value="haversine">haversine</option>
                                    </select>
                                    <br />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={8} className='text'>
                                    <h4>Vehicle Types</h4>
                                    <input
                                        type="number"
                                        className="form-control"
                                        min={1}
                                        max={5}
                                        defaultValue={1}
                                        onChange={handleNumberVehicleTypeChange}
                                    />
                                    { numberVT > 5 ?
                                        <>
                                            <br/>
                                            <Alert variant='danger'>
                                                The maximum number of vehicle types is 5.
                                            </Alert>
                                        </> : null }
                                    <br/>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={8} className='text'>
                                    <h4>Depots</h4>
                                    <input
                                        type="number"
                                        className="form-control"
                                        min={1}
                                        max={5}
                                        defaultValue={1}
                                        onChange={handleNumberDepotChange}
                                    />
                                    { numberDepot > 5 ?
                                        <>
                                            <br/>
                                            <Alert variant='danger'>
                                                The maximum number of depots is 5.
                                            </Alert>
                                        </> : null }
                                    <br/>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={8} className="text">
                                    <h4>Drivers</h4>
                                    <h6 className="subheading">Route Constraints</h6>
                                    <div className="border border-1 p-4">
                                        <div className="d-flex">
                                            <div>
                                                <h6 className="text">Max Amplitude</h6>
                                                <select className="form-select" onChange={handleMaxAmplitudeChange} value={maxAmpToggle}>
                                                    <option disabled value="">
                                                        --Choose an option--
                                                    </option>
                                                    <option value="on">On</option>
                                                    <option value="off">Off</option>
                                                </select>
                                            </div>
                                            &nbsp;&nbsp;&nbsp;
                                            <div className="mr-3">
                                                <h6 className="text">Min Amplitude</h6>
                                                <select className="form-select" onChange={handleMinAmplitudeChange} value={minAmpToggle}>
                                                    <option disabled value="">
                                                        --Choose an option--
                                                    </option>
                                                    <option value="on">On</option>
                                                    <option value="off">Off</option>
                                                </select>
                                            </div>
                                        </div>
                                        <br/>
                                        <div className="d-flex">
                                            <div>
                                                <h6 className="text">Max Customers</h6>
                                                <select className="form-select" onChange={handleMaxCustomersChange} value={maxCustToggle}>
                                                    <option disabled value="">
                                                        --Choose an option--
                                                    </option>
                                                    <option value="on">On</option>
                                                    <option value="off">Off</option>
                                                </select>
                                            </div>
                                            &nbsp;&nbsp;&nbsp;
                                            <div>
                                                <h6 className="text">Min Customers</h6>
                                                <select className="form-select" onChange={handleMinCustomersChange} value={minCustToggle}>
                                                    <option disabled value="">
                                                        --Choose an option--
                                                    </option>
                                                    <option value="on">On</option>
                                                    <option value="off">Off</option>
                                                </select>
                                            </div>
                                        </div>
                                        <br/>
                                        <h6 className="text">Breaks</h6>
                                        <select className="form-select" onChange={handleBreakChange} value={breakToggle}>
                                            <option disabled value="">
                                                --Choose an option--
                                            </option>
                                            <option value="none">None</option>
                                            <option value="fixedPause">Fixed Pause</option>
                                            <option value="twPause">Time Window Pause</option>
                                            <option value="regularPause">Regular Pause</option>
                                        </select>

                                    <br />
                            {/*    </Col>*/}
                            {/*</Row>*/}
                            {/*<Row>*/}
                            {/*    <Col xs={8} className='text'>*/}
                                    <h6 className="text">Drivers Start At Home</h6>
                                    <select className="form-select" onChange={handleDriversStartAtHomeChange} value={driversStartAtHome}>
                                        <option disabled value="">
                                            --Choose an option--
                                        </option>
                                        <option value="True">True</option>
                                        <option value="False">False</option>
                                    </select>
                                    &nbsp;
                                    </div>
                                </Col>
                            </Row>
                            <br/>
                            <Row>
                                <Col xs={8} className='text'>
                                    <h4>Constraints</h4>
                                    <div className="border border-1 p-4 d-flex"> {/* Add "d-flex" class to make the div a flex container */}
                                        <div className="mr-3"> {/* Add margin to create space between select elements */}
                                            <h6 className="text">Same Route</h6>
                                            <select className="form-select" onChange={handleSameRouteChange} value={sameRouteToggle}>
                                                <option disabled value="">
                                                    --Choose an option--
                                                </option>
                                                <option value="on">On</option>
                                                <option value="off">Off</option>
                                            </select>
                                        </div>
                                        &nbsp;&nbsp;&nbsp;
                                        <div className="mr-3">
                                            <h6 className="text">In Sequence</h6>
                                            <select className="form-select" onChange={handleInSequenceChange} value={inSequenceToggle}>
                                                <option disabled value="">
                                                    --Choose an option--
                                                </option>
                                                <option value="on">On</option>
                                                <option value="off">Off</option>
                                            </select>
                                        </div>
                                        &nbsp;&nbsp;&nbsp;
                                        <div>
                                            <h6 className="text">In Direct Sequence</h6>
                                            <select className="form-select" onChange={handleInDirectSequenceChange} value={inDirectSequenceToggle}>
                                                <option disabled value="">
                                                    --Choose an option--
                                                </option>
                                                <option value="on">On</option>
                                                <option value="off">Off</option>
                                            </select>
                                        </div>
                                    </div>
                                    <br/>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={8} className='text'>
                                    <h6 className="text">Mandatory</h6>
                                    <select className="form-select" onChange={handleMandatoryToggleChange} value={mandatoryToggle}>
                                        <option disabled value="">
                                            --Choose an option--
                                        </option>
                                        <option value="on">On</option>
                                        <option value="off">Off</option>
                                    </select>
                                    &nbsp;&nbsp;&nbsp;
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={8} className='text'>
                                    <h6 className="text">Forbidden</h6>
                                    <select className="form-select" onChange={handleForbiddenToggleChange} value={forbiddenToggle}>
                                        <option disabled value="">
                                            --Choose an option--
                                        </option>
                                        <option value="on">On</option>
                                        <option value="off">Off</option>
                                    </select>
                                    &nbsp;
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={8} className='text'>
                                    <Button variant="primary" className="float-end text btn-block button" onClick={handleGenerate} disabled={loading}>
                                        {loading ? 'Loading...' : 'Generate'}
                                    </Button>
                                    {loading && <Spinner animation="border" variant="primary" className="float-end" />}
                                </Col>
                            </Row>
                        </>
                        : (
                            <>
                                <Row>
                                    <Col xs={5}>
                                        <h6 className="text-start subheading">Generated File</h6>
                                        <Card className="p-2 overflow-auto text-wrap align-content-left text" style={{ height: "600px" }}>
                                            <Card.Body>
                                                <Card.Text className="text-start">{JSON.stringify(generatedFile)}</Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col>
                                        <div>
                                            <br />
                                            <Button variant="primary" className="text btn-block button" onClick={handleRunLs} disabled={LsLoading}>
                                                {LsLoading ? 'Loading...' : 'Run LS'}
                                            </Button>
                                            {/*<Button className="button text" onClick={handleRunLs}>*/}
                                            {/*    Launch LS*/}
                                            {/*</Button>*/}
                                            &nbsp;
                                            <Button className="button text" onClick={downloadUseCase}>
                                                Download File
                                            </Button>
                                            &nbsp;
                                            <Button className="button text" onClick={() => setGeneratedFile({})}>
                                                Generate New File
                                            </Button>
                                            &nbsp;
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        )}
                </div>
            </Container>
        </Body>
    );
}

export default UseCaseGeneratorHomepage;